/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Icons } from '../../../@Common/Helpers/Icons'
import { GridFilterDto } from '../../../@Common/Models/GridModel'
import { Buttons } from '../../../@Common/UIComponents/Buttons'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { CustomColumnDef, Grid } from '../../../@Common/UIComponents/Grid'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { RootState } from '../../../setup'
import { PageButtons, PageTitle } from '../../../_metronic/layout/core'
import BeneficiaryActionEnum from '../helper/BeneficiaryActionEnum'
import { BeneficiaryModel } from '../models/BeneficiaryModel'
import { getBeneficiaryList } from '../redux/BeneficiaryActions'
import * as beneficiaryRedux from '../redux/BeneficiaryListRedux'
import BeneficiaryActionModal from './BeneficiaryActionModal'
import BeneficiaryFormModal from './BeneficiaryFormModal'

const mapState = (state: RootState) => ({
    beneficiaryList: state.beneficiaryList,
    permissions: state.auth.userPermissions
})
const connector = connect(mapState, beneficiaryRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const BeneficiaryList: FC<PropsFromRedux> = ({ beneficiaryList, permissions }) => {

    const [beneficiaryId, setBeneficiaryId] = useState<number>(0);
    const [beneficiary, setBeneficiary] = useState<BeneficiaryModel>();
    const [isShowEditModal, setIsShowEditModal] = useState(false);
    const [isShowActionModal, setIsShowActionModal] = useState(false);
    const [action, setAction] = useState<BeneficiaryActionEnum>(BeneficiaryActionEnum.None);
    let { loading } = beneficiaryList;



    // Grid Columns
    const columns = React.useMemo<CustomColumnDef<BeneficiaryModel>[]>(
        () => [
            {
                id: 'idNumber',
                header: () => 'ID',
                accessorKey: 'idNumber',
                cell: info => info.getValue(),
                enableColumnFilter: false
            },
            {
                id: 'name',
                header: () => 'Name',
                accessorKey: 'name',
                cell: info => info.getValue()
            },
            {
                id: 'surname',
                header: () => 'Surname',
                accessorKey: 'surname',
                cell: info => info.getValue()
            },
            {
                id: 'email',
                header: () => 'Email',
                accessorKey: 'email',
                cell: info => info.getValue()
            },
            {
                header: "Actions",
                cell: ({ cell }) => (
                    <>
                        <Buttons
                            variant="primary"
                            className="m-1 btn-icon"
                            onClick={() => editBeneficiary(cell.row.original!)}
                            icon={Icons.Edit}
                            tooltip="Edit Employee Details"
                        >
                        </Buttons>
                        <Buttons
                            variant="primary"
                            className="m-1 btn-icon"
                            onClick={() => archiveBeneficiary(cell.row.original!)}
                            icon={Icons.Archive}
                            tooltip="Archive Employee"
                        >
                        </Buttons>
                        <Buttons
                            variant="danger"
                            className="m-1 btn-icon"
                            onClick={() => deleteBeneficiary(cell.row.original!)}
                            icon={Icons.Trash}
                            tooltip="Delete Employee"
                        >
                        </Buttons>
                    </>
                ),
                enableResizing: true
            }
        ],
        []
    )

    // Get Data
    const getData = async (options: GridFilterDto) => {
        var dataResponse = await getBeneficiaryList(options);
        if (dataResponse.isSuccess)
            return dataResponse.data!;
        else
            return null;
    }

    // Eidt Beneficiary
    const editBeneficiary = async (beneficiary: BeneficiaryModel) => {
        await setBeneficiaryId(beneficiary.id);
        await setIsShowEditModal(true);
    }

    // Delete Beneficiary
    const deleteBeneficiary = async (beneficiary: BeneficiaryModel) => {
        await beneficiaryAction(beneficiary, BeneficiaryActionEnum.Delete);
    }

    // Eidt Beneficiary
    const archiveBeneficiary = async (beneficiary: BeneficiaryModel) => {
        await beneficiaryAction(beneficiary, BeneficiaryActionEnum.Archive);
    }

    // Beneficiary Action
    const beneficiaryAction = async (beneficiary: BeneficiaryModel, action: BeneficiaryActionEnum) => {
        // Set Action
        setAction(action);
        // Set Beneficiary
        await setBeneficiary(beneficiary);
        // Show Modal
        await setIsShowActionModal(true);
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle>Employees</PageTitle>
                <PageButtons>
                </PageButtons>
                <Loader loading={loading}>
                    <div className='card-xl-stretch mb-xl-8 card'>
                        <div className='card-body'>
                            <div className='card-group'>
                                <Cards className='card-xl-stretch mb-xl-8 ic-widget-small card'>
                                    <h1>Employee List</h1>
                                    <Grid<BeneficiaryModel>
                                        columns={columns}
                                        getData={(options) => getData(options)}
                                        maxPageSize={10} />
                                </Cards>
                            </div>
                        </div>
                    </div>

                    <BeneficiaryFormModal
                        id={beneficiaryId}
                        isShowModal={isShowEditModal}
                        setIsShowModal={async (isShowModal) => setIsShowEditModal(isShowModal)} />

                    <BeneficiaryActionModal
                        beneficiary={beneficiary!}
                        isShowModal={isShowActionModal}
                        setIsShowModal={async (isShowModal) => setIsShowActionModal(isShowModal)}
                        beneficiaryAction={action}
                    />
                </Loader>

            </ErrorBoundary>
        </>
    );
}

export default connector(BeneficiaryList)
