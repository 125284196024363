import { call, put, takeLatest } from "redux-saga/effects";
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { getCausesAndImpacts, getConversationCausesAndImpactsByGuid, getRecommendations } from "./RecommendationsActions";
import { showToastrError, showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { RecommendationsReadModel } from "../models/RecommendationsReadModel";
import { ConversationReadDto } from "../models/ConversationReadDto";
import { CauseAndImpact } from "../models/CauseAndImpact";
import { ConfigurationAreaModel } from "../../configurationArea/models/ConfigurationAreaModel";
import { getConfigurationAreaList } from "../../configurationArea/redux/ConfigurationAreaActions";

export const actionTypes = {
    RecommendationsRequested: '[Request Recommendations] Action',
    RecommendationsLoaded: '[Loaded Recommendations] API',
    ConversationRequested: '[Request Conversation] Action',
    ConversationLoaded: '[Loaded Conversation] API',
    CauseAndImpactRequested: '[Request CauseAndImpact] Action',
    CauseAndImpactLoaded: '[Loaded CauseAndImpact] API',
    ConfigurationAreaRequested: '[Request ConfigurationArea] Action',
    ConfigurationAreaLoaded: '[Loaded ConfigurationArea] API',
    RecordStatistics: '[Record Statistics] Action'
}

export interface IRecommendationsState {
    recommendations?: RecommendationsReadModel[],
    loading: boolean,
    conversation?: ConversationReadDto,
    causeAndImpact?: CauseAndImpact[],
    configurationArea?: ConfigurationAreaModel[],
}

const initialRecommendationsState: IRecommendationsState = {
    recommendations: undefined,
    loading: false,
    conversation: undefined,
    causeAndImpact: undefined
}

export const reducer = (state: IRecommendationsState = initialRecommendationsState, action: ActionWithPayload<IRecommendationsState>): IRecommendationsState => {
    switch (action.type) {
        case actionTypes.RecommendationsRequested: {
            return { ...state, loading: true };
        }
        case actionTypes.RecommendationsLoaded: {
            return {
                ...state,
                recommendations: action.payload?.recommendations,
                loading: false
            };
        }
        case actionTypes.ConversationLoaded: {
            return {
                ...state,
                conversation: action.payload?.conversation,
                loading: false
            };
        }
        case actionTypes.CauseAndImpactLoaded: {
            return {
                ...state,
                causeAndImpact: action.payload?.causeAndImpact,
                loading: false
            };
        }
        case actionTypes.ConfigurationAreaLoaded: {
            return {
                ...state,
                configurationArea: action.payload?.configurationArea,
                loading: false
            };
        }
        default:
            return state;
    }
}

export const actions = {
    requestRecommendations: (conversationGuid: string) => ({ type: actionTypes.RecommendationsRequested, payload: { conversationGuid } }),
    fulfillRecommendations: (recommendations: RecommendationsReadModel[]) => ({ type: actionTypes.RecommendationsLoaded, payload: { recommendations } }),
    requestConversation: (conversationGuid: string) => ({ type: actionTypes.ConversationRequested, payload: { conversationGuid } }),
    fulfillConversation: (conversation: ConversationReadDto) => ({ type: actionTypes.ConversationLoaded, payload: { conversation } }),
    requestCauseAndImpact: () => ({ type: actionTypes.CauseAndImpactRequested }),
    fulfillCauseAndImpact: (causeAndImpact: CauseAndImpact[]) => ({ type: actionTypes.CauseAndImpactLoaded, payload: { causeAndImpact } }),
    requestConfigurationArea: () => ({ type: actionTypes.ConfigurationAreaRequested }),
    fulfillConfigurationArea: (configurationArea: ConfigurationAreaModel[]) => ({
        type: actionTypes.ConfigurationAreaLoaded, payload: { configurationArea },
    }),
}

export function* saga() {
    yield takeLatest(actionTypes.RecommendationsRequested, fetchRecommendations);
    yield takeLatest(actionTypes.CauseAndImpactRequested, fetchCausesAndImpacts);
    yield takeLatest(actionTypes.ConfigurationAreaRequested, fetchConfigurationArea);
}

function* fetchCausesAndImpacts() {
    try {
        const response: ServiceResponse<CauseAndImpact[]> = yield call(getCausesAndImpacts);
        if (response.isSuccess && response.data) {
            yield put(actions.fulfillCauseAndImpact(response.data));
        } else {
            if (response.errors && response.errors.length > 0) {
                showToastrErrors(response.errors);
            } else {
                showToastrError("An unexpected error has occurred while trying to get Causes and Impacts.");
            }
        }
    } catch (error) {
        showToastrError("An unexpected error has occurred.");
    }
}

function* fetchRecommendations(request: any) {
    try {
        const conversationResponse: ServiceResponse<ConversationReadDto> = yield call(getConversationCausesAndImpactsByGuid, request.payload.conversationGuid);
        if (conversationResponse.isSuccess && conversationResponse.data) {
            yield put(actions.fulfillConversation(conversationResponse.data));
        } else {
            showToastrError("An unexpected error has occurred while trying to get the conversation.");
            return;
        }

        const recommendationsResponse: ServiceResponse<RecommendationsReadModel[]> = yield call(getRecommendations, request.payload.conversationGuid);
        if (recommendationsResponse.isSuccess && recommendationsResponse.data) {
            yield put(actions.fulfillRecommendations(recommendationsResponse.data));
        } else {
            if (recommendationsResponse.errors && recommendationsResponse.errors.length > 0) {
                showToastrErrors(recommendationsResponse.errors);
            } else {
                showToastrError("An unexpected error has occurred while trying to get recommendations.");
            }
        }
    } catch (error) {
        showToastrError("An unexpected error has occurred.");
    }
}

function* fetchConfigurationArea() {
    try {
        const response: ServiceResponse<ConfigurationAreaModel[]> = yield call(getConfigurationAreaList);
        if (response.isSuccess && response.data) {
            yield put(actions.fulfillConfigurationArea(response.data));
        } else {
            if (response.errors && response.errors.length > 0) {
                showToastrErrors(response.errors);
            } else {
                showToastrError("An unexpected error occurred while trying to get Configuration Area.");
            }
        }
    } catch (error) {
        showToastrError("An unexpected error has occurred.");
    }
}