import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../../@Common/Interfaces/ActionWithPayload"
import { RoleModel } from "../../../../@Common/Models/RoleModel";
import { RoleSpecificPermissionModel } from "../models/RippleAndCo/RoleSpecificPermissionModel";
import { RolePermissionsReadModel } from "../models/RolePermissionsModel";
import { UsersRoles } from "../models/UsersRoles";
import { getRolePermissions, getRoleSpecificPermissions, getUsersRoles } from "./PeopleActions";

export const actionTypes = {
    UsersAndRolesRequested: '[Request Users and Roles] Action',
    UsersAndRolesLoaded: '[Loaded Users and Roles] API',
    ShowRoleModal: '[Role Modal] Action',
    RoleSaved: '[Role Saved] Action',
    RoleDeleted: '[Role Deleted] Action',
    ShowUserModal: '[User Modal] Action',
    UserSaved: '[User Saved] Action',
    ShowAssignUserToRoleModal: '[Assign User To Role Modal] Action',
    UserAssignedToRoleSaved: '[User Assigned To Role Saved] Action',
    RemovedUserFromRole: '[Removed User From Role] Action',
    ShowRolePermissionsModal: '[Role Permissions Modal] Action',
    RolePermissionsRequested: '[Request Role Permissions] Action',
    RolePermissionsLoaded: '[Loaded Role Permissions] API',
    RoleSpecificPermissionsRequested: '[Request Role Specific Permissions] Action',
    RoleSpecificPermissionsLoaded: '[Loaded Role Specific Permisions] API',
    RequestEditRole: '[Role Edit] Requested'
}

export interface IPeopleState {
    usersRoles?: UsersRoles,
    showRoleModal: boolean,
    showUserModal: boolean,
    showAssignUserToRoleModal: boolean,
    loading: boolean,
    currentRole?: RoleModel,
    showRolePermissionsModal: boolean,
    permissionsLoading: boolean,
    rolePermissions?: RolePermissionsReadModel,
    roleSpecificPermissionsLoading: boolean,
    roleSpecificPermissions?: RoleSpecificPermissionModel
}

const initialPeopleState: IPeopleState = {
    usersRoles: undefined,
    showRoleModal: false,
    showUserModal: false,
    showAssignUserToRoleModal: false,
    loading: false,
    currentRole: undefined,
    showRolePermissionsModal: false,
    permissionsLoading: false,
    rolePermissions: undefined,
    roleSpecificPermissionsLoading: false,
    roleSpecificPermissions: undefined
}

export const reducer = (state: IPeopleState = initialPeopleState, action: ActionWithPayload<IPeopleState>): IPeopleState => {
    switch (action.type) {
        case actionTypes.UsersAndRolesRequested: {
            return { ...state, loading: true };
        }

        case actionTypes.UsersAndRolesLoaded: {
            return {
                ...state,
                usersRoles: action.payload?.usersRoles,
                loading: false
            };
        }

        case actionTypes.ShowRoleModal: {
            let showRoleModal = action.payload?.showRoleModal!;
            return { ...state, showRoleModal, currentRole: undefined };
        }

        case actionTypes.RoleSaved: {
            return { ...state, showRoleModal: false };
        }

        case actionTypes.ShowUserModal: {
            let showUserModal = action.payload?.showUserModal!;
            return { ...state, showUserModal };
        }

        case actionTypes.ShowAssignUserToRoleModal: {
            let showAssignUserToRoleModal = action.payload?.showAssignUserToRoleModal!;
            let currentRole = action.payload?.currentRole;
            return { ...state, showAssignUserToRoleModal, currentRole };
        }

        case actionTypes.UserSaved: {
            return { ...state, showUserModal: false };
        }

        case actionTypes.UserAssignedToRoleSaved: {
            return { ...state, showAssignUserToRoleModal: false };
        }

        case actionTypes.ShowRolePermissionsModal: {
            let showRolePermissionsModal = action.payload?.showRolePermissionsModal!;
            let currentRole = action.payload?.currentRole;
            return { ...state, showRolePermissionsModal, currentRole };
        }

        case actionTypes.RolePermissionsRequested: {
            return { ...state, permissionsLoading: true };
        }

        case actionTypes.RolePermissionsLoaded: {
            return {
                ...state,
                rolePermissions: action.payload?.rolePermissions,
                permissionsLoading: false
            };
        }

        case actionTypes.RoleSpecificPermissionsRequested: {
            return { ...state, roleSpecificPermissionsLoading: true };
        }

        case actionTypes.RoleSpecificPermissionsLoaded: {
            return {
                ...state,
                roleSpecificPermissions: action.payload?.roleSpecificPermissions,
                roleSpecificPermissionsLoading: false
            };
        }

        case actionTypes.RequestEditRole: {
            return {
                ...state,
                currentRole: action.payload?.currentRole,
                showRoleModal: true
            };
        }
        
        default:
            return state
    }
}

export const actions = {
    requestUsersAndRoles: () => ({ type: actionTypes.UsersAndRolesRequested }),
    fulfillUsers: (usersRoles: UsersRoles) => ({ type: actionTypes.UsersAndRolesLoaded, payload: { usersRoles } }),
    showRoleModal: (showRoleModal: boolean) => ({ type: actionTypes.ShowRoleModal, payload: { showRoleModal } }),
    fulfillRoleSaved: () => ({ type: actionTypes.RoleSaved }),
    fulfillRoleDeleted: () => ({ type: actionTypes.RoleDeleted }),
    showUserModal: (showUserModal: boolean) => ({ type: actionTypes.ShowUserModal, payload: { showUserModal } }),
    fulfillUserSaved: () => ({ type: actionTypes.UserSaved }),
    showAssignUserToRoleModal: (showAssignUserToRoleModal: boolean, currentRole?: RoleModel) => ({ type: actionTypes.ShowAssignUserToRoleModal, payload: { showAssignUserToRoleModal, currentRole } }),
    fulfillAssignUserToRoleSaved: () => ({ type: actionTypes.UserAssignedToRoleSaved }),
    fulfillRemoveUserFromRole: () => ({ type: actionTypes.RemovedUserFromRole }),
    showRolePermissionsModal: (showRolePermissionsModal: boolean, currentRole?: RoleModel) => ({ type: actionTypes.ShowRolePermissionsModal, payload: { showRolePermissionsModal, currentRole } }),
    requestRolePermissions: () => ({ type: actionTypes.RolePermissionsRequested }),
    fulfillRolePermissions: (rolePermissions: RolePermissionsReadModel) => ({ type: actionTypes.RolePermissionsLoaded, payload: { rolePermissions } }),
    requestRoleSpecificPermissions: (roleId: string) => ({ type: actionTypes.RoleSpecificPermissionsRequested, payload: { roleId } }),
    fulfillRoleSpecificPermissions: (roleSpecificPermissions: RoleSpecificPermissionModel) => ({ type: actionTypes.RoleSpecificPermissionsLoaded, payload: { roleSpecificPermissions } }),
    editRoleModal: (role: RoleModel) => ({ type: actionTypes.RequestEditRole, payload: { currentRole: role } })
}

export function* saga() {
    yield takeLatest(actionTypes.UsersAndRolesRequested, function* usersRequested() {
        const usersRoles = yield getUsersRoles();
        yield put(actions.fulfillUsers(usersRoles));
    })

    function* requestUsersAndRoles() {
        yield put(actions.requestUsersAndRoles());
    }

    yield takeLatest(actionTypes.RoleSaved, requestUsersAndRoles)
    yield takeLatest(actionTypes.RoleDeleted, requestUsersAndRoles);
    yield takeLatest(actionTypes.UserSaved, requestUsersAndRoles);
    yield takeLatest(actionTypes.UserAssignedToRoleSaved, requestUsersAndRoles);
    yield takeLatest(actionTypes.RemovedUserFromRole, requestUsersAndRoles);

    yield takeLatest(actionTypes.RoleSpecificPermissionsRequested, function* roleSpecificPermissionsRequested(action: any){
        let roleId: string = action.payload.roleId;
        var roleSpecificPermissionsResponse = yield getRoleSpecificPermissions(roleId);

        if (roleSpecificPermissionsResponse.isSuccess)
           yield put(actions.fulfillRoleSpecificPermissions(roleSpecificPermissionsResponse.data))
        else
            showToastrErrors(roleSpecificPermissionsResponse.errors);
    })
}