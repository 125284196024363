import React, { lazy, FC, useEffect, useState } from 'react'
import * as khciRedux from '../redux/KnowledgeHubCausesAndImpactRedux'
import { RootState } from '../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { PageTitle } from '../../../_metronic/layout/core'
import { KnowledgeHubPermissions } from '../../../@Common/Helpers/Permissions'
import { CauseAndImpactFullModel  } from '../models/CausesAndImpactModel'
import { AuthorizeAccess } from '../../../@Common/UIComponents/AuthorizeAccess'
import { Buttons } from '../../../@Common/UIComponents/Buttons'
import { Icons } from '../../../@Common/Helpers/Icons'
import { SuspensedView } from '../../../_metronic/layout/SuspendedView';
import ReactHtmlParser from 'html-react-parser';

const mapState = (state: RootState) => ({ knowledgeHubCauseAndImpact: state.knowledgeHubCauseAndImpact, auth: state.auth })
const connector = connect(mapState, khciRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const KnowledgeHubCauseAndImpact: FC<PropsFromRedux> = ({ knowledgeHubCauseAndImpact, auth }) => {
    const EditGeneralDescriptionModal = lazy(() => import('./EditGeneralDescriptionModal'))
    const EditTenantSpecificDescriptionModal = lazy(() => import('./EditTenantSpecificDescriptionModal'))
    const { causeAndImpactTitle } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);

    let { loading } = knowledgeHubCauseAndImpact;

    useEffect(() => {
        function loadCauseAndImpactCategory() {
            dispatch(khciRedux.actions.requestCauseAndImpactCategory(causeAndImpactTitle!));
        }

        if (!knowledgeHubCauseAndImpact.causeAndImpact || knowledgeHubCauseAndImpact.causeAndImpact?.name != causeAndImpactTitle) {
            loadCauseAndImpactCategory();
        }
    }, [location]);

    const onEditGeneralDescriptionClick = (causeAndImpact: CauseAndImpactFullModel | undefined) => {
        if(!causeAndImpact)
            return;

        dispatch(khciRedux.actions.editGeneralDescriptionModal(causeAndImpact));
    };

    const onEditTenantSpecificDescriptionClick = (causeAndImpact: CauseAndImpactFullModel | undefined) => {
        if(!causeAndImpact)
            return;
            
        dispatch(khciRedux.actions.editTenantSpecificDescriptionModal(causeAndImpact));
    };

    const parseRichTextDescription = (richTextDescription: string | undefined) => {
        if (richTextDescription == undefined) {
            richTextDescription = '';
        }

        return richTextDescription;
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle>Cause and Impact</PageTitle>
                <Loader loading={loading}>
                    <div className='card-xl-stretch mb-xl-8 ic-widget-cont card'>

                        <div className='card-header'>
                            <div className="d-flex flex-row justify-content-between">
                                <div className="p-2">
                                    <h1>{knowledgeHubCauseAndImpact.causeAndImpact?.name}</h1>
                                </div>
                            </div>                             
                        </div>

                        {/*<div className='ic-widget-cont__sub-content'>*/}

                        {/*    <div className='flex-space-between'>*/}

                        {/*        <h3>General Description</h3>*/}
                        {/*        <AuthorizeAccess permissionKey={KnowledgeHubPermissions.UpdateGeneralDescription}>*/}
                        {/*            <Buttons variant="primary" className="btn-icon ic-btn-icon-small" onClick={() => { onEditGeneralDescriptionClick(knowledgeHubCauseAndImpact.causeAndImpact) }} */}
                        {/*                icon={Icons.Edit} tooltip="Edit General Description"></Buttons>*/}
                        {/*        </AuthorizeAccess>*/}

                        {/*    </div>*/}
                                
                        {/*    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>*/}
                        {/*        <span className='d-flex align-items-center text-gray-600 mb-2'>*/}
                        {/*            <i className={"me-3 fas fa-info"} /> {knowledgeHubCauseAndImpact.causeAndImpact?.generalDescription}*/}
                        {/*        </span>*/}
                        {/*    </div>*/}

                        {/*</div>                                              */}
                        
                        <div className='ic-widget-cont__sub-content'>

                            <div className='flex-space-between'>

                                <h3>Internal company resources</h3>
                            
                                <AuthorizeAccess permissionKey={KnowledgeHubPermissions.UpdateTenantSpecificDescription}>
                                    <Buttons variant="primary" className="btn-icon ic-btn-icon-small" onClick={() => { onEditTenantSpecificDescriptionClick(knowledgeHubCauseAndImpact.causeAndImpact) }} 
                                        icon={Icons.Edit} tooltip="Edit Tenant Specific Description"></Buttons>
                                </AuthorizeAccess>

                            </div>                            

                            {/*TODO: ALBERT PLEASE FIX MY ALIGNMENT!!!!*/}
                            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                <span className='d-flex text-gray-600 mb-2'>
                                    <i className={"me-3 fas fa-info"} /> 
                                    <div>
                                        {ReactHtmlParser(parseRichTextDescription(knowledgeHubCauseAndImpact.causeAndImpact?.tenantSpecificDescription))}
                                    </div>
                                </span>
                            </div>
                            

                        </div>

                        <div className='ic-widget-cont__sub-content'>

                            <h3>Resources</h3>

                            <ul className="ic-table">
                                <li className="ic-table__row ic-table__row--header">
                                    <div className="ic-table__row__field ic-table__row__field--medium">Name</div>
                                    <div className="ic-table__row__field ic-table__row__field--large">Description</div>
                                    <div className="ic-table__row__field ic-table__row__field--small">Link to Resource</div>
                                </li>
                                {knowledgeHubCauseAndImpact.causeAndImpact?.resources.map((resource, index) =>
                                    <React.Fragment key={index}>
                                        <li className="ic-table__row">
                                            <div className="ic-table__row__field ic-table__row__field--medium ic-table__row__field--highlight">{resource.name}</div>
                                            <div className="ic-table__row__field ic-table__row__field--large">{resource.description}</div>
                                            <div className="ic-table__row__field ic-table__row__field--small"><a href={resource.linkToResource} className="link-primary" target="_blank" >Access here <i className="fas fa-angle-right"></i></a></div>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>                               

                        </div>

                    </div>
                    <SuspensedView>                                    
                        <EditGeneralDescriptionModal></EditGeneralDescriptionModal>
                    </SuspensedView>
                    <SuspensedView>                                    
                        <EditTenantSpecificDescriptionModal></EditTenantSpecificDescriptionModal>
                    </SuspensedView>                               
                </Loader>
            </ErrorBoundary>
        </>
    )
}

export default connector(KnowledgeHubCauseAndImpact);
