import * as React from 'react';
import { Spinner } from 'react-bootstrap';

export interface LoaderProps {
    loading: boolean;
    children?: any;
    showTextLoading?: boolean;
}

const Loader: React.FC<LoaderProps> = (props) => {
    let { children, loading, showTextLoading } = props;

    Loader.defaultProps = {
        showTextLoading: false
    }

    if (loading) {
        return (
            <>
                {!showTextLoading &&
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                }
                {showTextLoading &&
                    <>
                        <p><b>Loading...</b></p>
                    </>
                }
            </>
        )
    }
    else if (children) {
        return <>{children}</>
    }
    else {
        return null;
    }
}

export { Loader }