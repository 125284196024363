import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors, showToastrError } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { AssesmentModel } from '../models/AssesmentModel';
import { CauseAndImpactPercentageModel } from '../models/CauseAndImpactPercentageModel';
import { GetSystemAssesmentForMonth, GetSystemTopCausesForMonth, GetSystemTopImpactsForMonth } from "./DashboardActions";
import { getNormalisedMonthNumber, getMonthNameFromNormalisedMonthNumber, getYearNumberFromNewNormalisedMonthNumberValue, getMonthNumberFromNewUnnormalisedMonthNumberValue } from "../../../@Common/Helpers/DateHelper";

export const actionTypes = {
    SystemAssesmentRequested: '[Request System Assesment] Action',
    SystemAssesmentLoaded: '[Loaded System Assesment] API',
    SystemTopCausesRequested: '[Request System Top Causes] Action',
    SystemTopCausesLoaded: '[Loaded System Top Causes] API',
    SystemTopImpactsRequested: '[Request System Top Impacts] Action',
    SystemTopImpactsLoaded: '[Loaded System Top Impacts] API',
    OtherMonthSystemDataRequested: '[Request Other Month System Data] Action',
    OtherMonthSystemDataLoaded: '[Loaded Other Month System Data] API'
}

export interface ISystemDashboardState {
    selectedMonthNumber: number,
    selectedMonthTitle: string,
    selectedMonthYear: number,
    previousMonthNumber: number,
    previousMonthTitle: string,
    previousMonthYear: number,
    nextMonthNumber: number,
    nextMonthTitle: string,
    nextMonthYear: number,
    systemAssesmentLoading: boolean,
    systemAssesment: AssesmentModel | undefined,
    systemTopCausesLoading: boolean,
    systemTopCauses: CauseAndImpactPercentageModel[] | undefined,
    systemTopImpactsLoading: boolean,
    systemTopImpacts: CauseAndImpactPercentageModel[] | undefined,
}

const currentDate = new Date();
const previousMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
const nextMonthDate = new Date(new Date().setMonth(new Date().getMonth() + 1));

const initialSystemDashboardState: ISystemDashboardState = {
    selectedMonthNumber: getNormalisedMonthNumber(currentDate),
    selectedMonthTitle: currentDate.toLocaleString('default', { month: 'long' }),
    selectedMonthYear: currentDate.getUTCFullYear(),
    previousMonthNumber: getNormalisedMonthNumber(previousMonthDate),
    previousMonthTitle: previousMonthDate.toLocaleString('default', { month: 'long' }),
    previousMonthYear: previousMonthDate.getUTCFullYear(),
    nextMonthNumber: getNormalisedMonthNumber(nextMonthDate),
    nextMonthTitle: nextMonthDate.toLocaleString('default', { month: 'long' }),
    nextMonthYear: nextMonthDate.getUTCFullYear(),
    systemAssesmentLoading: false,
    systemAssesment: undefined,
    systemTopCausesLoading: false,
    systemTopCauses: [],
    systemTopImpactsLoading: false,
    systemTopImpacts: [],
}

export const reducer = (state: ISystemDashboardState = initialSystemDashboardState, action: ActionWithPayload<ISystemDashboardState>): ISystemDashboardState => {
    switch (action.type) {
        case actionTypes.SystemAssesmentRequested: {
            return {
                ...state,
                systemAssesmentLoading: true
            };
        }

        case actionTypes.SystemAssesmentLoaded: {
            return {
                ...state,
                systemAssesment: action.payload?.systemAssesment,
                systemAssesmentLoading: false
            };
        }

        case actionTypes.SystemTopCausesRequested: {
            return {
                ...state,
                systemTopCausesLoading: true
            };
        }

        case actionTypes.SystemTopCausesLoaded: {
            return {
                ...state,
                systemTopCauses: action.payload?.systemTopCauses,
                systemTopCausesLoading: false
            };
        }

        case actionTypes.SystemTopImpactsRequested: {
            return {
                ...state,
                systemTopImpactsLoading: true
            };
        }

        case actionTypes.SystemTopImpactsLoaded: {
            return {
                ...state,
                systemTopImpacts: action.payload?.systemTopImpacts,
                systemTopImpactsLoading: false
            };
        }

        case actionTypes.OtherMonthSystemDataRequested: {
            return {
                ...state,
                systemAssesmentLoading: true,
                systemTopCausesLoading: true,
                systemTopImpactsLoading: true
            };
        }

        case actionTypes.OtherMonthSystemDataLoaded: {
            let monthRequested = action.payload?.selectedMonthNumber;
            let yearRequested = action.payload?.selectedMonthYear;

            if (monthRequested === undefined || yearRequested == undefined) {
                showToastrError("Operation to return data for another month indicated success but some data was missing.");

                return {
                    ...state,
                    systemAssesmentLoading: false,
                    systemTopCausesLoading: false,
                    systemTopImpactsLoading: false
                };
            } else {
                let newSelectedMonthNumberValue: number = monthRequested;
                let newSelectedMonthNameValue: string = getMonthNameFromNormalisedMonthNumber(monthRequested);
                let newSelectedMonthYearNumberValue: number = yearRequested;       

                let newNextMonthNumberValue: number = getMonthNumberFromNewUnnormalisedMonthNumberValue(monthRequested + 1);
                let newNextMonthNameValue: string = getMonthNameFromNormalisedMonthNumber(newNextMonthNumberValue);                
                let newNextMonthYearNumberValue: number = getYearNumberFromNewNormalisedMonthNumberValue(newNextMonthNumberValue, newNextMonthNumberValue, yearRequested);            

                let newPreviousMonthNumberValue = getMonthNumberFromNewUnnormalisedMonthNumberValue(monthRequested - 1);
                let newPreviousMonthNameValue: string = getMonthNameFromNormalisedMonthNumber(newPreviousMonthNumberValue);
                let newPreviousMonthYearNumberValue: number = getYearNumberFromNewNormalisedMonthNumberValue(newPreviousMonthNumberValue, newPreviousMonthNumberValue, yearRequested);            

                return {
                    ...state,
                    selectedMonthNumber: newSelectedMonthNumberValue,
                    selectedMonthTitle: newSelectedMonthNameValue,
                    selectedMonthYear: newSelectedMonthYearNumberValue,
                    nextMonthNumber: newNextMonthNumberValue,
                    nextMonthTitle: newNextMonthNameValue,
                    nextMonthYear: newNextMonthYearNumberValue,
                    previousMonthNumber: newPreviousMonthNumberValue,
                    previousMonthTitle: newPreviousMonthNameValue,
                    previousMonthYear: newPreviousMonthYearNumberValue,
                    systemAssesment: action.payload?.systemAssesment,
                    systemAssesmentLoading: false,
                    systemTopCauses: action.payload?.systemTopCauses,
                    systemTopCausesLoading: false,
                    systemTopImpacts: action.payload?.systemTopImpacts,
                    systemTopImpactsLoading: false
                };
            }
        }

        default:
            return state
    }
}

export const actions = {
    requestSystemAssesment: () => ({ type: actionTypes.SystemAssesmentRequested }),
    fulfillSystemAssesment: (systemAssesment: AssesmentModel) => ({ type: actionTypes.SystemAssesmentLoaded, payload: { systemAssesment } }),
    requestSystemTopCauses: () => ({ type: actionTypes.SystemTopCausesRequested }),
    fulfillSystemTopCauses: (systemTopCauses: CauseAndImpactPercentageModel[]) => ({ type: actionTypes.SystemTopCausesLoaded, payload: { systemTopCauses } }),
    requestSystemTopImpacts: () => ({ type: actionTypes.SystemTopImpactsRequested }),
    fulfillSystemTopImpacts: (systemTopImpacts: CauseAndImpactPercentageModel[]) => ({ type: actionTypes.SystemTopImpactsLoaded, payload: { systemTopImpacts } }),
    requestOtherMonthSystemData: (monthRequested: number, yearRequested: number) => ({
        type: actionTypes.OtherMonthSystemDataRequested, payload: { monthRequested, yearRequested } }),
    fulfillOtherMonthSystemData: (selectedMonthNumber: number,
        selectedMonthYear: number,
        systemAssesment: AssesmentModel,
        systemTopCauses: CauseAndImpactPercentageModel[],
        systemTopImpacts: CauseAndImpactPercentageModel[]) =>
        ({ type: actionTypes.OtherMonthSystemDataLoaded, payload: { selectedMonthNumber, selectedMonthYear, systemAssesment, systemTopCauses, systemTopImpacts } })
}

export function* saga() {
    yield takeLatest(actionTypes.SystemAssesmentRequested, function* systemAssesmentRequested() {
        const systemAssesmentRequestedResponse: ServiceResponse<AssesmentModel> = yield GetSystemAssesmentForMonth(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());

        if (systemAssesmentRequestedResponse.isSuccess && systemAssesmentRequestedResponse.data) {
            let systemAssesmentState: AssesmentModel = systemAssesmentRequestedResponse.data;

            yield put(actions.fulfillSystemAssesment(systemAssesmentState));
        } else {
            showToastrErrors(systemAssesmentRequestedResponse.errors);
        }
    })

    yield takeLatest(actionTypes.SystemTopCausesRequested, function* systemTopCausesRequested() {
        const systemTopCausesRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetSystemTopCausesForMonth(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());

        if (systemTopCausesRequestedResponse.isSuccess && systemTopCausesRequestedResponse.data) {
            let systemTopCausesState: CauseAndImpactPercentageModel[] = systemTopCausesRequestedResponse.data;

            yield put(actions.fulfillSystemTopCauses(systemTopCausesState));
        } else {
            showToastrErrors(systemTopCausesRequestedResponse.errors);
        }
    })

    yield takeLatest(actionTypes.SystemTopImpactsRequested, function* systemTopImpactsRequested() {
        const systemTopImpactsRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetSystemTopImpactsForMonth(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());

        if (systemTopImpactsRequestedResponse.isSuccess && systemTopImpactsRequestedResponse.data) {
            let systemTopImpactsState: CauseAndImpactPercentageModel[] = systemTopImpactsRequestedResponse.data;

            yield put(actions.fulfillSystemTopImpacts(systemTopImpactsState));
        } else {
            showToastrErrors(systemTopImpactsRequestedResponse.errors);
        }
    })    

    function* otherMonthSystemDataRequested(action: any) {
        let monthRequested: number = action.payload.monthRequested;
        let yearRequested: number = action.payload.yearRequested;

        const systemAssesmentRequestedResponse: ServiceResponse<AssesmentModel> = yield GetSystemAssesmentForMonth(monthRequested, yearRequested);
        const systemTopCausesRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetSystemTopCausesForMonth(monthRequested, yearRequested);
        const systemTopImpactsRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetSystemTopImpactsForMonth(monthRequested, yearRequested);

        if ((systemAssesmentRequestedResponse.isSuccess && systemAssesmentRequestedResponse.data) &&
            (systemTopCausesRequestedResponse.isSuccess && systemTopCausesRequestedResponse.data) &&
            (systemTopImpactsRequestedResponse.isSuccess && systemTopImpactsRequestedResponse.data)) {

            let systemAssesmentRequestedState: AssesmentModel = systemAssesmentRequestedResponse.data;
            let systemTopCausesRequestedState: CauseAndImpactPercentageModel[] = systemTopCausesRequestedResponse.data;
            let systemTopImpactsState: CauseAndImpactPercentageModel[] = systemTopImpactsRequestedResponse.data;

            yield put(actions.fulfillOtherMonthSystemData(monthRequested,
                yearRequested,
                systemAssesmentRequestedState,
                systemTopCausesRequestedState,
                systemTopImpactsState));
        } else {
            if (systemAssesmentRequestedResponse.errors)
                showToastrErrors(systemAssesmentRequestedResponse.errors);

            if (systemTopCausesRequestedResponse.errors)
                showToastrErrors(systemTopCausesRequestedResponse.errors);

            if (systemTopImpactsRequestedResponse.errors)
                showToastrErrors(systemTopImpactsRequestedResponse.errors);
        }
    }
    yield takeLatest(actionTypes.OtherMonthSystemDataRequested, otherMonthSystemDataRequested);
}