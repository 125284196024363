import { connect } from "react-redux";
import { CauseAndImpactPercentageModel } from '../models/CauseAndImpactPercentageModel';
import { CauseOrImpactHighPercentageThreshold, CauseOrImpactMediumPercentageThreshold } from '../../../@Common/Configurations/BLConfigurations';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

interface ICauseAndImpactRankingEntry {
    causeOrImpactRankedList: CauseAndImpactPercentageModel[] | undefined;
    entryType: string
}

const RenderAll: React.FC<CauseAndImpactPercentageModel[]> = ( causeOrImpactRankedList ) =>{
    return (
        <>
        <ul className="ic-table">
            {
                causeOrImpactRankedList?.map(function(element, index){
                return (
                    <>
                        <li className="ic-table__row" key={index}>
                            <div className="ic-table__row__field ic-table__row__field--large"><strong>{element.title}</strong></div>
                            <div className="ic-table__row__field ic-table__row__field--small">
                                <span className={element.percentage > CauseOrImpactHighPercentageThreshold ? "ic-tag ic-tag--red" :
                                    element.percentage > CauseOrImpactMediumPercentageThreshold ? "ic-tag ic-tag--orange" : 
                                        "ic-tag ic-tag--green"}>
                                    <strong>{element.percentage}%</strong>
                                </span>
                            </div>
                        </li>
                    </>
                )
                })
            }
        </ul>
        </>
    )
}

const RenderCascade: React.FC<CauseAndImpactPercentageModel[]> = ( causeOrImpactRankedList ) =>{
    function ShowText(show: boolean) {
        return (show ? "See Less" : "See More");
    }

    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    const topCategories = causeOrImpactRankedList.slice(0, 3);
    const remainingCategories = causeOrImpactRankedList.slice(3, causeOrImpactRankedList.length);

    return (
        <>
            <ul className="ic-table">
                {
                    topCategories?.map(function(element, index){
                    return (
                        <>
                            <li className="ic-table__row" key={index}>
                                <div className="ic-table__row__field ic-table__row__field--large"><strong>{element.title}</strong></div>
                                <div className="ic-table__row__field ic-table__row__field--small">
                                    <span className={element.percentage > CauseOrImpactHighPercentageThreshold ? "ic-tag ic-tag--red" :
                                        element.percentage > CauseOrImpactMediumPercentageThreshold ? "ic-tag ic-tag--orange" : 
                                            "ic-tag ic-tag--green"}>
                                        <strong>{element.percentage}%</strong>
                                    </span>
                                </div>
                            </li>
                        </>
                    )
                    })
                }
                {show ? 
                    remainingCategories?.map(function(element, index){
                    return (
                        <>
                            <li className="ic-table__row" key={index}>
                                <div className="ic-table__row__field ic-table__row__field--large"><strong>{element.title}</strong></div>
                                <div className="ic-table__row__field ic-table__row__field--small">
                                    <span className={element.percentage > CauseOrImpactHighPercentageThreshold ? "ic-tag ic-tag--red" :
                                        element.percentage > CauseOrImpactMediumPercentageThreshold ? "ic-tag ic-tag--orange" : 
                                            "ic-tag ic-tag--green"}>
                                        <strong>{element.percentage}%</strong>
                                    </span>
                                </div>
                            </li>
                        </>
                    )
                    })
                    : <></>
                }
            </ul>
            <Button className="w-100" onClick={() => toggleShow()}>{ShowText(show)}</Button>
        </>
    )
}

const CauseAndImpactRankingEntry: React.FC<ICauseAndImpactRankingEntry> = ({ causeOrImpactRankedList, entryType } ) => {
    return(
        (causeOrImpactRankedList === undefined ||
            (causeOrImpactRankedList !== undefined && causeOrImpactRankedList.length < 1))
        ?
            <>No {entryType} data is available for the selected month.</>
        :
        <>
        
            {
                causeOrImpactRankedList.length > 3 
                ? RenderCascade(causeOrImpactRankedList)
                : RenderAll(causeOrImpactRankedList)
            }
            
        </>
    );
}

// states to be retrieved from the redux store
const mapStateToProps = (state) => {
    return {
    };
  };

export default connect(mapStateToProps)(CauseAndImpactRankingEntry);
