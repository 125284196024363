import { Routes, Route, Outlet } from 'react-router-dom'
import OrganisationView from './components/organisationView';
import { PageTitle } from "../../_metronic/layout/core";

const OrganisationViewPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path="/"
                element={
                    <>
                        <PageTitle>Dashboard (Organisation)</PageTitle>
                        <OrganisationView />
                    </>
                }
            />
        </Route>
    </Routes>
)

export default OrganisationViewPage;