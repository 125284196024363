
const beneficiaryConversationsLimit = "beneficiaryConversationsLimit";
const beneficiaryConversationsDuration = "beneficiaryConversationsDuration";
const beneficiaryConversationsMessage = "beneficiaryConversationsMessage";

const mhfaConversationsLimit = "mhfaConversationsLimit";
const mhfaConversationsDuration = "mhfaConversationsDuration";
const mhfaConversationsMessage = "mhfaConversationsMessage";

const conversationRecommendedLengthMin = "conversationRecommendedLengthMin";
const conversationRecommendedLengthMax = "conversationRecommendedLengthMax";
const conversationRecommendedLengthMessage = "conversationRecommendedLengthMessage";

const eapName = "eapName";
const eapPhone = "eapPhone";
const eapEmail = "eapEmail";

export {
    beneficiaryConversationsLimit,
    beneficiaryConversationsDuration,
    beneficiaryConversationsMessage,
    mhfaConversationsLimit,
    mhfaConversationsDuration,
    mhfaConversationsMessage,
    conversationRecommendedLengthMin,
    conversationRecommendedLengthMax,
    conversationRecommendedLengthMessage,
    eapName,
    eapPhone,
    eapEmail
};