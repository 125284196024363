import { ISystemDashboardState } from "../redux/SystemDashboardRedux";
import { connect } from "react-redux";
import { getAbsoluteMoodUrl } from "../../../@Common/Helpers/MoodHelper";

interface ISystemAssesmentSection {
    systemDashboardState: ISystemDashboardState | undefined ;
}

const SystemAssesmentSection: React.FC<ISystemAssesmentSection> = ({ systemDashboardState } ) => {
    return (
        (systemDashboardState === undefined || systemDashboardState?.systemAssesment === undefined) ?
            <>No first aider conversations logged for the selected month</> :
            <ul className='ic-widget-cont__stats'>
                <li className='brand-color-4'>
                    <i className="far fa-comment-alt color-01"></i>
                    <h3 className='color-02'>Number of Conversations</h3>
                    <p>Across all Organisations</p>
                    <h2 className='color-02'>{systemDashboardState.systemAssesment.totalNumberOfConversationsLogged > 0 ? systemDashboardState.systemAssesment.totalNumberOfConversationsLogged : 0}</h2>
                </li>
                <li className='brand-color-4'>
                    <i className="far fa-clock color-01"></i>
                    <h3 className='color-02'>Duration of Conversations</h3>
                    <p>Across all Organisations</p>
                    <h2 className='color-02'>{systemDashboardState.systemAssesment.totalConversationTimeLoggedInHours > 0 ? systemDashboardState.systemAssesment.totalConversationTimeLoggedInHours : 0} hrs</h2>
                </li>
                <li>
                    <img src={getAbsoluteMoodUrl(systemDashboardState.systemAssesment.averageBeneficiaryMoodEnum)} />
                    <h3 className='color-02'>Average Employee Mood</h3>
                    <p>Across all Organisations</p>
                    <h2 className='color-02'>{systemDashboardState.systemAssesment.averageBeneficiaryMood != undefined ? systemDashboardState.systemAssesment.averageBeneficiaryMood : "N/A"}</h2>
                </li>
                <li>
                    <img src={getAbsoluteMoodUrl(systemDashboardState.systemAssesment.averageMHFAMoodEnum)} />
                    <h3 className='color-02'>Average MHFA Mood</h3>
                    <p>Across all Organisations</p>
                    <h2 className='color-02'>{systemDashboardState.systemAssesment.averageMHFAMood != undefined ? systemDashboardState.systemAssesment.averageMHFAMood : "N/A"}</h2>
                </li>
            </ul>
    );
}

// states to be retrieved from the redux store
const mapStateToProps = (state) => {
    return {
    };
  };

export default connect(mapStateToProps)(SystemAssesmentSection);
