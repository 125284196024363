enum RoutePath {
    PEOPLE = "usermanagement",
    SELF_VIEW = "myprofile",
    COMPLIANCE_VIEW = "dashboard/compliance",
    ORGANISATION_VIEW = "dashboard/organisation",
    SYSTEM_VIEW = "dashboard/system",
    CREATE_CONVERSATION = "logconversation",
    IMPORT_BENEFICIARY_LIST = "importemployeelist",
    BENEFICIARY_LIST = "viewemployeelist",
    VIEW_CONVERSATION = "conversation/view/:id",
    CONFIGURATION_AREA = "settings",
    FIRST_AIDERS_VIEW = "viewmhfas",
    KNOWLEDGE_HUB_VIEW = "signpostingresources",
    KNOWLEDGE_HUB_CAUSE_AND_IMPACT_VIEW = "signpostingresources/*"
}

export default RoutePath;