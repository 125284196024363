import { UserModel } from '../Models/UserModel'

export const doesUserHaveRole = (userToCheck: UserModel | undefined, roleToCheck: string): boolean => {
    let userHasRole: boolean = false;

    if (userToCheck)
        userHasRole = userToCheck.roles.some(role => role.name == roleToCheck);

    return userHasRole;
}

export const doesUserHavePermission = (userPermissions: string[] | undefined, permissionToCheck: string): boolean => {
    let userHasPermission: boolean = false;

    if (userPermissions)
        userHasPermission = userPermissions.some(permission => permission = permissionToCheck);

    return userHasPermission;
}