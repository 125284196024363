import React, { Fragment, useEffect, useState } from 'react'
import Quill from "quill";
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { Cards } from "../../../@Common/UIComponents/Cards";
import { Button, ButtonGroup } from 'react-bootstrap';

interface PlaceHolders {
    buttonText: string,
    placeholder: string
}

interface Props {
    id: string,
    text: string,
    bgColor: string,
    onChange: (text: string) => {},
    placeholders: PlaceHolders[]
}

const ConfigurationAreaRichText = ({ id, text, bgColor, onChange, placeholders }: Props) => {

    var quill;

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //['blockquote', 'code-block'],

        //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        //[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    useEffect(() => {
        quillInit();
    }, []);

    async function quillInit() {


        quill = new Quill("#" + id, {
            modules: {
                toolbar: toolbarOptions
            },
            theme: 'snow'
        });

        const delta = quill.clipboard.convert(text);
        quill.setContents(delta);

        quill.on('text-change', function (delta, oldDelta, source) {
            onChange(quill.root.innerHTML);
        });
    }

    // Hanlde Placeholder Button Click
    const handlePlaceholderClick = (placeholder: string) => {
        if (quill) {
            placeholder = " " + placeholder + " ";
            var selection = quill.getSelection(true);
            quill.insertText(selection.index, placeholder);
        }
    }

    return (
        <>
            {/* <ButtonGroup className='d-flex'>
                {placeholders.map((placeholder, i) => (
                    <Button
                        key={placeholder.buttonText}
                        variant="secondary"
                        size="sm"
                        style={{ backgroundColor: "#0505050d" }}
                        onClick={() => handlePlaceholderClick(placeholder.placeholder)}
                    >
                        {placeholder.buttonText}
                    </Button>
                ))}
            </ButtonGroup> */}
            <div id={id} style={{ backgroundColor: bgColor }}></div>
        </>
    );
};

export default ConfigurationAreaRichText;