import { createRef, Props, PropsWithChildren, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { Icons } from "../Helpers/Icons";
import { Buttons } from "./Buttons";
import { ButtonSpinner } from "./ButtonSpinner";
import _ from "lodash";

import {
    createTable,
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
    SortingState,
    getSortedRowModel,
    SortingFn,
    FilterFn,
    ColumnFiltersState,
    Column,
    Table,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getFilteredRowModel,
    RowData
} from '@tanstack/react-table'

import { useQuery } from 'react-query'

import React from "react";
import { GridColFilter, GridColSort, GridFilterDto } from "../Models/GridModel";
import { PaginatedResult } from "../Models/PagiantedResult";
import { Loader } from "./Loader";
import { Dictionary } from "../Interfaces/Dictionary";

export type CustomColumnDef<RowType extends RowData> = ColumnDef<RowType> & { dataList?: string[] };

interface GridProps<RowType extends RowData> {
    columns: CustomColumnDef<RowType>[]
    getData: (options: GridFilterDto) => Promise<PaginatedResult<RowType> | null>,
    maxPageSize: number
}

const Grid = <T extends object>(props: GridProps<T> & { children?: ReactNode }): ReactElement<any, any> | null => {

    const { columns, getData, maxPageSize } = props;

    const [sorting, setSorting] = React.useState<SortingState>([])

    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: maxPageSize,
        })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = React.useState("")
    const [preventFetchData, setPreventFetchData] = React.useState(false)

    const fetchDataOptions = {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
        globalFilter
    }

    const dataQuery = useQuery(
        ['data', fetchDataOptions],
        () => fetchData(fetchDataOptions),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 0
        }
    )

    async function fetchData(options?: {
        pageIndex: number
        pageSize: number,
        sorting: SortingState,
        columnFilters: ColumnFiltersState
    }) {
        if (!preventFetchData) {
            let fetchOptions: GridFilterDto = {
                pageSize: (options ? pageSize : maxPageSize),
                pageIndex: (options ? pageIndex + 1 : 1),
                sorting: (options ? options.sorting.map(sort => {
                    const s: GridColSort = { name: sort.id, isDesc: sort.desc };
                    return s;
                })[0] : undefined),
                columnFilters: (options ? options.columnFilters.map(filter => {
                    const f: GridColFilter = { name: filter.id, value: filter.value };
                    return f;
                }) : [])
            };

            let apiData = await getData(fetchOptions);

            return {
                rows: apiData?.records,
                pageCount: (apiData?.records.length == 0 ? 1 : Math.ceil(apiData?.totalRecords! / pageSize)),
            }
        } else
            setPreventFetchData(false);
    }

    const table = useReactTable({
        data: dataQuery.data?.rows ?? [],
        columns,
        pageCount: dataQuery.data?.pageCount ?? 1,
        state: {
            sorting,
            pagination,
            columnFilters,
            // globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues()
    })

    React.useEffect(() => {
        table.resetPageIndex();
    }, [sorting, columnFilters]);

    return (
        <>
            <div className="p-2">
                <div className="h-2" />
                <div className="overflow-auto mb-4 bg-white p-4 rounded">
                    <table className="table table-striped">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => {
                                        return (
                                            <th scope="col" key={header.id} colSpan={header.colSpan} style={{ paddingLeft: '10px' }}>
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <div
                                                            {...{
                                                                className: header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none'
                                                                    : '',
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            <b>
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                            </b>
                                                            {{
                                                                asc: <span style={{ fontSize: "20px" }} className={Icons.AngleUp + " ms-2"}></span>,
                                                                desc: <span style={{ fontSize: "20px" }} className={Icons.AngleDown + " ms-2"}></span>
                                                            }[header.column.getIsSorted() as string] ?? null}
                                                        </div>
                                                        {header.column.getCanFilter() ? (
                                                            <div>
                                                                <Filter column={header.column} table={table} dataList={columns.find(c => c.id == header.column.id)?.dataList} />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {dataQuery.isFetching ?
                                <>
                                    <tr>
                                        <td colSpan={100}>
                                            <span>Loading...</span>
                                            <Spinner
                                                className="ms-2"
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                </>
                                :
                                <>
                                    {table.getRowModel().rows.length > 0 &&
                                        <>
                                            {table.getRowModel().rows.map(row => {
                                                return (
                                                    <tr key={row.id}>
                                                        {row.getVisibleCells().map(cell => {
                                                            return (
                                                                <td key={cell.id} style={{ paddingLeft: '10px' }}>
                                                                    {flexRender(
                                                                        cell.column.columnDef.cell,
                                                                        cell.getContext()
                                                                    )}
                                                                </td>
                                                            )
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    }
                                    {table.getRowModel().rows.length == 0 &&
                                        <>
                                            <tr>
                                                <td colSpan={100}>
                                                    No records have been found
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="h-2" />
                <div className="flex items-center gap-2">
                    <button
                        className="border rounded p-1"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className={Icons.AngleDoubleLeft}></span>
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className={Icons.AngleLeft}></span>
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className={Icons.AngleRight}></span>
                    </button>
                    <button
                        className="border rounded p-1"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className={Icons.AngleDoubleRight}></span>
                    </button>
                    <span className="flex items-center gap-1">
                        <div>
                            <span>Page</span>
                            <span className="ms-1">
                                <b>{table.getState().pagination.pageIndex + 1}</b> of{' '}
                                <b>{table.getPageCount()}</b>
                            </span>
                            <span className="ms-1 me-1">
                                | Go to page
                            </span>
                            <input
                                type="number"
                                defaultValue={table.getState().pagination.pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    table.setPageIndex(page)
                                }}
                                className="border p-1 rounded w-16"
                            />
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
}

function Filter({ column, table, dataList }: { column: any; table: Table<any>; dataList?: string[] }) { // column: Column<any>
    // const firstValue = table
    //     .getPreFilteredRowModel()
    //     .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    let values = [];

    let firstValueIsNumber = false; // typeof firstValue === 'number'

    return firstValueIsNumber ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    // min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    // max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                    }
                    // placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
                    //     ? `(${column.getFacetedMinMaxValues()?.[0]})`
                    //     : ''
                    //     }`}
                    placeholder={"Min"}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    // min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    // max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={value =>
                        column.setFilterValue((old: [number, number]) => [old?.[0], value])
                    }
                    // placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                    //     ? `(${column.getFacetedMinMaxValues()?.[1]})`
                    //     : ''
                    //     }`}
                    placeholder={"Max"}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            {dataList &&
                <datalist id={column.id + 'list'}>
                    {dataList.map((value: any) => (
                        <option value={value} key={value} />
                    ))}
                </datalist>
            }
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={value => {
                    column.setFilterValue(value)
                }}
                placeholder={`Search...`}
                className="w-36 border p-2 rounded"
                list={column.id + 'list'}
            />
            <div className="h-1" />
        </>
    )
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => { setValue(e.target.value) }} />
    )
}

export { Grid }
