import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import { RoleModel } from "../Models/RoleModel"

interface AuthorizeAccessProps {
    permissionKey: string,
    allowAccess?: boolean
}

interface AuthorizeAccessByRoleProps {
    roleKey: string,
    allowAccess?: boolean
}

const AuthorizeAccess: FC<AuthorizeAccessProps> = ({ permissionKey, allowAccess, children }) => {
    const userPermissions = useSelector<RootState, string[]>(({ auth }) => auth.userPermissions!);

    if (allowAccess || (userPermissions != undefined && userPermissions.findIndex(p => p == permissionKey) > -1))
        return <>{children}</>;
    else
        return <></>;
}

const AuthorizeAccessByRole: FC<AuthorizeAccessByRoleProps> = ({ roleKey, allowAccess, children }) => {
    const userRoles = useSelector<RootState, RoleModel[]>(({ auth }) => auth.user?.roles!);

    if (allowAccess || (userRoles != undefined && userRoles.findIndex(p => p.name == roleKey) > -1))
        return <>{children}</>;
    else
        return <></>;
}


export { AuthorizeAccess, AuthorizeAccessByRole }
