import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { ConfigurationAreaModel } from "../models/ConfigurationAreaModel";
import { getConfigurationArea, getConfigurationAreaList } from "./ConfigurationAreaActions";

export const actionTypes = {
    ConfigurationAreaRequested: '[Request Configuration Area] Action',
    ConfigurationAreaLoaded: '[Loaded Configuration Area] API',
    ConfigurationAreaFailed: '[Failed Configuration Area] API',
    ConfigurationAreaSaved: '[Configuration Area Saved] Action'
}

export interface IConfigurationAreaState {
    configurationArea?: ConfigurationAreaModel[],
    loading: boolean,
    configurationAreaNotFound: boolean
}

const initialConfigurationAreaState: IConfigurationAreaState = {
    configurationArea: undefined,
    loading: true,
    configurationAreaNotFound: false,
}

export const reducer =
    (
        state: IConfigurationAreaState = initialConfigurationAreaState,
        action: ActionWithPayload<IConfigurationAreaState>
    ): IConfigurationAreaState => {

        switch (action.type) {
            case actionTypes.ConfigurationAreaRequested: {
                return { ...state, loading: true };
            }

            case actionTypes.ConfigurationAreaLoaded: {
                return {
                    ...state,
                    configurationArea: action.payload?.configurationArea,
                    loading: false,
                    configurationAreaNotFound: false
                };
            }

            case actionTypes.ConfigurationAreaFailed: {
                return {
                    ...state,
                    loading: false,
                    configurationAreaNotFound: true
                };
            }

            default:
                return state
        }
    }

export const actions = {
    requestConfigurationArea: () => ({ type: actionTypes.ConfigurationAreaRequested, payload: {} }),
    fulfillConfigurationArea: (configurationArea: ConfigurationAreaModel[]) => ({ type: actionTypes.ConfigurationAreaLoaded, payload: { configurationArea } }),
    fulfillFailedConfigurationArea: () => ({ type: actionTypes.ConfigurationAreaFailed }),
    fulfillConfigurationAreaSaved: (configurationArea: ConfigurationAreaModel[]) => ({ type: actionTypes.ConfigurationAreaSaved, payload: { configurationArea } }),
}

export function* saga() {
    function* configurationAreaRequested(action: any) {
        const userResponse: ServiceResponse<ConfigurationAreaModel[]> = yield getConfigurationAreaList();

        if (userResponse.isSuccess)
            yield put(actions.fulfillConfigurationArea(userResponse.data!));
        else
            yield put(actions.fulfillFailedConfigurationArea());
    }

    yield takeLatest(actionTypes.ConfigurationAreaRequested, configurationAreaRequested);
    yield takeLatest(actionTypes.ConfigurationAreaSaved, configurationAreaRequested);

}