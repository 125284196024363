import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import * as auth from '../../app/core/auth'
import * as menu from '../../_metronic/layout/components/aside'
import { reducer as peopleReducer, saga as peopleSaga } from '../../app/core/people/redux/PeopleRedux';
import * as profile from '../../app/core/profile'
import * as conversation from '../../app/conversation'
import * as mhfaDashboard from '../../app/mhfaDashboard'
import * as knowledgehub from '../../app/knowledgehub/redux/KnowledgeHubRedux'
import * as knowledgeHubCauseAndImpact from '../../app/knowledgehub/redux/KnowledgeHubCausesAndImpactRedux'
import * as beneficiary from '../../app/beneficiary/redux/BeneficiaryRedux'
import * as beneficiaryList from '../../app/beneficiary/redux/BeneficiaryListRedux'
import * as configurationArea from '../../app/configurationArea/redux/ConfigurationAreaRedux'
import * as niko from '../../app/nikoniko/redux/NikoRedux'
import * as firstAiderList from '../../app/firstaiders/redux/FirstAidersRedux'
import * as organisationDashboard from '../../app/dashboard/redux/OrganisationDashboardRedux'
import * as systemDashboard from '../../app/dashboard/redux/SystemDashboardRedux'
import * as complianceDashboard from '../../app/complianceDashboard/redux/ComplianceDashboardRedux'
import * as recommendations from '../../app/recommendations/redux/RecommendationsRedux'

export const rootReducer = combineReducers({
    auth: auth.reducer,
    menu: menu.reducer,
    people: peopleReducer,
    profile: profile.reducer,
    conversation: conversation.reducer,
    beneficiary: beneficiary.reducer,
    beneficiaryList: beneficiaryList.reducer,
    mhfaDashboard: mhfaDashboard.reducer,
    knowledgehub: knowledgehub.reducer,
    knowledgeHubCauseAndImpact: knowledgeHubCauseAndImpact.reducer,
    configurationArea: configurationArea.reducer,
    niko: niko.reducer,
    firstAiderList: firstAiderList.reducer,
    organisationDashboard: organisationDashboard.reducer,
    systemDashboard: systemDashboard.reducer,
    complianceDashboard: complianceDashboard.reducer,
    recommendations: recommendations.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
    yield all([
        auth.saga(),
        menu.saga(),
        peopleSaga(),
        profile.saga(),
        conversation.saga(),
        beneficiary.saga(),
        beneficiaryList.saga(),
        knowledgehub.saga(),
        knowledgeHubCauseAndImpact.saga(),
        mhfaDashboard.saga(),
        configurationArea.saga(),
        niko.saga(),
        firstAiderList.saga(),
        organisationDashboard.saga(),
        systemDashboard.saga(),
        complianceDashboard.saga(),
        recommendations.saga()
    ])
}