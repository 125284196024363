import React, { FC, useEffect } from 'react'
import { RootState } from '../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../@Common/UIComponents/Cards'
import * as systemDashboardRedux from '../redux/SystemDashboardRedux'
import { CardGroup } from 'react-bootstrap'
import { Loader } from '../../../@Common/UIComponents/Loader'
import SystemAssesmentSection from './SystemAssesmentSection'
import CauseAndImpactRankingEntry from './CauseAndImpactRankingEntry'

const mapState = (state: RootState) => ({
    systemDashboardState: state.systemDashboard,
    permissions: state.auth.userPermissions
})
const connector = connect(mapState, systemDashboardRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const SystemView: FC<PropsFromRedux> = ({ systemDashboardState, permissions }) => {
    const dispatch = useDispatch();

    let { selectedMonthTitle, selectedMonthYear,
        nextMonthTitle, nextMonthNumber, nextMonthYear,
        previousMonthTitle, previousMonthNumber, previousMonthYear,
        systemAssesmentLoading, systemAssesment,
        systemTopCausesLoading, systemTopCauses,
        systemTopImpactsLoading, systemTopImpacts } = systemDashboardState;

    useEffect(() => {
        async function loadSystemAssesment() {
            dispatch(systemDashboardRedux.actions.requestSystemAssesment());
        }

        async function loadSystemTopCauses() {
            dispatch(systemDashboardRedux.actions.requestSystemTopCauses());
        }

        async function loadSystemTopImpacts() {
            dispatch(systemDashboardRedux.actions.requestSystemTopImpacts());
        }

        loadSystemAssesment();
        loadSystemTopCauses();
        loadSystemTopImpacts();
    }, []);

    const getSystemDataForPreviousMonth = () => {
        dispatch(systemDashboardRedux.actions.requestOtherMonthSystemData(previousMonthNumber, previousMonthYear));
    };

    const getSystemDataForNextMonth = () => {
        dispatch(systemDashboardRedux.actions.requestOtherMonthSystemData(nextMonthNumber, nextMonthYear));
    };

    return (
        <Loader loading={systemAssesmentLoading && systemTopCausesLoading && systemTopImpactsLoading}>
            {<>
                <CardGroup>
                    <ul className="nav-next-pre">
                        <li onClick={() => { getSystemDataForPreviousMonth() }}><i className="fas fa-chevron-left"></i> {previousMonthTitle} {previousMonthYear} <span>- Stats</span></li>
                        <li onClick={() => { getSystemDataForNextMonth() }}>{nextMonthTitle} {nextMonthYear} <span>- Stats</span> <i className="fas fa-chevron-right"></i></li>
                    </ul>

                    <div className='ic-widget-cont-wrapper ic-widget-cont-wrapper--2-col-desk col-6'>
                        <Cards
                            className='ic-widget-cont ic-widget-cont--r-spacer'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>{selectedMonthTitle} {selectedMonthYear}</h1>
                                        </div>
                                    </div>
                                </>
                            }>
                            <SystemAssesmentSection systemDashboardState={systemDashboardState} />
                        </Cards>
                    </div>

                    <div className='ic-widget-cont-wrapper ic-widget-cont-wrapper--2-col-desk col-6'>
                        <Cards
                            className='ic-widget-cont ic-widget-cont--r-spacer'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>Top Causes</h1>
                                        </div>
                                    </div>
                                </>
                            }>

                            <CauseAndImpactRankingEntry causeOrImpactRankedList={systemTopCauses} entryType="Causes" />                        
                        </Cards>
                        <Cards
                            className='ic-widget-cont ic-widget-cont--r-spacer'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>Top Impacts</h1>
                                        </div>
                                    </div>
                                </>
                            }>

                            <CauseAndImpactRankingEntry causeOrImpactRankedList={systemTopImpacts} entryType="Causes" />                       
                        </Cards>
                    </div>

                
                </CardGroup>
            </>}
        </Loader>
    );
};

export default connector(SystemView);