/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { lazy, FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../core/errors/ErrorsPage'
import { Logout, AuthPage } from '../core/auth'
import { RootState } from '../../setup'
import { App } from '../App'

const { PUBLIC_URL } = process.env

const RecommendationsPage = lazy(() => import('../recommendations/RecommendationsPage'))

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='recommendations/*' element={<RecommendationsPage />} />
          {isAuthorized ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/home' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }