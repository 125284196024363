import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import ViewFirstAiders from './components/viewFirstAiders';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'

const queryClient = new QueryClient()

const ViewFirstAidersPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path="/"
                element={
                    <>

                        <QueryClientProvider client={queryClient}>
                            <ViewFirstAiders/>
                        </QueryClientProvider>
                    </>
                }
            />
        </Route>
    </Routes>
)

export default ViewFirstAidersPage;