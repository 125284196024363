import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import RoutePath from '../../@Common/Helpers/RoutePathConstants'
import { PageTitle } from '../../_metronic/layout/core'
import KnowledgeHubCauseAndImpact from './components/knowledgeHubCauseAndImpact'

const knowledgehubPath = RoutePath.KNOWLEDGE_HUB_VIEW;

const KnowledgeHubCauseAndImpactPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path='/:causeAndImpactTitle/*'
                element={
                    <>     
                        <PageTitle breadcrumbs={[
                            {
                                title: 'All resources',
                                path: '/' + knowledgehubPath,
                                isSeparator: false,
                                isActive: false,
                            },
                            {
                                title: 'Sign Posting Resource',
                                path: '',
                                isSeparator: true,
                                isActive: true,
                            }
                        ]}>
                            Sign Posting Resource
                        </PageTitle>                   
                        <KnowledgeHubCauseAndImpact />
                    </>
                }
            />
        </Route>
        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
)

export default KnowledgeHubCauseAndImpactPage
