import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import KnowledgeHub from './components/knowledgeHub';

const KnowledgehubPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path="/"
                element={
                    <>
                        <KnowledgeHub />
                    </>
                }
            />
        </Route>
        <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
)

export default KnowledgehubPage;