import { put, takeLatest } from "redux-saga/effects";
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { GetNikoModel, NikoModel } from "../models/NikoModel";
import { getTodayNiko } from "./NikoActions";
import { showToastrError, showToastrErrors } from "../../../@Common/Helpers/Toastr";

export const actionTypes = {
    RequestNikoRequested: '[Request Niko] Action',
    RequestNikoLoaded: '[Loaded Niko] API',
    RequestNikoSubmitted: '[Submitted Niko] API',
}

export interface INikoState {
    submitted: boolean,
    loading: boolean
}

const initialNikoState: INikoState = {
    submitted: true,
    loading: false
}

export const reducer = (state: INikoState = initialNikoState, action: ActionWithPayload<INikoState>): INikoState => {
    switch (action.type) {
        case actionTypes.RequestNikoRequested: {
            return { ...state, loading: true };
        }

        case actionTypes.RequestNikoLoaded: {
            return {
                ...state,
                submitted: action.payload?.submitted || false,
                loading: false
            };
        }

        case actionTypes.RequestNikoSubmitted: {
            return {
                ...state,
                submitted: true,
                loading: false
            };
        }
        
        default:
            return state
    }
}

export const actions = {
    requestNiko: () => ({ type: actionTypes.RequestNikoRequested }),
    fulfillNiko: (submitted: GetNikoModel) => ({ type: actionTypes.RequestNikoLoaded, payload: { submitted } }),
    submitNiko: () => ({ type: actionTypes.RequestNikoSubmitted }),
}

export function* saga() {
    yield takeLatest(actionTypes.RequestNikoRequested, function* causesAndImpactsRequested() {
        const nikoResponse: ServiceResponse<GetNikoModel> = yield getTodayNiko();

        if (nikoResponse.isSuccess && nikoResponse.data != null) {
            yield put(actions.fulfillNiko(nikoResponse.data));
        }
        else
        {
            if (nikoResponse.errors.length > 1) {
                showToastrErrors(nikoResponse.errors);
            } else {
                showToastrError("An unexpected error has occurred while trying to get today's niko.");
            }
        }            
    })

    function* requestNiko() {
        yield put(actions.requestNiko());
    }
}