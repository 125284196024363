import * as authRedux from "../../app/core/auth/redux/AuthRedux"
import * as menuRedux from '../../_metronic/layout/components/aside/redux/MenuRedux'
import { ServiceResponse } from "../../@Common/Interfaces/ServiceResponse"
import { showToastrError } from "../../@Common/Helpers/Toastr"
import { UserTokenRefreshedModel } from "../../app/core/auth/models/UserTokenRefreshedModel"

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.common['X-Content-Type-Option'] = 'no-sniff'
  axios.defaults.headers.common['X-Frame-Options'] = 'SAMEORIGIN'

  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  const API_URL = process.env.REACT_APP_API_URL + "/api/v1/user";
  const REFRESH_TOKEN_URL = API_URL + "/RefreshToken";

  function interceptorResponseResponse(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }

  function interceptorResponseError(error) {
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    // /* 
    //    * When response code is 401, try to refresh the token.
    //    * Eject the interceptor so it doesn't loop in case
    //    * token refresh causes the 401 response
    //    */

    axios.interceptors.response.eject(interceptor);

    const {
      auth: { refreshToken },
    } = store.getState()

    return axios.post(REFRESH_TOKEN_URL, {
      'refreshToken': refreshToken.token
    }).then(response => {
      let res: ServiceResponse<UserTokenRefreshedModel> = response.data;

      if (res.isSuccess) {
        if (!res.data?.sameToken) {
          store.dispatch(authRedux.actions.tokenRefreshed(res.data?.accessToken!, res.data?.refreshToken!))
          return axios(error.response.config);
        }
      } else {
        showToastrError("Your session has expired. Kindly log in again");
        store.dispatch(authRedux.actions.logout())
        store.dispatch(menuRedux.actions.clearMenuItems);
        document.location.reload();
      }
    }).catch(error => {
      return Promise.reject(error);
    })
      .finally(() => { interceptor = axios.interceptors.response.use(interceptorResponseResponse, interceptorResponseError) });
  }

  let interceptor = axios.interceptors.response.use(interceptorResponseResponse, interceptorResponseError);
}