import axios from 'axios'
import moment from 'moment';
import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { UserModel } from '../../../../@Common/Models/UserModel'
import { UserLoginResponseModel, UserTokenModel } from '../models/UserLoginResponseModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/user";
const MULTITENANCY_URL = process.env.REACT_APP_API_URL + "/api/v1/tenant";

export const LOGIN_URL = `${API_URL}/Login`
export const REQUEST_PASSWORD_URL = `${API_URL}/Forgot_password`
export const GET_USER_BY_TOKEN = `${API_URL}/GetUserByToken`
export const GET_USER_PERMISSIONS = `${API_URL}/GetUserPermissions`
export const REVOKE_REFRESH_TOKEN_URL = `${API_URL}/RevokeRefreshToken`
export const REFRESH_MULTITENANCY_KEYS = `${MULTITENANCY_URL}/CacheMultitenancyKeys`

export const login = async (username: string, password: string) => {
  let loginResponse = await postToServerAPI<ServiceResponse<UserLoginResponseModel>>(LOGIN_URL, {
    username,
    password,
  });

  return loginResponse;
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post('', {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email
  })
}

export const getUserByToken = async () => {
  let response = await postToServerAPI<UserModel>(GET_USER_BY_TOKEN);
  return response;
}

export const getUserPermissions = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<string[]>>(GET_USER_PERMISSIONS, { userId });
  return response;
}

export const revokeRefreshToken = async (userId: string) => {
  await postToServerAPI<ServiceResponse>(REVOKE_REFRESH_TOKEN_URL, { userId });
}

export const refreshMultiTenancyKeys = async () => {
  await getFromServerAPI<ServiceResponse>(REFRESH_MULTITENANCY_KEYS);
}