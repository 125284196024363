import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { RecordsConversationServiceResponse } from "../../../@Common/Interfaces/RecordsConversationServiceResponse";
import { GridFilterDto } from "../../../@Common/Models/GridModel";
import { GetAllOrganisationConversations } from "./ComplianceDashboardActions";

export const actionTypes = {
    ComplianceListConversationRequested: '[Request Compliance List Conversations] Action',
    ComplianceListConversationLoaded: '[Loaded Compliance List Conversations] API',
}

export interface IConversation {
    id: number,
    beneficiary: string,
    date: Date
}

export interface IConversationRecords {
    records: IConversation[];
}

export interface IComplianceDashboardState {
    complianceConversationListLoading: boolean,
    complianceConversationList: IConversationRecords | undefined,
}

const initialComplianceDashboardState: IComplianceDashboardState = {
    complianceConversationListLoading: false,
    complianceConversationList: { records: [] },
}

export const reducer = (state: IComplianceDashboardState = initialComplianceDashboardState, action: ActionWithPayload<IComplianceDashboardState>): IComplianceDashboardState => {
    switch (action.type) {
        case actionTypes.ComplianceListConversationRequested: {
            return {
                ...state,
                complianceConversationListLoading: true
            };
        }

        case actionTypes.ComplianceListConversationLoaded: {
            return {
                ...state,
                complianceConversationList: action.payload?.complianceConversationList,
                complianceConversationListLoading: false
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestComplianceConversationList: () => ({ type: actionTypes.ComplianceListConversationRequested }),
    fulfillComplianceConversationList: (complianceConversationList: IConversationRecords) => ({ type: actionTypes.ComplianceListConversationLoaded, payload: { complianceConversationList } })
}

export function* saga() {
    yield takeLatest(actionTypes.ComplianceListConversationRequested, function* complianceConversationListRequested() {

        let fetchDto: GridFilterDto = {
            pageIndex: 0,
            pageSize: 10
        };

        const complianceConversationRequestedResponse: RecordsConversationServiceResponse<IConversationRecords> = yield GetAllOrganisationConversations(fetchDto);

        if (complianceConversationRequestedResponse.isSuccess) {
            let conversationState: IConversationRecords = {
                records: complianceConversationRequestedResponse.data?.records
            }
            yield put(actions.fulfillComplianceConversationList(conversationState));
        } else {
            showToastrErrors(complianceConversationRequestedResponse.errors);
        }
    })
}