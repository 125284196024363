import { Button } from "react-bootstrap";

interface ButtonsProps {
    onClick?: () => void;
    variant: "primary" | "danger" | "success" | "secondary" | "info";
    disabled?: boolean;
    icon?: string;
    className?: string;
    size?: "lg" | "sm";
    tooltip?: string;
}

const Buttons: React.FC<ButtonsProps> = (props) => {
    let { variant, icon, className, size, disabled, tooltip, onClick, children } = props;

    return (
        <>
            <Button
                size={size}
                className={className}
                onClick={onClick}
                variant={variant}
                disabled={disabled ?? false}
                title={tooltip}
            >
                {icon && <i className={icon}></i>}
                {children}
            </Button>
        </>
    );
}

export { Buttons }
