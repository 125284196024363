import React from "react"
import { FC, useEffect } from "react"
import { CardGroup } from "react-bootstrap"
import { useIntl } from "react-intl"
import { connect, ConnectedProps, shallowEqual, useSelector } from 'react-redux'
import { Link, useNavigate } from "react-router-dom"
import { BaseRippleRoles } from "../../../@Common/Enums/BaseRippleRoles"
import { doesUserHaveRole } from "../../../@Common/Helpers/RoleHelper"
import { Cards } from "../../../@Common/UIComponents/Cards"
import { RootState } from "../../../setup"
import { MenuItemModel } from "../../../_metronic/layout/components/aside/models/MenuItemModel"
import { PageTitle } from "../../../_metronic/layout/core"
import RoutePath from '../../../@Common/Helpers/RoutePathConstants'


const mapState = (state: RootState) => ({
    permissions: state.auth.userPermissions,
    user: state.auth.user
})
const connector = connect(mapState)
type PropsFromRedux = ConnectedProps<typeof connector>

const HomeWrapper: FC<PropsFromRedux> = ({ permissions, user }) => {
    const navigate = useNavigate();

    useEffect(() => {
        async function redirectToAlternateHomePage() {
            if (user != null) {
                if (doesUserHaveRole(user, BaseRippleRoles.RippleAndCoSuperAdmin)) {
                    navigate("../" + RoutePath.SYSTEM_VIEW);
                } else if (doesUserHaveRole(user, BaseRippleRoles.TenantAdmin)) {
                    navigate("../" + RoutePath.PEOPLE);
                } else if (doesUserHaveRole(user, BaseRippleRoles.CommunityManager)) {
                    navigate("../" + RoutePath.ORGANISATION_VIEW);
                } else if (doesUserHaveRole(user, BaseRippleRoles.MHFA)) {
                    navigate("../" + RoutePath.SELF_VIEW);
                } else if (doesUserHaveRole(user, BaseRippleRoles.ComplianceOfficer)) {
                    navigate("../" + RoutePath.COMPLIANCE_VIEW);
                }
            }
        }

        redirectToAlternateHomePage();
    }, []);

    const intl = useIntl()
    const menuItems: MenuItemModel[] = useSelector<RootState>(({ menu }) => menu.menuItems, shallowEqual) as MenuItemModel[]

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'CORE.HOME' })}</PageTitle>
      <h2>Welcome!</h2>
      <div className="mt-4">
        <CardGroup>
          {menuItems && menuItems.filter(mi => mi.location).map((menuItem, i) =>
            <React.Fragment key={i}>
              <div className={`col-12`}>
                <Cards
                  border='dark'
                  title={
                    <>
                      <div className='row'>
                        <div className='col-10'>
                          <i className={menuItem.iconClass}></i>
                          <span className={"ms-1"}>
                            <Link to={"/" + menuItem.location}>
                              {menuItem.name}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </>
                  }
                  key={"menu-" + i}
                  className={"m-1"}
                >
                  <div className="row">
                    <p>{menuItem.parentMenuItem ? menuItem.parentMenuItem.name + " > " : ""}{menuItem.name}</p>
                  </div>
                </Cards>
              </div>
            </React.Fragment>
          )}
        </CardGroup>
      </div>
    </>
  )
}

export default connector(HomeWrapper);