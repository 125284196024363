import { put, takeLatest } from "redux-saga/effects";
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { CauseAndImpactShortModel } from "../models/CausesAndImpactModel";
import { getCausesAndImpacts } from "./KnowledgeHubActions";
import { showToastrError, showToastrErrors } from "../../../@Common/Helpers/Toastr";

export const actionTypes = {
    CausesAndImpactsRequested: '[Request Causes and Impacts] Action',
    CausesAndImpactsLoaded: '[Loaded Causes and Impacts] API',
    ShowNewCausesAndImpactModal: '[New Cause And Impact Modal] Action',
    CausesAndImpactSaved: '[Cause And Impact Saved] Action',
    CausesAndImpactDeleted: '[Cause And Impact Deleted] Action'
}

export interface IKnowledgeHubState {
    causesAndImpacts?: CauseAndImpactShortModel[],
    showNewCausesAndImpactModal: boolean,
    loading: boolean,
    currentCausesAndImpact?: CauseAndImpactShortModel,
}

const initialKnoledgeHubState: IKnowledgeHubState = {
    causesAndImpacts: undefined,
    showNewCausesAndImpactModal: false,
    loading: false,
    currentCausesAndImpact: undefined,
}

export const reducer = (state: IKnowledgeHubState = initialKnoledgeHubState, action: ActionWithPayload<IKnowledgeHubState>): IKnowledgeHubState => {
    switch (action.type) {
        case actionTypes.CausesAndImpactsRequested: {
            return { ...state, loading: true };
        }

        case actionTypes.CausesAndImpactsLoaded: {
            return {
                ...state,
                causesAndImpacts: action.payload?.causesAndImpacts,
                loading: false
            };
        }

        case actionTypes.ShowNewCausesAndImpactModal: {
            let showNewCausesAndImpactModal = action.payload?.showNewCausesAndImpactModal!;
            return { ...state, showNewCausesAndImpactModal, currentCausesAndImpact: undefined };
        }

        case actionTypes.CausesAndImpactSaved: {
            return { ...state, showNewCausesAndImpactModal: false };
        }
        
        default:
            return state
    }
}

export const actions = {
    requestCausesAndImpacts: () => ({ type: actionTypes.CausesAndImpactsRequested }),
    fulfillCausesAndImpacts: (causesAndImpacts: CauseAndImpactShortModel[]) => ({ type: actionTypes.CausesAndImpactsLoaded, payload: { causesAndImpacts } }),
    fulfillCausesAndImpactSaved: () => ({ type: actionTypes.CausesAndImpactSaved }),
    fulfillCausesAndImpactDeleted: () => ({ type: actionTypes.CausesAndImpactDeleted }),
    showNewCausesAndImpactModal: (showNewCausesAndImpactModal: boolean) => ({ type: actionTypes.ShowNewCausesAndImpactModal, payload: { showNewCausesAndImpactModal } }),
}

export function* saga() {
    yield takeLatest(actionTypes.CausesAndImpactsRequested, function* causesAndImpactsRequested() {
        const causesAndImpactsResponse: ServiceResponse<CauseAndImpactShortModel[]> = yield getCausesAndImpacts();

        if (causesAndImpactsResponse.isSuccess && causesAndImpactsResponse.data != null) {
            yield put(actions.fulfillCausesAndImpacts(causesAndImpactsResponse.data));
        }
        else
        {
            if (causesAndImpactsResponse.errors.length > 1) {
                showToastrErrors(causesAndImpactsResponse.errors);
            } else {
                showToastrError("An unexpected error has occurred while trying to get causes and impacts.");
            }
        }            
    })

    function* requestCausesAndImpacts() {

        yield put(actions.requestCausesAndImpacts());
    }

    yield takeLatest(actionTypes.CausesAndImpactSaved, requestCausesAndImpacts)
    yield takeLatest(actionTypes.CausesAndImpactDeleted, requestCausesAndImpacts);
}