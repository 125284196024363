import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { GridFilterDto } from "../../../@Common/Models/GridModel";
import { BeneficiaryModel } from "../models/BeneficiaryModel";
import { getBeneficiary } from "./BeneficiaryActions";

export const actionTypes = {
    BeneficiaryRequested: '[Request Beneficiary ] Action',
    BeneficiaryLoaded: '[Loaded Beneficiary ] API',
    BeneficiaryFailed: '[Failed Beneficiary ] API',
    BeneficiarySaved: '[Beneficiary Saved] Action'
}

export interface IBeneficiaryState {
    beneficiary?: BeneficiaryModel,
    loading: boolean,
    beneficiaryNotFound: boolean
}

const initialBeneficiaryState: IBeneficiaryState = {
    beneficiary: undefined,
    loading: false,
    beneficiaryNotFound: false,
}

export const reducer =
    (
        state: IBeneficiaryState = initialBeneficiaryState,
        action: ActionWithPayload<IBeneficiaryState>
    ): IBeneficiaryState => {

        switch (action.type) {
            case actionTypes.BeneficiaryRequested: {
                return { ...state, loading: true };
            }

            case actionTypes.BeneficiaryLoaded: {
                return {
                    ...state,
                    beneficiary: action.payload?.beneficiary,
                    loading: false,
                    beneficiaryNotFound: false
                };
            }

            case actionTypes.BeneficiaryFailed: {
                return {
                    ...state,
                    loading: false,
                    beneficiaryNotFound: true
                };
            }

            default:
                return state
        }
    }

export const actions = {
    requestBeneficiary: (id: number) => ({ type: actionTypes.BeneficiaryRequested, payload: { id } }),
    fulfillBeneficiary: (beneficiary: BeneficiaryModel) => ({ type: actionTypes.BeneficiaryLoaded, payload: { beneficiary } }),
    fulfillFailedBeneficiary: () => ({ type: actionTypes.BeneficiaryFailed }),
    fulfillBeneficiarySaved: (beneficiary: BeneficiaryModel) => ({ type: actionTypes.BeneficiarySaved, payload: { beneficiary } }),
}

export function* saga() {
    function* beneficiaryRequested(action: any) {
        const response: ServiceResponse<BeneficiaryModel> = yield getBeneficiary(action.payload.id);

        if (response.isSuccess)
            yield put(actions.fulfillBeneficiary(response.data!));
        else
            yield put(actions.fulfillFailedBeneficiary());
    }

    yield takeLatest(actionTypes.BeneficiaryRequested, beneficiaryRequested);
    yield takeLatest(actionTypes.BeneficiarySaved, beneficiaryRequested);
}