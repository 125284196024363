import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GetNikoModel } from '../models/NikoModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/nikoniko";

//NIKO Root actions
export const GET_TODAY_NIKO = `${API_URL}/GetTodayNiko`;
export const POST_TODAY_NIKO = `${API_URL}/PostTodayNiko`;

export const getTodayNiko = async () => {
    let response = await getFromServerAPI<ServiceResponse<GetNikoModel>>(GET_TODAY_NIKO, {});
    return response;
}

export const postTodayNiko = async (mood: number) => {
    let response = await postToServerAPI<ServiceResponse>(POST_TODAY_NIKO, { mood });
    return response;    
}