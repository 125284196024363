import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { GridColSort, GridFilterDto } from "../../../@Common/Models/GridModel";
import { FirstAiderModel } from "../models/FirstAiderModel";
import { getFirstAiderList } from "./FirstAidersActions";

export const actionTypes = {
    FirstAiderListRequested: '[Request First Aider List] Action',
    FirstAiderListLoaded: '[Loaded First Aider List] API',
    FirstAiderListFailed: '[Failed First Aider List] API',
}

export interface IFirstAiderState {
    firstAider?: FirstAiderModel,
    loading: boolean,
    firstAiderNotFound: boolean
}

const initialFirstAiderState: IFirstAiderState = {
    firstAider: undefined,
    loading: false,
    firstAiderNotFound: false,
}

export const reducer =
    (
        state: IFirstAiderState = initialFirstAiderState,
        action: ActionWithPayload<IFirstAiderState>
    ): IFirstAiderState => {

        switch (action.type) {
            case actionTypes.FirstAiderListRequested: {
                return { ...state, loading: true };
            }

            case actionTypes.FirstAiderListLoaded: {
                return {
                    ...state,
                    firstAider: action.payload?.firstAider,
                    loading: false,
                    firstAiderNotFound: false
                };
            }

            case actionTypes.FirstAiderListFailed: {
                return {
                    ...state,
                    loading: false,
                    firstAiderNotFound: true
                };
            }

            default:
                return state
        }
    }

export const actions = {
    requestFirstAiderList: () => ({ type: actionTypes.FirstAiderListRequested, payload: {} }),
    fulfillFirstAiderList: (firstAiderList: FirstAiderModel[]) => ({ type: actionTypes.FirstAiderListLoaded, payload: { firstAiderList } }),
    fulfillFailedFirstAiderList: () => ({ type: actionTypes.FirstAiderListFailed }),
}

export function* saga() {
    function* firstAiderListRequested(action: any) {
        let gridFilter: GridFilterDto = {
            pageIndex: 0,
            pageSize: 10
        };
        const userResponse: ServiceResponse<FirstAiderModel[]> = yield getFirstAiderList(gridFilter);

        if (userResponse.isSuccess)
            yield put(actions.fulfillFirstAiderList(userResponse.data!));
        else
            yield put(actions.fulfillFailedFirstAiderList());
    }

    yield takeLatest(actionTypes.FirstAiderListRequested, firstAiderListRequested);
}