import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { GridFilterDto } from "../../../@Common/Models/GridModel";
import { BeneficiaryModel } from "../models/BeneficiaryModel";
import { getBeneficiary, getBeneficiaryList } from "./BeneficiaryActions";

export const actionTypes = {
    BeneficiaryListRequested: '[Request Beneficiary List] Action',
    BeneficiaryListLoaded: '[Loaded Beneficiary List] API',
    BeneficiaryListFailed: '[Failed Beneficiary List] API',
    BeneficiaryListSaved: '[Beneficiary Saved] Action'
}

export interface IBeneficiaryState {
    beneficiary?: BeneficiaryModel,
    loading: boolean,
    beneficiaryNotFound: boolean
}

const initialBeneficiaryState: IBeneficiaryState = {
    beneficiary: undefined,
    loading: false,
    beneficiaryNotFound: false,
}

export const reducer =
    (
        state: IBeneficiaryState = initialBeneficiaryState,
        action: ActionWithPayload<IBeneficiaryState>
    ): IBeneficiaryState => {

        switch (action.type) {
            case actionTypes.BeneficiaryListRequested: {
                return { ...state, loading: true };
            }

            case actionTypes.BeneficiaryListLoaded: {
                return {
                    ...state,
                    beneficiary: action.payload?.beneficiary,
                    loading: false,
                    beneficiaryNotFound: false
                };
            }

            case actionTypes.BeneficiaryListFailed: {
                return {
                    ...state,
                    loading: false,
                    beneficiaryNotFound: true
                };
            }

            default:
                return state
        }
    }

export const actions = {
    requestBeneficiaryList: () => ({ type: actionTypes.BeneficiaryListRequested, payload: {} }),
    fulfillBeneficiaryList: (beneficiaryList: BeneficiaryModel[]) => ({ type: actionTypes.BeneficiaryListLoaded, payload: { beneficiaryList } }),
    fulfillFailedBeneficiaryList: () => ({ type: actionTypes.BeneficiaryListFailed }),
    fulfillBeneficiaryListSaved: (beneficiaryList: BeneficiaryModel[]) => ({ type: actionTypes.BeneficiaryListSaved, payload: { beneficiaryList } }),
}

export function* saga() {
    function* beneficiaryListRequested(action: any) {
        let fetchDto: GridFilterDto = {
            pageIndex: 0,
            pageSize: 10
        };
        const userResponse: ServiceResponse<BeneficiaryModel[]> = yield getBeneficiaryList(fetchDto);

        if (userResponse.isSuccess)
            yield put(actions.fulfillBeneficiaryList(userResponse.data!));
        else
            yield put(actions.fulfillFailedBeneficiaryList());
    }

    yield takeLatest(actionTypes.BeneficiaryListRequested, beneficiaryListRequested);
    yield takeLatest(actionTypes.BeneficiaryListSaved, beneficiaryListRequested);
}