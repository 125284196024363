export enum Icons {
    Plus = "fas fa-plus",
    Trash = "fas fa-trash",
    User = "fas fa-user",
    Times = "fas fa-times",
    Check = "fas fa-check",
    Envelope = "fas fa-envelope",
    ExclamationTriangle = "fas fa-exclamation-triangle",
    Lock = "fas fa-lock",
    PeopleArrows = "fas fa-people-arrows",
    UserPlus = "fas fa-user-plus",
    AngleUp = "fas fa-angle-up",
    AngleDown = "fas fa-angle-down",
    AngleDoubleLeft = "fas fa-angle-double-left",
    AngleDoubleRight = "fas fa-angle-double-right",
    AngleRight = "fas fa-angle-right",
    AngleLeft = "fas fa-angle-left",
    Spinner = "fas fa-spinner",
    Expand = "fas fa-expand",
    Edit = "fas fa-edit",
    Email = "fas fa-at",
    HandShake = "fas fa-handshake",
    Archive = "fas fa-archive",
    Info = "fas fa-info",
    ArrowRight = "fas fa-arrow-right"
}