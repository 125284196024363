/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { AsideMenu } from './components/AsideMenu'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

const AsideDefault: FC = () => {
  const { config, classes } = useLayout()
  const { aside } = config

  //#region Albert to remove with clean media queries
  // const [logo, showLogo] = useState(window.innerWidth < 992);

  // const updateMedia = () => {
  //   showLogo(window.innerWidth < 992);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", updateMedia);
  //   return () => window.removeEventListener("resize", updateMedia);
  // });
  
  //#endregion

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/home'>
            <img
              alt='Logo'
              className='h-45px logo'
              src={toAbsoluteUrl('/media/logos/logo_plato_white_01.svg')}
              //src={toAbsoluteUrl('/media/logos/GXO_logo.png')}
              //src={toAbsoluteUrl('/media/logos/natwest-logo.png')}
              //src={toAbsoluteUrl('/media/logos/mitie-logo.svg')} 
              //src={toAbsoluteUrl('/media/logos/volkerwessel-logo.svg')} 
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/home'>
            <img
              alt='Logo'
              className='h-45px logo'
              src={toAbsoluteUrl('/media/logos/logo_plato_white_01.svg')}
              //src={toAbsoluteUrl('/media/logos/GXO_logo.png')}
              //src={toAbsoluteUrl('/media/logos/natwest-logo.png')}  
              //src={toAbsoluteUrl('/media/logos/mitie-logo.svg')} 
              //src={toAbsoluteUrl('/media/logos/volkerwessel-logo.svg')}  
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            {aside.theme === 'light' && (
              <KTSVG
                path={'/media/icons/duotune/arrows/arr080.svg'}
                className={'svg-icon-1 rotate-180'}
              />)}
            {aside.theme === 'dark' && (
              <span className="fas fa-angle-double-left menu-arrow rotate-180"></span>
            )}
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <div className='asideresponsive'>
          {/* begin::Logo */}
          {/* Albert to clean with clean media queries */}
          <Link to='/home'>
              <img
                alt='Logo'
                className='h-45px logo'
                src={toAbsoluteUrl('/media/logos/logo_plato_white_01.svg')}
                //src={toAbsoluteUrl('/media/logos/GXO_logo.png')}
                //src={toAbsoluteUrl('/media/logos/natwest-logo.png')}  
                //src={toAbsoluteUrl('/media/logos/mitie-logo.svg')} 
                //src={toAbsoluteUrl('/media/logos/volkerwessel-logo.svg')}  
              />
          </Link>
        {/* end::Logo */}
        </div>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export { AsideDefault }
