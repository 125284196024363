import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { BeneficiaryModel } from '../models/BeneficiaryModel';


const API_URL = process.env.REACT_APP_API_URL + "/api/v1/Beneficiary";

export const GET_BENEFICIARY_URL = `${API_URL}/GetBeneficiary`;
export const GET_BENEFICIARY_LIST_URL = `${API_URL}/GetBeneficiaryList`;
export const SAVE_BENEFICIARY_URL = `${API_URL}/SaveBeneficiary`;
export const SAVE_BENEFICIARY_LIST_URL = `${API_URL}/SaveBeneficiaryList`;
export const UPDATE_BENEFICIARY_URL = `${API_URL}/UpdateBeneficiary`;
export const DELETE_BENEFICIARY_URL = `${API_URL}/DeleteBeneficiary`;
export const ARCHIVE_BENEFICIARY_URL = `${API_URL}/ArchiveBeneficiary`;



export const getBeneficiary = async (id: number) => {
    let response = await getFromServerAPI<ServiceResponse<BeneficiaryModel>>(GET_BENEFICIARY_URL, { id });
    return response;
}

export const getBeneficiaryList = async (filter: GridFilterDto) => {
    let response = await postToServerAPI<PaginatedServiceResponse<BeneficiaryModel>>(GET_BENEFICIARY_LIST_URL, filter);
    return response;
}

export const saveBeneficiary = async (beneficiary: BeneficiaryModel) => {
    let response = await postToServerAPI<ServiceResponse>(SAVE_BENEFICIARY_URL, beneficiary);
    return response;
}

export const saveBeneficiaryList = async (beneficiaryList: BeneficiaryModel[]) => {
    let response = await postToServerAPI<ServiceResponse>(SAVE_BENEFICIARY_LIST_URL, beneficiaryList);
    return response;
}

export const updateBeneficiary = async (beneficiary: BeneficiaryModel) => {
    let response = await postToServerAPI<ServiceResponse>(UPDATE_BENEFICIARY_URL, beneficiary);
    return response;
}

export const deleteBeneficiary = async (beneficiary: BeneficiaryModel) => {
    let response = await postToServerAPI<ServiceResponse>(DELETE_BENEFICIARY_URL, beneficiary);
    return response;
}

export const archiveBeneficiary = async (beneficiary: BeneficiaryModel) => {
    let response = await postToServerAPI<ServiceResponse>(ARCHIVE_BENEFICIARY_URL, beneficiary);
    return response;
}
