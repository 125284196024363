import {useLayout} from '../../core/LayoutProvider'
import {IconToolbar} from './IconToolbar'

const Toolbar = () => {
  const {config} = useLayout()

  switch (config.toolbar.layout) {
    default:
      return <IconToolbar />
  }
}

export {Toolbar}
