import React, { FC, useEffect } from 'react'
import { RootState } from '../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../@Common/UIComponents/Cards'
import * as organisationDashboardRedux from '../redux/OrganisationDashboardRedux'
import { CardGroup } from 'react-bootstrap'
import { Loader } from '../../../@Common/UIComponents/Loader'
import OrganisationAssesmentSection from './OrganisationAssesmentSection'
import OrganisationAiderStatusEntry from './OrganisationAiderStatusEntry'
import CauseAndImpactRankingEntry from './CauseAndImpactRankingEntry'

const mapState = (state: RootState) => ({
    organisationDashboardState: state.organisationDashboard,
    permissions: state.auth.userPermissions
})
const connector = connect(mapState, organisationDashboardRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const OrganisationView: FC<PropsFromRedux> = ({ organisationDashboardState, permissions }) => {
    const dispatch = useDispatch();

    let { selectedMonthTitle, selectedMonthYear,
        nextMonthTitle, nextMonthNumber, nextMonthYear,
        previousMonthTitle, previousMonthNumber, previousMonthYear,
        mhfaStatusListLoading, mhfaStatusList,
        organisationAssesmentLoading, organisationAssesment,
        organisationTopCausesLoading, organisationTopCauses,
        organisationTopImpactsLoading, organisationTopImpacts } = organisationDashboardState;

    useEffect(() => {
        async function loadMHFAStatusList() {
            dispatch(organisationDashboardRedux.actions.requestMHFAStatusList());
        }

        async function loadOrganisationAssesment() {
            dispatch(organisationDashboardRedux.actions.requestOrganisationAssesment());
        }

        async function loadOrganisationTopCauses() {
            dispatch(organisationDashboardRedux.actions.requestOrganisationTopCauses());
        }

        async function loadOrganisationTopImpacts() {
            dispatch(organisationDashboardRedux.actions.requestOrganisationTopImpacts());
        }

        loadMHFAStatusList();
        loadOrganisationAssesment();
        loadOrganisationTopCauses();
        loadOrganisationTopImpacts();
    }, []);

    const getOrganisationDataForPreviousMonth = () => {
        dispatch(organisationDashboardRedux.actions.requestOtherMonthOrganisationData(previousMonthNumber, previousMonthYear));
    };

    const getOrganisationDataForNextMonth = () => {
        dispatch(organisationDashboardRedux.actions.requestOtherMonthOrganisationData(nextMonthNumber, nextMonthYear));
    };

    return (
        <Loader loading={mhfaStatusListLoading && organisationAssesmentLoading && organisationTopCausesLoading && organisationTopImpactsLoading}>
            {<>
                <CardGroup>
                    <ul className="nav-next-pre">
                        <li onClick={() => { getOrganisationDataForPreviousMonth() }}><i className="fas fa-chevron-left"></i> {previousMonthTitle} {previousMonthYear} <span>- Stats</span></li>
                        <li onClick={() => { getOrganisationDataForNextMonth() }}>{nextMonthTitle} {nextMonthYear} <span>- Stats</span> <i className="fas fa-chevron-right"></i></li>
                    </ul>

                    <div className='ic-widget-cont-wrapper ic-widget-cont-wrapper--2-col-desk col-6'>
                        <Cards
                            className='ic-widget-cont ic-widget-cont--r-spacer'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>{selectedMonthTitle} {selectedMonthYear}</h1>
                                        </div>
                                    </div>
                                </>
                            }>
                            <OrganisationAssesmentSection organisationDashboardState={organisationDashboardState} />
                        </Cards>
                    </div>

                    <div className='ic-widget-cont-wrapper ic-widget-cont-wrapper--4-col-desk col-6'>
                        <Cards
                            className='ic-widget-cont ic-widget-cont--r-spacer'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>Top Causes</h1>
                                        </div>
                                    </div>
                                </>
                            }>

                            <CauseAndImpactRankingEntry causeOrImpactRankedList={organisationTopCauses} entryType="Causes" />
                        </Cards>
                        <Cards
                            className='ic-widget-cont ic-widget-cont--r-spacer'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>Top Impacts</h1>
                                        </div>
                                    </div>
                                </>
                            }>
                            <CauseAndImpactRankingEntry causeOrImpactRankedList={organisationTopImpacts} entryType="Impacts" />                            
                        </Cards>
                    </div>

                    <div className='ic-widget-cont-wrapper ic-widget-cont-wrapper--4-col-desk col-6'>
                        <Cards
                            className='ic-widget-cont'
                            title={
                                <>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="p-2">
                                            <h1>MHFA Status</h1>
                                        </div>
                                    </div>
                                </>
                            }>
                            <OrganisationAiderStatusEntry organisationDashboardState={organisationDashboardState} />
                        </Cards>
                    </div>

                </CardGroup>
            </>}
        </Loader>
    );
};

export default connector(OrganisationView);