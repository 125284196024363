import { postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { FirstAiderModel } from '../models/FirstAiderModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/FirstAider";

export const GET_FIRST_AIDER_LIST_URL = `${API_URL}/GetFirstAiderList`;

export const getFirstAiderList = async (gridFilter: GridFilterDto) => {
    let response = await postToServerAPI<PaginatedServiceResponse<FirstAiderModel>>(GET_FIRST_AIDER_LIST_URL, gridFilter);
    return response;
}

