import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors, showToastrError } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { OrganisationAiderStatusModel } from '../models/OrganisationAiderStatusModel';
import { AssesmentModel } from '../models/AssesmentModel';
import { CauseAndImpactPercentageModel } from '../models/CauseAndImpactPercentageModel';
import { GetOrganisationAiderStatus, GetOrganisationAssesmentForMonth, GetOrganisationTopCausesForMonth, GetOrganisationTopImpactsForMonth } from "./DashboardActions";
import { getNormalisedMonthNumber, getMonthNameFromNormalisedMonthNumber, getYearNumberFromNewNormalisedMonthNumberValue, getMonthNumberFromNewUnnormalisedMonthNumberValue } from "../../../@Common/Helpers/DateHelper";

export const actionTypes = {
    MHFAStatusListRequested: '[Request MHFA Status List] Action',
    MHFAStatusListLoaded: '[Loaded MHFA Status List] API',
    OrganisationAssesmentRequested: '[Request Organisation Assesment] Action',
    OrganisationAssesmentLoaded: '[Loaded Organisation Assesment] API',
    OrganisationTopCausesRequested: '[Request Organisation Top Causes] Action',
    OrganisationTopCausesLoaded: '[Loaded Organisation Top Causes] API',
    OrganisationTopImpactsRequested: '[Request Organisation Top Impacts] Action',
    OrganisationTopImpactsLoaded: '[Loaded Organisation Top Impacts] API',
    OtherMonthOrganisationDataRequested: '[Request Other Month Organisation Data] Action',
    OtherMonthOrganisationDataLoaded: '[Loaded Other Month Organisation Data] API'
}

export interface IOrganisationDashboardState {
    selectedMonthNumber: number,
    selectedMonthTitle: string,
    selectedMonthYear: number,
    previousMonthNumber: number,
    previousMonthTitle: string,
    previousMonthYear: number,
    nextMonthNumber: number,
    nextMonthTitle: string,
    nextMonthYear: number,
    mhfaStatusListLoading: boolean,
    mhfaStatusList: OrganisationAiderStatusModel[] | undefined,
    organisationAssesmentLoading: boolean,
    organisationAssesment: AssesmentModel | undefined,
    organisationTopCausesLoading: boolean,
    organisationTopCauses: CauseAndImpactPercentageModel[] | undefined,
    organisationTopImpactsLoading: boolean,
    organisationTopImpacts: CauseAndImpactPercentageModel[] | undefined,
}

const currentDate = new Date();
const previousMonthDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
const nextMonthDate = new Date(new Date().setMonth(new Date().getMonth() + 1));

const initialOrganisationDashboardState: IOrganisationDashboardState = {
    selectedMonthNumber: getNormalisedMonthNumber(currentDate),
    selectedMonthTitle: currentDate.toLocaleString('default', { month: 'long' }),
    selectedMonthYear: currentDate.getUTCFullYear(),
    previousMonthNumber: getNormalisedMonthNumber(previousMonthDate),
    previousMonthTitle: previousMonthDate.toLocaleString('default', { month: 'long' }),
    previousMonthYear: previousMonthDate.getUTCFullYear(),
    nextMonthNumber: getNormalisedMonthNumber(nextMonthDate),
    nextMonthTitle: nextMonthDate.toLocaleString('default', { month: 'long' }),
    nextMonthYear: nextMonthDate.getUTCFullYear(),
    mhfaStatusListLoading: false,
    mhfaStatusList: [],
    organisationAssesmentLoading: false,
    organisationAssesment: undefined,
    organisationTopCausesLoading: false,
    organisationTopCauses: [],
    organisationTopImpactsLoading: false,
    organisationTopImpacts: [],
}

export const reducer = (state: IOrganisationDashboardState = initialOrganisationDashboardState, action: ActionWithPayload<IOrganisationDashboardState>): IOrganisationDashboardState => {
    switch (action.type) {
        case actionTypes.MHFAStatusListRequested: {
            return {
                ...state,
                mhfaStatusListLoading: true
            };
        }

        case actionTypes.MHFAStatusListLoaded: {
            return {
                ...state,
                mhfaStatusList: action.payload?.mhfaStatusList,
                mhfaStatusListLoading: false
            };
        }

        case actionTypes.OrganisationAssesmentRequested: {
            return {
                ...state,
                organisationAssesmentLoading: true
            };
        }

        case actionTypes.OrganisationAssesmentLoaded: {
            return {
                ...state,
                organisationAssesment: action.payload?.organisationAssesment,
                organisationAssesmentLoading: false
            };
        }

        case actionTypes.OrganisationTopCausesRequested: {
            return {
                ...state,
                organisationTopCausesLoading: true
            };
        }

        case actionTypes.OrganisationTopCausesLoaded: {
            return {
                ...state,
                organisationTopCauses: action.payload?.organisationTopCauses,
                organisationTopCausesLoading: false
            };
        }

        case actionTypes.OrganisationTopImpactsRequested: {
            return {
                ...state,
                organisationTopImpactsLoading: true
            };
        }

        case actionTypes.OrganisationTopImpactsLoaded: {
            return {
                ...state,
                organisationTopImpacts: action.payload?.organisationTopImpacts,
                organisationTopImpactsLoading: false
            };
        }

        case actionTypes.OtherMonthOrganisationDataRequested: {
            return {
                ...state,
                mhfaStatusListLoading: true,
                organisationAssesmentLoading: true,
                organisationTopCausesLoading: true,
                organisationTopImpactsLoading: true
            };
        }

        case actionTypes.OtherMonthOrganisationDataLoaded: {
            let monthRequested = action.payload?.selectedMonthNumber;
            let yearRequested = action.payload?.selectedMonthYear;

            if (monthRequested === undefined || yearRequested == undefined) {
                showToastrError("Operation to return data for another month indicated success but some data was missing.");

                return {
                    ...state,
                    mhfaStatusListLoading: false,
                    organisationAssesmentLoading: false,
                    organisationTopCausesLoading: false,
                    organisationTopImpactsLoading: false
                };
            } else {
                let newSelectedMonthNumberValue: number = monthRequested;
                let newSelectedMonthNameValue: string = getMonthNameFromNormalisedMonthNumber(monthRequested);
                let newSelectedMonthYearNumberValue: number = yearRequested;       

                let newNextMonthNumberValue: number = getMonthNumberFromNewUnnormalisedMonthNumberValue(monthRequested + 1);
                let newNextMonthNameValue: string = getMonthNameFromNormalisedMonthNumber(newNextMonthNumberValue);                
                let newNextMonthYearNumberValue: number = getYearNumberFromNewNormalisedMonthNumberValue(newNextMonthNumberValue, newSelectedMonthNumberValue, yearRequested);            

                let newPreviousMonthNumberValue = getMonthNumberFromNewUnnormalisedMonthNumberValue(monthRequested - 1);
                let newPreviousMonthNameValue: string = getMonthNameFromNormalisedMonthNumber(newPreviousMonthNumberValue);
                let newPreviousMonthYearNumberValue: number = getYearNumberFromNewNormalisedMonthNumberValue(newPreviousMonthNumberValue, newSelectedMonthNumberValue, yearRequested);            

                return {
                    ...state,
                    selectedMonthNumber: newSelectedMonthNumberValue,
                    selectedMonthTitle: newSelectedMonthNameValue,
                    selectedMonthYear: newSelectedMonthYearNumberValue,
                    nextMonthNumber: newNextMonthNumberValue,
                    nextMonthTitle: newNextMonthNameValue,
                    nextMonthYear: newNextMonthYearNumberValue,
                    previousMonthNumber: newPreviousMonthNumberValue,
                    previousMonthTitle: newPreviousMonthNameValue,
                    previousMonthYear: newPreviousMonthYearNumberValue,
                    mhfaStatusList: action.payload?.mhfaStatusList,
                    mhfaStatusListLoading: false,
                    organisationAssesment: action.payload?.organisationAssesment,
                    organisationAssesmentLoading: false,
                    organisationTopCauses: action.payload?.organisationTopCauses,
                    organisationTopCausesLoading: false,
                    organisationTopImpacts: action.payload?.organisationTopImpacts,
                    organisationTopImpactsLoading: false
                };
            }
        }

        default:
            return state
    }
}

export const actions = {
    requestMHFAStatusList: () => ({ type: actionTypes.MHFAStatusListRequested }),
    fulfillMHFAStatusList: (mhfaStatusList: OrganisationAiderStatusModel[]) => ({ type: actionTypes.MHFAStatusListLoaded, payload: { mhfaStatusList } }),
    requestOrganisationAssesment: () => ({ type: actionTypes.OrganisationAssesmentRequested }),
    fulfillOrganisationAssesment: (organisationAssesment: AssesmentModel) => ({ type: actionTypes.OrganisationAssesmentLoaded, payload: { organisationAssesment } }),
    requestOrganisationTopCauses: () => ({ type: actionTypes.OrganisationTopCausesRequested }),
    fulfillOrganisationTopCauses: (organisationTopCauses: CauseAndImpactPercentageModel[]) => ({ type: actionTypes.OrganisationTopCausesLoaded, payload: { organisationTopCauses } }),
    requestOrganisationTopImpacts: () => ({ type: actionTypes.OrganisationTopImpactsRequested }),
    fulfillOrganisationTopImpacts: (organisationTopImpacts: CauseAndImpactPercentageModel[]) => ({ type: actionTypes.OrganisationTopImpactsLoaded, payload: { organisationTopImpacts } }),
    requestOtherMonthOrganisationData: (monthRequested: number, yearRequested: number) => ({
        type: actionTypes.OtherMonthOrganisationDataRequested, payload: { monthRequested, yearRequested } }),
    fulfillOtherMonthOrganisationData: (selectedMonthNumber: number,
        selectedMonthYear: number,
        mhfaStatusList: OrganisationAiderStatusModel[],
        organisationAssesment: AssesmentModel,
        organisationTopCauses: CauseAndImpactPercentageModel[],
        organisationTopImpacts: CauseAndImpactPercentageModel[]) =>
        ({ type: actionTypes.OtherMonthOrganisationDataLoaded, payload: { selectedMonthNumber, selectedMonthYear, mhfaStatusList, organisationAssesment, organisationTopCauses, organisationTopImpacts } })
}

export function* saga() {
    yield takeLatest(actionTypes.MHFAStatusListRequested, function* mhfaStatusListRequested() {
        const mhfaStatusListRequestedResponse: ServiceResponse<OrganisationAiderStatusModel[]> = yield GetOrganisationAiderStatus(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());
        
        if (mhfaStatusListRequestedResponse.isSuccess && mhfaStatusListRequestedResponse.data) {
            let mhfaStatusesState: OrganisationAiderStatusModel[] = mhfaStatusListRequestedResponse.data;
            
            yield put(actions.fulfillMHFAStatusList(mhfaStatusesState));
        }else{
            showToastrErrors(mhfaStatusListRequestedResponse.errors);
        }
    })

    yield takeLatest(actionTypes.OrganisationAssesmentRequested, function* organisationAssesmentRequested() {
        const organisationAssesmentRequestedResponse: ServiceResponse<AssesmentModel> = yield GetOrganisationAssesmentForMonth(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());

        if (organisationAssesmentRequestedResponse.isSuccess && organisationAssesmentRequestedResponse.data) {
            let organisationAssesmentState: AssesmentModel = organisationAssesmentRequestedResponse.data;

            yield put(actions.fulfillOrganisationAssesment(organisationAssesmentState));
        } else {
            showToastrErrors(organisationAssesmentRequestedResponse.errors);
        }
    })

    yield takeLatest(actionTypes.OrganisationTopCausesRequested, function* organisationTopCausesRequested() {
        const organisationTopCausesRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetOrganisationTopCausesForMonth(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());

        if (organisationTopCausesRequestedResponse.isSuccess && organisationTopCausesRequestedResponse.data) {
            let organisationTopCausesState: CauseAndImpactPercentageModel[] = organisationTopCausesRequestedResponse.data;

            yield put(actions.fulfillOrganisationTopCauses(organisationTopCausesState));
        } else {
            showToastrErrors(organisationTopCausesRequestedResponse.errors);
        }
    })

    yield takeLatest(actionTypes.OrganisationTopImpactsRequested, function* organisationTopImpactsRequested() {
        const organisationTopImpactsRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetOrganisationTopImpactsForMonth(getNormalisedMonthNumber(currentDate), currentDate.getUTCFullYear());

        if (organisationTopImpactsRequestedResponse.isSuccess && organisationTopImpactsRequestedResponse.data) {
            let organisationTopImpactsState: CauseAndImpactPercentageModel[] = organisationTopImpactsRequestedResponse.data;

            yield put(actions.fulfillOrganisationTopImpacts(organisationTopImpactsState));
        } else {
            showToastrErrors(organisationTopImpactsRequestedResponse.errors);
        }
    })    

    function* otherMonthOrganisationDataRequested(action: any) {
        let monthRequested: number = action.payload.monthRequested;
        let yearRequested: number = action.payload.yearRequested;

        const mhfaStatusListRequestedResponse: ServiceResponse<OrganisationAiderStatusModel[]> = yield GetOrganisationAiderStatus(monthRequested, yearRequested);
        const organisationAssesmentRequestedResponse: ServiceResponse<AssesmentModel> = yield GetOrganisationAssesmentForMonth(monthRequested, yearRequested);
        const organisationTopCausesRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetOrganisationTopCausesForMonth(monthRequested, yearRequested);
        const organisationTopImpactsRequestedResponse: ServiceResponse<CauseAndImpactPercentageModel[]> = yield GetOrganisationTopImpactsForMonth(monthRequested, yearRequested);

        if ((mhfaStatusListRequestedResponse.isSuccess && mhfaStatusListRequestedResponse.data) &&
            (organisationAssesmentRequestedResponse.isSuccess && organisationAssesmentRequestedResponse.data) &&
            (organisationTopCausesRequestedResponse.isSuccess && organisationTopCausesRequestedResponse.data) &&
            (organisationTopImpactsRequestedResponse.isSuccess && organisationTopImpactsRequestedResponse.data)) {

            let mhfaStatusListRequesteState: OrganisationAiderStatusModel[] = mhfaStatusListRequestedResponse.data;
            let organisationAssesmentRequestedState: AssesmentModel = organisationAssesmentRequestedResponse.data;
            let organisationTopCausesRequestedState: CauseAndImpactPercentageModel[] = organisationTopCausesRequestedResponse.data;
            let organisationTopImpactsState: CauseAndImpactPercentageModel[] = organisationTopImpactsRequestedResponse.data;

            yield put(actions.fulfillOtherMonthOrganisationData(monthRequested,
                yearRequested,
                mhfaStatusListRequesteState,
                organisationAssesmentRequestedState,
                organisationTopCausesRequestedState,
                organisationTopImpactsState));
        } else {
            if (mhfaStatusListRequestedResponse.errors)
                showToastrErrors(mhfaStatusListRequestedResponse.errors);

            if (organisationAssesmentRequestedResponse.errors)
                showToastrErrors(organisationAssesmentRequestedResponse.errors);

            if (organisationTopCausesRequestedResponse.errors)
                showToastrErrors(organisationTopCausesRequestedResponse.errors);

            if (organisationTopImpactsRequestedResponse.errors)
                showToastrErrors(organisationTopImpactsRequestedResponse.errors);
        }
    }
    yield takeLatest(actionTypes.OtherMonthOrganisationDataRequested, otherMonthOrganisationDataRequested);
}