/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useRef, useState } from 'react'
import * as knowledgeHubRedux from '../redux/KnowledgeHubRedux'
import { RootState } from '../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import { Modals } from '../../../@Common/UIComponents/Modals'
import { Formik } from 'formik';
import * as Yup from 'yup'
import { saveCreateCausesAndImpact } from '../redux/KnowledgeHubActions'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import { nameOf } from '../../../@Common/Helpers/Utilities'
import { CauseAndImpactModel, CauseAndImpactNewModel } from '../models/CausesAndImpactModel'
import { FormGroupComponent } from '../../../@Common/FormComponents/FormGroupComponent'

const mapState = (state: RootState) => ({ knowledgehubState: state.knowledgehub })
const connector = connect(mapState, knowledgeHubRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const NewCauseAndImpactModal: FC<PropsFromRedux> = ({ knowledgehubState }) => {
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    let { showNewCausesAndImpactModal } = knowledgehubState;
    const formikRef = useRef(null);

    const onSaveCausesAndImpact = () => {
        (formikRef.current as any).submitForm();
    }

    const onCloseNewCausesAndImpactModal = () => {
        dispatch(knowledgeHubRedux.actions.showNewCausesAndImpactModal(false));
    }

    const causeAndImpactSchema: Yup.SchemaOf<CauseAndImpactModel> = Yup.object().shape({
        id: Yup.string()
            .label("Cause and Impact Id")
            .required("Cause and Impact id is required"),
        name: Yup.string()
            .label("Cause and Impact Name")
            .required('Cause and Impact name is required')
            .min(1)
            .max(100),
        generalDescription: Yup.string()
            .label("General Description")
            .required('General Description is required')
            .min(1)
            .max(1000),
        tenantSpecificDescription: Yup.string()
            .label("Tenant Specific Description")
            .required('General Description is required')
            .min(1)
            .max(1000)
    });

    return (
        <>
            <Modals
                show={showNewCausesAndImpactModal}
                title={"Add Cause And Impact"}
                onClose={onCloseNewCausesAndImpactModal}
                onSave={onSaveCausesAndImpact}
                saving={isSaving}
            >
                <>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            id: '0',
                            name: '',
                            generalDescription: '',
                            tenantSpecificDescription: ''
                        }}
                        validationSchema={causeAndImpactSchema}
                        validateOnChange={true}
                        validateOnBlur={false}
                        onSubmit={(values: CauseAndImpactModel, { setSubmitting, setStatus }) => {
                            setIsSaving(true)
                            setTimeout(async () => {
                                try {
                                    let saveUserResponse = await saveCreateCausesAndImpact(values);

                                    if (!saveUserResponse.isSuccess) {
                                        showToastrErrors(saveUserResponse.errors);
                                    } else {
                                        showToastrSuccess("Problem category has been successfully saved");
                                        dispatch(knowledgeHubRedux.actions.fulfillCausesAndImpactSaved());
                                    }
                                } catch (e) {
                                    showToastrError('An unexpected error has occured while attempting to create a new problem category');
                                }

                                setSubmitting(false);
                                setIsSaving(false);
                            }, 1000);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <>
                                <Form noValidate onSubmit={handleSubmit}>
                                    <FormGroupComponent
                                        className="mb-3"
                                        controlId="formCausesAndImpactName"
                                        label="Title"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        isInvalidError={errors.name}
                                        name={nameOf<CauseAndImpactModel>("name")}
                                        value={values.name}
                                        placeholder={"Enter problem category title here..."}
                                        touched={touched.name}
                                    />                                    
                                </Form>
                            </>
                        )
                        }
                    </Formik>
                </>
            </Modals>
        </>
    );
}

export default connector(NewCauseAndImpactModal)