//import { put, takeLatest, takeMaybe } from "redux-saga/effects";
import { useNavigate } from 'react-router-dom';
import * as Eff from 'redux-saga/effects'
import { Duration } from '../../../@Common/Enums/Duration';

import { WellbeingStatuses } from '../../../@Common/Enums/WellbeingStatuses';
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { RecordsServiceResponse } from "../../../@Common/Interfaces/RecordsServiceResponse";
import { getMHFAConversationById } from "./ConversationAction";

export const actionTypes = {
    MHFAConversationRequested: '[Request MHFA Conversations] Action',
    MHFAConversationByIdRequested: '[Request MHFA Conversations By Id] API',
    MHFAConversationLoaded: '[Loaded MHFA Conversations] API',
    MHFAConversationByIdLoaded: '[Loaded MHFA Conversations By Id] API',
    MHFAConversationByIdUnauthorised: '[Loaded MHFA Conversations By Id Unauthrised] API',
}

export interface IConversationEnumItem {
    conversationEnumItem: number
}

export interface IPreviousConversationState {
    unauthorised: boolean,
    loading: boolean,
    mhfaConversations: {
        conversationGuid: string
        previousConversationId: number
        nextConversationId: number
        beneficiary: string
        conversationDate: Date
        wellbeingScore: WellbeingStatuses
        duration: Duration
        notes: string
        causes: IConversationEnumItem[]
        impacts: IConversationEnumItem[]
    }
}

const initialPreviousConversationState: IPreviousConversationState = {
    unauthorised: false,
    loading: true,
    mhfaConversations: {
        conversationGuid: '00000000-0000-0000-0000-000000000000',
        previousConversationId: -1,
        nextConversationId: -1,
        beneficiary: "",
        conversationDate: new Date(),
        wellbeingScore: WellbeingStatuses.Unset,
        duration: Duration.Unset,
        notes: "",
        causes: [],
        impacts: [],
    }
}

const takeLatest: any = Eff.takeLatest;    // <-- new

export const reducer = (state: IPreviousConversationState = initialPreviousConversationState, action: ActionWithPayload<IPreviousConversationState>): IPreviousConversationState => {
    switch (action.type) {
        case actionTypes.MHFAConversationRequested: {
            return { ...state };
        }

        case actionTypes.MHFAConversationLoaded: {
            return {
                ...action.payload!,
                loading: false
            };
        }

        case actionTypes.MHFAConversationByIdRequested: {
            return { ...state };
        }

        case actionTypes.MHFAConversationByIdUnauthorised: {
            return {
                ...state,
                loading: false,
                unauthorised: true
            };
        }

        case actionTypes.MHFAConversationByIdLoaded: {
            return {
                ...state,
                //mhfaConversations: action.payload?.mhfaConversations!
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestMHFAReadConversationById: (id) => ({ type: actionTypes.MHFAConversationByIdRequested, payload: { id } }),
    fulfillMHFAReadConversationUnauthorised: () => ({ type: actionTypes.MHFAConversationByIdUnauthorised }),
    fulfillMHFAReadConversation: (mhfaConversations: IPreviousConversationState) => ({ type: actionTypes.MHFAConversationLoaded, payload: { mhfaConversations } })
}

export function* saga() {
    yield takeLatest(actionTypes.MHFAConversationByIdRequested, function* mhfaConversationByIdRequested(request) {
        var cleanedId = request.payload.id !== undefined ? request.payload.id : "";
        var parsedId = parseInt(cleanedId);
        const mhfaConversationRequestedResponse: RecordsServiceResponse<IPreviousConversationState> = yield getMHFAConversationById(parsedId);
        if (mhfaConversationRequestedResponse.isSuccess) {
            yield Eff.put(actions.fulfillMHFAReadConversation(mhfaConversationRequestedResponse.data));
        } else if (mhfaConversationRequestedResponse.status === 401) {
            yield Eff.put(actions.fulfillMHFAReadConversationUnauthorised());
        } else {
            showToastrErrors(mhfaConversationRequestedResponse.errors);
        }
    })
}