export enum PeoplePermissions {
    View = "Permissions.People.View",
    Create = "Permissions.People.Create",
    Edit = "Permissions.People.Edit",
    ForceEdit = "Permissions.People.ForceEdit",
    Delete = "Permissions.People.Delete",
    ManagePermissions = "Permissions.People.ManagePermissions"
}

export enum KnowledgeHubPermissions {
    ViewCategory = "Permissions.KnowledgeHub.ViewCategory",
    CreateCategory = "Permissions.KnowledgeHub.CreateCategory",
    EditCategory = "Permissions.KnowledgeHub.EditCategory",
    DeleteCategory = "Permissions.KnowledgeHub.DeleteCategory",
    UpdateGeneralDescription = "Permissions.Knowledgehub.UpdateGeneralDescription",
    UpdateTenantSpecificDescription = "Permissions.Knowledgehub.UpdateTenantSpecificDescription"
}