import { Form, InputGroup } from "react-bootstrap";

interface FormGroupComponentProps {
    label: string;
    controlId: string;
    className?: string;
    name?: string;
    handleChange?: (e: any) => void;
    handleBlur?: (e: any) => void;
    value?: any;
    isInvalidError?: any | undefined;
    placeholder?: string;
    autocomplete?: boolean;
    touched?: any | undefined;
    type?: string;
    disabled?: boolean;
    inputGroup?: FormGroupComponentInputGroupProps;
    required?: boolean;
}

interface FormGroupComponentInputGroupProps {
    size?: "sm" | "lg"
    text?: string
}

const FormGroupComponent: React.FC<FormGroupComponentProps> = (props) => {
    const { className, controlId, label, name, value, isInvalidError, placeholder, autocomplete=false, touched, type, disabled, inputGroup, required=true, handleChange, handleBlur } = props;

    const displayControl = () => {
        return(
        <>
            <Form.Control
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                isInvalid={touched && !!isInvalidError}
                placeholder={placeholder}
                autoComplete={!autocomplete ? "off" : undefined}
                type={type}
                disabled={disabled}
            />
            <Form.Control.Feedback type="invalid">
                {isInvalidError}
            </Form.Control.Feedback>
        </>);
    }

    return (
        <>
            <Form.Group className={className} controlId={controlId}>
                <Form.Label>{label} {required ? <span className="text-danger">*</span> : <></>}</Form.Label>
                {inputGroup &&
                    <InputGroup size={inputGroup.size} className="mb-3">
                        <InputGroup.Text>{inputGroup.text}</InputGroup.Text>
                        {displayControl()}
                    </InputGroup>
                }
                {!inputGroup &&
                    displayControl()
                }
            </Form.Group>
        </>
    );
}

export { FormGroupComponent }
