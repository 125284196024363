import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { UserModel } from '../../../../@Common/Models/UserModel';
import { ChangePasswordModel } from '../models/ChangePasswordModel';
import { ForceChangePasswordModel } from '../models/ForceChangePasswordModel';
import { UpdateUserModel } from '../models/UpdateUserModel';
import { UserProfileSecurity } from '../models/UserProfileSecurity';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/profile";

export const GET_USER_URL = `${API_URL}/GetUser`;
export const UPDATE_USER_URL = `${API_URL}/UpdateUser`;
export const DELETE_USER_URL = `${API_URL}/DeleteUser`;
export const CHANGE_USER_PASSWORD_URL = `${API_URL}/ChangeUserPassword`;
export const FORCE_CHANGE_USER_PASSWORD_URL = `${API_URL}/ForceChangeUserPassword`;
export const GET_USER_PROFILE_SECURITY_URL = `${API_URL}/GetUserProfileSecurity`;

export const getUserProfile = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<UserModel>>(GET_USER_URL, { userId });
  return response;
}

export const saveUpdateUser = async (user: UpdateUserModel) => {
  let response = await postToServerAPI<ServiceResponse>(UPDATE_USER_URL, user);
  return response;
}

export const deleteUser = async (userId: string) => {
  let response = await postToServerAPI<ServiceResponse>(DELETE_USER_URL, { userId });
  return response;
}

export const changeUserPassword = async (model: ChangePasswordModel) => {
  let response = await postToServerAPI<ServiceResponse>(CHANGE_USER_PASSWORD_URL, model);
  return response;
}

export const forceChangeUserPassword = async (model: ForceChangePasswordModel) => {
    let response = await postToServerAPI<ServiceResponse>(FORCE_CHANGE_USER_PASSWORD_URL, model);
    return response;
}

export const getUserProfileSecurity = async (userId: string) => {
  let response = await getFromServerAPI<ServiceResponse<UserProfileSecurity>>(GET_USER_PROFILE_SECURITY_URL, { userId });
  return response;
}