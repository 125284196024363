/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ConfigurationAreaModel } from '../../../../app/configurationArea/models/ConfigurationAreaModel'
import { RootState } from '../../../../setup'
import * as configurationKeys from '../../../../app/configurationArea/helper/ConfigurationKeys';
import { KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultPageButtons } from '../header/page-buttons/DefaultPageButtons'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import { Icons } from '../../../../@Common/Helpers/Icons'
import * as configurationAreaRedux from '../../../../app/configurationArea/redux/ConfigurationAreaRedux';
import { getEapDetails } from '../../../../app/configurationArea/helper/ConfigurationEAPDetails'

const IconToolbar: FC = () => {
  const { classes } = useLayout()

  // Configuration
  const configurationArea: ConfigurationAreaModel[] = useSelector<RootState>(({ configurationArea }) => configurationArea.configurationArea, shallowEqual) as ConfigurationAreaModel[]
  const eapNameValue = getEapDetails(configurationArea, configurationKeys.eapName);
  const eapPhoneValue = getEapDetails(configurationArea, configurationKeys.eapPhone);
  const eapEmailValue = getEapDetails(configurationArea, configurationKeys.eapEmail);

  let dispatch = useDispatch();

  useEffect(() => {
    // Request Configuration Area
    dispatch(configurationAreaRedux.actions.requestConfigurationArea());
  }, [])

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <DefaultTitle />

        {/* begin::Actions */}
        <div className='d-flex flex-push-right p-2 flex-no-wrap'>
          <DefaultPageButtons />
        </div>

        <div className='d-flex align-items-center p-2 eap-toolbar'>
          {
            configurationArea &&
            <>
              <i className={`${Icons.HandShake} color-01`} />
              <div>
                <span><b>{eapNameValue}</b></span>
                <span> - {eapPhoneValue}</span>
                <br />
                <span>{eapEmailValue}</span>
              </div>

            </>
          }
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { IconToolbar }
