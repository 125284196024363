import { postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { MHFAConversation } from '../../mhfaDashboard/models/ConversationReadModel';

const CONVERSATION_API_URL = process.env.REACT_APP_API_URL + "/api/v1/Conversation";

const GET_ALL_ORGANISATION_CONVERSATIONS = CONVERSATION_API_URL + "/GetAllOrganisationConversations";

export const GetAllOrganisationConversations = async (filter: GridFilterDto) => {
    let response = await postToServerAPI<PaginatedServiceResponse<MHFAConversation>>(GET_ALL_ORGANISATION_CONVERSATIONS, filter);
    return response;
}