/* eslint-disable react/jsx-no-target-blank */

import React, { FC, useEffect } from 'react'
import * as KnowledgeHubRedux from '../redux/KnowledgeHubRedux'
import { RootState } from '../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { CardGroup } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../_metronic/layout/core'
import { generatePath, useNavigate } from 'react-router-dom'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { Buttons } from '../../../@Common/UIComponents/Buttons'
import { Icons } from '../../../@Common/Helpers/Icons'
import NewCauseAndImpactModal from './NewCauseAndImpactModal'
import { KnowledgeHubPermissions } from '../../../@Common/Helpers/Permissions'
import { AuthorizeAccess } from '../../../@Common/UIComponents/AuthorizeAccess'
import { CauseAndImpactShortModel } from '../models/CausesAndImpactModel'

const mapState = (state: RootState) => ({
    categories: state.knowledgehub,
    permissions: state.auth.userPermissions
})
const connector = connect(mapState, KnowledgeHubRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const KnowledgeHub: FC<PropsFromRedux> = ({ categories, permissions }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { causesAndImpacts, loading } = categories;

    useEffect(() => {
        async function loadCausesAndImpacts() {
            dispatch(KnowledgeHubRedux.actions.requestCausesAndImpacts())
        }

        loadCausesAndImpacts();
    }, [])

    const onCausesAndImpactClick = (causeAndImpactTitle: string) => {
        let path = generatePath("/signpostingresources/:causeAndImpactTitle", { causeAndImpactTitle });
        return navigate(path);
    }

    const onAddCausesAndImpactClick = () => {
        dispatch(KnowledgeHubRedux.actions.showNewCausesAndImpactModal(true));
    }

    const displayCausesAndImpacts = (causesAndImpacts: CauseAndImpactShortModel[]) => {
        return (
            <>
                <Cards key={"causesAndImpacts"}
                    border='custom'
                    className='ic-widget-cont'
                    header={
                        <>
                            <div className="d-flex flex-row justify-content-between flex-wrap">
                                <div className="p-2">
                                    <h1><i className="far fa-list-alt color-03"></i>Cause and Impact</h1>
                                </div>
                                <div className="p-2">
                                    <a href="https://hubofhope.co.uk/" target="_blank" className='medium-button medium-button--color-01'>Search the <img alt='Logo' src='/media/logos/hub-of-hope-logo.png' /></a>
                                </div>
                            </div>
                        </>
                    }
                >
                    <CardGroup>
                        {causesAndImpacts.map((causeAndImpact, index) =>
                            <React.Fragment key={index}>
                                <div className={`col-3 ic-small-widget-wrapper ic-small-widget-wrapper--4-col-desk`}>
                                    <Cards
                                        className='ic-widget-button'
                                        border='custom'
                                        title={
                                            <>
                                                <h1>{causeAndImpact.name}</h1>
                                            </>
                                        }
                                        key={"causeAndImpact-" + index}
                                        footer={
                                            <>
                                                <div className='row'>
                                                    <Buttons size="sm" variant="primary" onClick={() => onCausesAndImpactClick(causeAndImpact.name)} icon="fas fa-book-medical">View</Buttons>
                                                </div>
                                            </>
                                        }>
                                    </Cards>
                                </div>
                            </React.Fragment>
                        )}
                    </CardGroup>
                </Cards>
            </>
        );
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle>Signposting Resources</PageTitle>
                <PageButtons>
                    <AuthorizeAccess permissionKey={KnowledgeHubPermissions.CreateCategory}>
                        <Buttons variant="primary" className="m-1" onClick={onAddCausesAndImpactClick} icon={Icons.Plus}>Add Cause And Impact</Buttons>
                    </AuthorizeAccess>
                </PageButtons>
                <Loader loading={loading}>
                    {causesAndImpacts && <> {displayCausesAndImpacts(causesAndImpacts)} </>}
                </Loader>
                <AuthorizeAccess permissionKey={KnowledgeHubPermissions.CreateCategory}>
                    <NewCauseAndImpactModal></NewCauseAndImpactModal>
                </AuthorizeAccess>
            </ErrorBoundary>
        </>
    );
}

export default connector(KnowledgeHub)