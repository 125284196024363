export enum ImpactStatuses {
    Unset               = -1,
    LowMood             = 0,
    FlatMood            = 1,
    EatingDisorder      = 2,
    StressPersonal      = 3,
    StressWork          = 4,
    SubstanceMisuse     = 5,
    Loneliness          = 6,
    Isolation           = 7,
    SuicidalThoughts    = 8,
    Anxiety             = 9,
    SelfHarm            = 10,
    ChangesInThinking   = 11,
}