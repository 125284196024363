import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { ConversationStatisticsWriteModel } from '../../conversationStatistics/models/ConversationStatisticsWriteModel';
import { CauseAndImpact } from '../models/CauseAndImpact';
import { ConversationReadDto } from '../models/ConversationReadDto';
import { RecommendationsReadModel } from '../models/RecommendationsReadModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/recommendations";

//Recommendations Root actions
export const GET_RECOMMENDATIONS_URL = `${API_URL}/GetAnonymousRecommendationByConversationGuid`;

// Get Conversation
export const GET_CONVERSATIONCAUSESANDIMPACTSBYGUID_URL = `${API_URL}/GetConversationCausesAndImpactsByGuid`;

// Get CauseAndImpacts
export const GET_CAUSEANDIMPACTS_URL = `${API_URL}/GetCausesAndImpacts`;

// Microservices Document Management API
export const GENERATE_PDF_ENDPOINT_URL = `${API_URL}/GeneratePDFWithDatapoints`;

export const getRecommendations = async (conversationGuid) => {
    let response = await getFromServerAPI<ServiceResponse<RecommendationsReadModel[]>>(GET_RECOMMENDATIONS_URL, { conversationGuid });
    return response;
}

export const getConversationCausesAndImpactsByGuid = async (conversationGuid: string) => {
    let response = await getFromServerAPI<ServiceResponse<ConversationReadDto>>(GET_CONVERSATIONCAUSESANDIMPACTSBYGUID_URL, { conversationGuid });
    return response;
}

export const getCausesAndImpacts = async () => {
    let response = await getFromServerAPI<ServiceResponse<CauseAndImpact[]>>(GET_CAUSEANDIMPACTS_URL);
    return response;
}

export const generatePdf = async (html: string, datapoint: any): Promise<ServiceResponse<string>> => {
    const requestBody = {
        html,
        datapoint
    };
    try {
        let response = await postToServerAPI<ServiceResponse<string>>(GENERATE_PDF_ENDPOINT_URL, requestBody);
        return response;
    } catch (error) {
        console.error('Error while generating PDF:', error);
        throw error;
    }
};