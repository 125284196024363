import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { CauseAndImpactShortModel, CauseAndImpactModel, CauseAndImpactFullModel } from '../models/CausesAndImpactModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/knowledgehub";

//Knowledgehub Root actions
export const GET_CAUSES_AND_IMPACTS_URL = `${API_URL}/GetCausesAndImpacts`;
export const GET_CAUSES_AND_IMPACT_DETAILS_URL = `${API_URL}/GetCausesAndImpactDetails`;
export const CREATE_CAUSES_AND_IMPACT_URL = `${API_URL}/CreateCausesAndImpact`;
export const EDIT_CAUSES_AND_IMPACT_URL = `${API_URL}/EditCausesAndImpact`;
export const DELETE_CAUSES_AND_IMPACT_URL = `${API_URL}/DeleteCausesAndImpact`;

export const getCausesAndImpacts = async () => {
    let response = await getFromServerAPI<ServiceResponse<CauseAndImpactShortModel[]>>(GET_CAUSES_AND_IMPACTS_URL, {});
    return response;
}

export const getCauseAndImpactDetails = async (causeAndImpactTitle: string) => {
    let response = await getFromServerAPI<ServiceResponse<CauseAndImpactFullModel>>(GET_CAUSES_AND_IMPACT_DETAILS_URL, { causeAndImpactTitle });
    return response;    
}

export const saveCreateCausesAndImpact = async (newCausesAndImpact: CauseAndImpactModel) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(CREATE_CAUSES_AND_IMPACT_URL, newCausesAndImpact);
    return response;
}

export const saveEditCausesAndImpact = async (editedCausesAndImpact: CauseAndImpactShortModel) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(EDIT_CAUSES_AND_IMPACT_URL, editedCausesAndImpact);
    return response;
}

export const saveDeleteCausesAndImpact = async (causeAndImpactToDeleteId: string) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(DELETE_CAUSES_AND_IMPACT_URL, causeAndImpactToDeleteId);
    return response;
}

//Knowledgehub Cause And Impact actions
export const EDIT_CAUSES_AND_IMPACT_GENERAL_DESCRIPTION_URL = `${API_URL}/UpdateCausesAndImpactGeneralDescription`;
export const EDIT_CAUSES_AND_IMPACT_TENANT_DESCRIPTION_URL = `${API_URL}/UpdateCausesAndImpactTenantDescription`;
export const ADD_RESOURCE_TO_CAUSES_AND_IMPACT_URL = `${API_URL}/AddResourceToCausesAndImpact`;
export const REMOVE_RESOURCE_FROM_CAUSES_AND_IMPACT_URL = `${API_URL}/RemoveResourceFromCausesAndImpact`;

export const saveEditCausesAndImpactGeneralDescription = async (causeAndImpactId: number, updatedDescription: string) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(EDIT_CAUSES_AND_IMPACT_GENERAL_DESCRIPTION_URL, { causeAndImpactId, updatedDescription });
    return response;
}

export const saveEditCausesAndImpactTenantSpecificDescription = async (causeAndImpactId: number, updatedDescription: string) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(EDIT_CAUSES_AND_IMPACT_TENANT_DESCRIPTION_URL, { causeAndImpactId, updatedDescription });
    return response;
}

export const saveAddResourceToCausesAndImpact = async (causeAndImpactToDeleteId: string) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(ADD_RESOURCE_TO_CAUSES_AND_IMPACT_URL, causeAndImpactToDeleteId);
    return response;
}

export const saveDeleteResourceFromCausesAndImpact = async (causeAndImpactResourceToDeleteId: string) => {
    let response = await postToServerAPI<ServiceResponse<boolean>>(REMOVE_RESOURCE_FROM_CAUSES_AND_IMPACT_URL, causeAndImpactResourceToDeleteId);
    return response;
}