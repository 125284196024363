import { useState, useEffect, useRef, FC } from 'react';
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { Mood, NikoModel } from './models/NikoModel'
import { Modals } from '../../@Common/UIComponents/Modals'
import { postTodayNiko } from './redux/NikoActions';
import { ServiceResponse } from '../../@Common/Interfaces/ServiceResponse';
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../@Common/Helpers/Toastr';
import * as nikoRedux from './redux/NikoRedux';
import { FormGroupSelectMood } from '../../@Common/FormComponents/FormGroupSelectMood';
import { nameOf } from '../../@Common/Helpers/Utilities';
import { doesUserHaveRole } from '../../@Common/Helpers/RoleHelper';
import { BaseRippleRoles } from '../../@Common/Enums/BaseRippleRoles'
import { RootState } from '../../setup';

const mapState = (state: RootState) => ({ niko: state.niko, user: state.auth.user, })
const connector = connect(mapState, nikoRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const NikoModal: FC<PropsFromRedux>  = ({niko, user}) => {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);

    
    useEffect(() => {
        async function loadNiko() {
            dispatch(nikoRedux.actions.requestNiko());
        }

        if (!doesUserHaveRole(user, BaseRippleRoles.MHFA)) {
            niko.loading = false;
            niko.submitted = true;
        } else {
            loadNiko();
        }
    }, []);
    

    const nikoSchema: Yup.SchemaOf<NikoModel> =  Yup.object({
        NikoNiko: Yup.object().shape({
            NikoNiko: Yup.number()
                .label("")
                .required("Mood is required")
                .min(0)
                .max(4),
        }) as Yup.SchemaOf<Mood>,
    });

    const formikRef = useRef(null);
    const getFormikForm = () => (formikRef.current as any);
    
    const onSaveNikoNiko = () => {
        (formikRef.current as any).submitForm();
    }
    
    const displayNikoNiko = (props: FormikProps<NikoModel>) => {
        return <>
            <FormGroupSelectMood
                className="moods-modal-content"
                controlId="formNikoNikoMood"
                label=""
                name={`${nameOf<NikoModel>("NikoNiko")}.${nameOf<NikoModel>("NikoNiko")}`}
                value={props.values.NikoNiko.NikoNiko} 
                onChange={props.setFieldValue}
            />
        </>
    }

    return (
        <Modals
            show={!niko.submitted}
            title={"Hi " + user?.name  + ", how are you today?"}
            onClose={()=>null}
            onSave={onSaveNikoNiko}
            saving={isSaving}
            showClose={false}
            showSaving={true}
            saveButtonText={"Submit"}
        >
            <>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        NikoNiko: {
                            NikoNiko: -1
                        }
                    }}
                    validationSchema={nikoSchema}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={(values: NikoModel, { setSubmitting }) => {
                        setIsSaving(true)
                        setTimeout(async () => {
                            try {
                                let saveNikoResponse: ServiceResponse;

                                if (values.NikoNiko.NikoNiko > -1)
                                {                                        
                                    saveNikoResponse = await postTodayNiko(values.NikoNiko.NikoNiko);

                                    if (!saveNikoResponse.isSuccess) {
                                        showToastrErrors(saveNikoResponse.errors);
                                    } else {
                                        showToastrSuccess("Mood has been successfully saved");
                                        dispatch(nikoRedux.actions.submitNiko());
                                    }
                                }
                                else {
                                    showToastrError("Attempted to save your mood has failed. Please try again later.");
                                }                                    
                            } catch (e) {
                                showToastrError('An unexpected error has occured');
                            }

                            setSubmitting(false);
                            setIsSaving(false);
                        }, 1000);
                    }}>
                    {(props) => (
                        <>
                            {displayNikoNiko(props)}
                        </>
                    )
                    }
                </Formik>
            </>
          </Modals>   
    );
}

export default connector(NikoModal)