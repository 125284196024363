export enum CauseStatuses {
    Unset                       = -1,
    Bereavement                 = 0,
    LossSignificantChange       = 1,
    AgeingRelatives             = 2,
    CaringResponsibilities      = 3,
    Housing                     = 4,
    Workload                    = 5,
    Money                       = 6,
    ManagerRelationships        = 7,
    RomanticRelationships       = 8,
    ColleagueRelationships      = 9,
    Childcare                   = 10,
    Sleep                       = 11,
    Menopause                   = 12,
    FamilyFriendMentalHealth    = 13,
    PersonalPhysicalHealth      = 14,
    DontKnowTheCause            = 15,
    Other                       = 16
}