/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as auth from '../redux/AuthRedux'
import { login } from '../redux/AuthActions'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required'),
  password: Yup.string()
    .required('Password is required')
})

const initialValues = {
  username: (process.env.NODE_ENV !== 'production') ? '' : '',
  password: (process.env.NODE_ENV !== 'production') ? '' : ''
}

export function Login() {
    const [loading, setLoading] = useState(false)
    const [showForgetPasswordInstructions, setShowForgetPasswordInstructions] = useState(false)

    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
          setLoading(true)
          setTimeout(async () => {
            try {
              let loginResponse = await login(values.username, values.password);

              if (!loginResponse.isSuccess) {
                  setStatus(loginResponse.errorsDisplay);

                  setSubmitting(false);
                  setLoading(false);
              } else {
                let data = loginResponse.data;
                dispatch(auth.actions.login(data?.accessToken!, data?.refreshToken!, data?.userId!));
              }
            } catch (e)
            {
                setSubmitting(false);
                setLoading(false);
            }
          }, 3000)
        },
    })

    return (
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'>
            {/* begin::Heading */}
            <div className='text-center mb-10'>
            <h1>Welcome to <span>Plato</span></h1>
            <h2>Sign In</h2>

            </div>
            {/* begin::Heading */}

            {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
            ) : (
            <>
            </>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
            <input
                placeholder='Username'
                {...formik.getFieldProps('username')}
                className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.username && formik.errors.username },
                {
                    'is-valid': formik.touched.username && !formik.errors.username,
                }
                )}
                name='username'
                autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
                <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.username}</span>
                </div>
            )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>        
            <input
                placeholder='Password'
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                    'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                    'is-valid': formik.touched.password && !formik.errors.password,
                }
                )}
            />
            {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                </div>
                </div>
                    )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
            <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}>
                {!loading && <span className='indicator-label'>Sign In</span>}
                    {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>

                <div>
                    <div>
                        <button
                            onClick={() => setShowForgetPasswordInstructions(true)}
                            className='btn nobg-button w-100'
                            type='button'>
                            Forgot Password?
                        </button>
                    </div>
                    {showForgetPasswordInstructions && (
                    <div className='fv-plugins-message-container'>
                        <span>Please contact your company system administrator to reset your password</span>
                    </div>
                    )}                
                </div>
            </div>
            {/* end::Action */}
        </form>
  )
}