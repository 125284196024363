import { createRef, useEffect, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { string } from "yup";
import { Icons } from "../Helpers/Icons";
import { Buttons } from "./Buttons";
import { ButtonSpinner } from "./ButtonSpinner";

interface ModalProps {
    show: boolean;
    title: string;
    onSave?: () => void;
    onClose: () => void;
    saving?: boolean;
    showSaving?: boolean;
    showClose?: boolean;
    saveButtonText?: string;
}

const Modals: React.FC<ModalProps> = (props) => {
    const { show, title, onSave, onClose, saving, children, showSaving, showClose = true, saveButtonText = "Save Changes" } = props;
    let parentWrapper = document.getElementById("kt_wrapper");

    Modals.defaultProps = {
        showSaving: true
    };

    return (
        <>
            <Modal
                show={show}
                size="lg"
                onHide={onClose}
                container={parentWrapper}
                backdrop={"static"}
            >
                <Modal.Header closeButton={showClose}>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        {children}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {showSaving &&
                        <ButtonSpinner
                            onClick={onSave}
                            text={saveButtonText}
                            variant="primary"
                            saving={saving!}
                        />
                    }
                    {showClose ? <Buttons variant="danger" onClick={onClose} disabled={saving} icon={Icons.Times}>Close</Buttons> : <></>}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { Modals }
