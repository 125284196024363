import { IOrganisationDashboardState } from "../redux/OrganisationDashboardRedux";
import { connect } from "react-redux";
import { getAbsoluteMoodUrl } from "../../../@Common/Helpers/MoodHelper";

interface IOrganisationAssesmentSection {
    organisationDashboardState: IOrganisationDashboardState | undefined ;
}

const OrganisationAssesmentSection: React.FC<IOrganisationAssesmentSection> = ({ organisationDashboardState } ) => {
    return (
        (organisationDashboardState === undefined || organisationDashboardState?.organisationAssesment === undefined) ?
            <>No first aider conversations logged for the selected month</> :
            <ul className='ic-widget-cont__stats'>
                <li className='brand-color-4'>
                    <i className="far fa-comment-alt color-01"></i>
                    <h3 className='color-02'>Total number of Conversations</h3>
                    <p>Logged across all MHFAs</p>
                    <h2 className='color-02'>{organisationDashboardState.organisationAssesment.totalNumberOfConversationsLogged > 0 ? organisationDashboardState.organisationAssesment.totalNumberOfConversationsLogged : 0}</h2>
                </li>
                <li className='brand-color-4'>
                    <i className="far fa-clock color-01"></i>
                    <h3 className='color-02'>Total Time</h3>
                    <p>Logged across all MHFAs</p>
                    <h2 className='color-02'>{organisationDashboardState.organisationAssesment.totalConversationTimeLoggedInHours > 0 ? organisationDashboardState.organisationAssesment.totalConversationTimeLoggedInHours : 0} hrs</h2>
                </li>
                <li>
                    <img src={getAbsoluteMoodUrl(organisationDashboardState.organisationAssesment.averageBeneficiaryMoodEnum)} />
                    <h3 className='color-02'>Average Employee Mood</h3>
                    <h2 className='color-02'>{organisationDashboardState.organisationAssesment.averageBeneficiaryMood != undefined ? organisationDashboardState.organisationAssesment.averageBeneficiaryMood : "N/A"}</h2>
                </li>
                <li>
                    <img src={getAbsoluteMoodUrl(organisationDashboardState.organisationAssesment.averageMHFAMoodEnum)} />
                    <h3 className='color-02'>Average MHFA Mood</h3>
                    <h2 className='color-02'>{organisationDashboardState.organisationAssesment.averageMHFAMood != undefined ? organisationDashboardState.organisationAssesment.averageMHFAMood : "N/A"}</h2>
                </li>
            </ul>
    );
}

// states to be retrieved from the redux store
const mapStateToProps = (state) => {
    return {
    };
  };

export default connect(mapStateToProps)(OrganisationAssesmentSection);
