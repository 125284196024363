/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { BeneficiaryModel } from '../models/BeneficiaryModel'
import { archiveBeneficiary, deleteBeneficiary } from '../redux/BeneficiaryActions'
import { BeneficiaryWriteModel } from '../models/BeneficiaryWriteModel'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import { Modals } from '../../../@Common/UIComponents/Modals'
import * as beneficiaryListRedux from '../redux/BeneficiaryListRedux'
import BeneficiaryActionEnum from '../helper/BeneficiaryActionEnum'
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse'
import { Icons } from '../../../@Common/Helpers/Icons'


interface BeneficiaryProps {
    beneficiary: BeneficiaryWriteModel;
    isShowModal: boolean;
    setIsShowModal: (isShowModal: boolean) => {};
    beneficiaryAction: BeneficiaryActionEnum;
}

const BeneficiaryActionModal: FC<BeneficiaryProps> = ({ beneficiary, isShowModal, setIsShowModal, beneficiaryAction }) => {

    const [beneficiaryData, setBeneficiaryData] = useState<BeneficiaryModel>();
    const [isSaving, setIsSaving] = useState(false);
    const [actionText, setActionText] = useState<string>();
    const [actionIcon, setActionIcon] = useState<string>();
    const formikRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (beneficiaryAction == BeneficiaryActionEnum.Archive) {
            setActionText("Archive");
            setActionIcon(Icons.Archive);
        }
        else if (beneficiaryAction == BeneficiaryActionEnum.Delete) {
            setActionText("Delete");
            setActionIcon(Icons.Trash);
        }
    })

    useEffect(() => {
        setBeneficiaryData(beneficiary);
    }, [beneficiary]);


    // Handle Save from Modal Footer
    const onSaveBeneficiary = async () => {

        // Start Saving
        setIsSaving(true);

        try {

            // Service Response - Init
            let response: ServiceResponse<{}>

            // Check Operation
            if (beneficiaryAction == BeneficiaryActionEnum.Archive) {
                response = await archiveBeneficiary(beneficiary);
            }
            else if (beneficiaryAction == BeneficiaryActionEnum.Delete) {
                response = await deleteBeneficiary(beneficiary);
            }

            // Check Response
            if (response!.isSuccess) {
                showToastrSuccess(`Beneficiary ${actionText}d Successfully`);
            }
            else {
                showToastrErrors(response!.errors);
            }

            // Request Beneficiary List
            dispatch(beneficiaryListRedux.actions.requestBeneficiaryList());

            // Trigger Hide Modal in Beneficiary List
            setIsShowModal(false);
        }
        catch (e) {
            showToastrError('An unexpected error has occured');
        }

        // Stop
        setIsSaving(false);
    }

    // Handle Modal Close -> Request Beneficiary List
    const onCloseBeneficiaryFormModal = () => {

        // Trigger Hide Modal in Beneficiary List
        setIsShowModal(false);
    }

    return (
        <>
            <Modals
                show={isShowModal}
                title={"Employee"}
                onSave={onSaveBeneficiary}
                saving={isSaving}
                onClose={onCloseBeneficiaryFormModal}
                saveButtonText={actionText}
            >
                {beneficiaryData &&
                    <>
                        <Cards className='card-xl-stretch mb-xl-8 ic-widget-small card'>
                            <i className={actionIcon + " h2 color-primary"} style={{ fontWeight: '700' }}></i>
                            <h1><span>Employee </span>{actionText}</h1>
                            <h5> Are you sure you want to {actionText} employee:</h5>
                            <br />
                            <ul className="ic-table bg-white">
                                <li className="ic-table__row">
                                    <div className='ic-table__row__field ic-table__row__field--medium'>
                                        <h1><span>{beneficiaryData!.name!} </span>{beneficiaryData!.surname!}</h1>
                                        <div>
                                            {beneficiaryData!.idNumber!}
                                        </div>
                                        <div>
                                            {beneficiaryData!.email!}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </Cards>
                    </>
                }
            </Modals>
        </>
    );
}

export default BeneficiaryActionModal;
