const normalisedMonthNumberNameMap = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
};

export const getNormalisedMonthNumber = (monthToCheck: Date): number => {
    return monthToCheck.getMonth() + 1;
}

export const getMonthNameFromNormalisedMonthNumber = (normalisedMonthNumber: number): string => {
    return normalisedMonthNumberNameMap[normalisedMonthNumber];
}

export const getYearNumberFromNewNormalisedMonthNumberValue = (newNextOrPreviousMonthNumberValue: number,
    newSelectedMonthNumberValue: number, //Always refers to current month
    currentYearNumberValue: number): number => {
    if (newNextOrPreviousMonthNumberValue == 1 && newSelectedMonthNumberValue == 12) {
        return currentYearNumberValue + 1;
    } else if (newNextOrPreviousMonthNumberValue == 12 && newSelectedMonthNumberValue == 1) {
        return currentYearNumberValue - 1;
    } else {
        return currentYearNumberValue;
    }
}

export const getMonthNumberFromNewUnnormalisedMonthNumberValue = (newUnnormalisedMonthNumberValue: number): number => {
    if (newUnnormalisedMonthNumberValue == 13) {
        return 1;
    }

    if (newUnnormalisedMonthNumberValue == 0) {
        return 12;
    }

    return newUnnormalisedMonthNumberValue;
}