import { persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import { put, takeLatest, select } from 'redux-saga/effects'
import { ActionWithPayload } from '../../../../../@Common/Interfaces/ActionWithPayload'
import { MenuItemModel } from '../models/MenuItemModel'
import { getMenuItems } from './MenuActions'

export const actionTypes = {
    MenuItemsRequested: '[Request Menu Items] Action',
    MenuItemsLoaded: '[Loaded Menu Items] API',
    MenuItemsClear: '[Clear Menu Items] Action'
}

export interface IMenuItemState {
    menuItems?: MenuItemModel[]
}

const initialMenuItemState: IMenuItemState = {
    menuItems: undefined
}

export const reducer = persistReducer(
    { storage: storageSession, key: 'icon-core-menu-item', whitelist: ['menuItems'] },
    (state: IMenuItemState = initialMenuItemState, action: ActionWithPayload<IMenuItemState>) => {
        switch (action.type) {
            case actionTypes.MenuItemsLoaded: {
                return {
                    ...state,
                    menuItems: action.payload?.menuItems
                };
            }
            case actionTypes.MenuItemsClear: {
                return {
                    ...state,
                    menuItems: undefined
                };
            }

            default:
                return state
        }
    }
)

export const actions = {
    requestMenuItems: () => ({
        type: actionTypes.MenuItemsRequested,
    }),
    fulfillMenuItems: (menuItems: MenuItemModel[]) => ({ type: actionTypes.MenuItemsLoaded, payload: { menuItems } }),
    clearMenuItems: () => ({
        type: actionTypes.MenuItemsClear,
    }),
}

export function* saga() {
    yield takeLatest(actionTypes.MenuItemsRequested, function* menuItemsRequested() {
        const menuItems = yield getMenuItems();
        yield put(actions.fulfillMenuItems(menuItems));
    })
}