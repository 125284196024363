import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
//import { useQuill } from 'react-quilljs';
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { RootState } from '../../../setup'
import { Cards } from "../../../@Common/UIComponents/Cards";
import { ErrorBoundary } from "../../../@Common/UIComponents/ErrorBoundary";
import { PageButtons, PageTitle } from "../../../_metronic/layout/core";
import { Button, ButtonGroup } from "react-bootstrap";
import { saveConfigurationArea } from '../redux/ConfigurationAreaActions'
import * as configurationAreaRedux from '../redux/ConfigurationAreaRedux'
import { ConfigurationAreaModel } from '../models/ConfigurationAreaModel';
import { Loader } from '../../../@Common/UIComponents/Loader';
import ConfigurationAreaRichText from './ConfigurationAreaRichText';
import * as configurationKeys from '../helper/ConfigurationKeys';
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr';
import { ButtonSpinner } from '../../../@Common/UIComponents/ButtonSpinner';
import conversationDuration from '../helper/ConversationDuration';
import { AuthorizeAccess, AuthorizeAccessByRole } from '../../../@Common/UIComponents/AuthorizeAccess';
import { doesUserHaveRole } from '../../../@Common/Helpers/RoleHelper'
import { KnowledgeHubPermissions } from '../../../@Common/Helpers/Permissions'
import { BaseRippleRoles } from '../../../@Common/Enums/BaseRippleRoles'


const mapState = (state: RootState) => ({
    configurationArea: state.configurationArea,
    auth: state.auth
})
const connector = connect(mapState, configurationAreaRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const ConfigurationArea: FC<PropsFromRedux> = ({ configurationArea, auth }) => {

    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [configurationAreaList, setConfigurationAreaList] = useState<ConfigurationAreaModel[]>();

    let { loading } = configurationArea;

    useEffect(() => {
        const loadConfigurationArea = async () => {
            await dispatch(configurationAreaRedux.actions.requestConfigurationArea());
        }
        const result = loadConfigurationArea();
    }, []);

    useEffect(() => {
        if (configurationArea.configurationArea) {
            setConfigurationAreaList(configurationArea.configurationArea);
        }
    }, [configurationArea.configurationArea]);

    // Handle Change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Handle Configuration Change
        handleConfigurationChange(e.target.id, e.target.value);
    }

    // Handle Rich Text Change
    const handleRichTextChange = async (text: string, input: string) => {
        // Handle Configuration Change
        handleConfigurationChange(input, text);
    }

    // Handle Configuration Change
    const handleConfigurationChange = (input: string, value: string) => {
        const newConfig = configurationAreaList?.slice();
        newConfig?.forEach((config) => {
            if (config.key == input) {
                config.value = value;
            }
        });
        setConfigurationAreaList(newConfig);
    }

    // Save Configuration
    const saveConfigurationAreaData = async () => {
        setIsSaving(true);

        let result = await saveConfigurationArea(configurationAreaList!);
        if (result.isSuccess) {
            showToastrSuccess('Configurations Saved Successfully');
        }
        else {
            showToastrErrors(result.errors);
        }

        setIsSaving(false);
    }

    // Get Input Value
    const getValue = (configurationKey: string) => {
        return configurationAreaList?.find(config => { return config.key === configurationKey })?.value
    }

    // Rich Text - Warning Message PlaceHolders
    const richTextPlaceHolders = (configurationKey: string) => {

        let placeholders = [
            { buttonText: "Limit", placeholder: "[[limit]]" },
            { buttonText: "Duration", placeholder: "[[duration]]" }
        ];

        if (configurationKey === configurationKeys.beneficiaryConversationsMessage) {
            placeholders.unshift({ buttonText: "Beneficiary", placeholder: "[[beneficiary]]" });
        }
        else if (configurationKey == configurationKeys.mhfaConversationsMessage) {
            placeholders.unshift({ buttonText: "MHFA", placeholder: "[[mhfa]]" });
        }

        return placeholders;
    }

    // Conversation Duration Select
    const conversationDurationSelect = (min: number, max: number) => {
        let configMin = configurationAreaList?.find(
            config => { return config.key === configurationKeys.conversationRecommendedLengthMin })?.value;

        let configMax = configurationAreaList?.find(
            config => { return config.key === configurationKeys.conversationRecommendedLengthMax })?.value;

        if (min.toString() === configMin && max.toString() === configMax) {
            return 'success';
        }
        return 'primary';
    }

    // Handle Click of Conversation Duration Selection
    const handleClickConversationDuration = (min: number, max: number) => {
        // Change Min
        handleConfigurationChange(configurationKeys.conversationRecommendedLengthMin, min.toString());
        // Change Max
        handleConfigurationChange(configurationKeys.conversationRecommendedLengthMax, max.toString());
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle>Settings</PageTitle>
                <PageButtons></PageButtons>
                <Loader loading={loading}>
                    {configurationAreaList &&
                        <div className='card-xl-stretch mb-xl-8 card'>
                            <div className='card-body'>
                                <AuthorizeAccessByRole roleKey={BaseRippleRoles.RippleAndCoSuperAdmin}>
                                    <div className='card-group'>
                                        <Cards className='card-xl-stretch mb-xl-8 ic-widget-small card'>
                                            <h1>Conversations Per Employee</h1>
                                            <h3>Conversations Per Employee Limit</h3>
                                            <ul className="ic-table">
                                                <li className="ic-table__row">
                                                    <div className='ic-table__row__field ic-table__row__field--medium'>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            min={0}
                                                            id={configurationKeys.beneficiaryConversationsLimit}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.beneficiaryConversationsLimit)}
                                                        />
                                                    </div>
                                                    <div className='ic-table__row__field ic-table__row__field--larger flex-all-center'>
                                                        Conversations Per Employee Every
                                                    </div>
                                                    <div className='ic-table__row__field ic-table__row__field--medium'>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            min={0}
                                                            id={configurationKeys.beneficiaryConversationsDuration}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.beneficiaryConversationsDuration)}
                                                        />
                                                    </div>
                                                    <div className='ic-table__row__field ic-table__row__field--small flex-all-center'> Days</div>

                                                </li>
                                            </ul>
                                            <h3>Warning Message for Conversations Per Employee</h3>
                                            <ConfigurationAreaRichText
                                                id={configurationKeys.beneficiaryConversationsMessage}
                                                text={getValue(configurationKeys.beneficiaryConversationsMessage)!}
                                                onChange={(text) => handleRichTextChange(text, configurationKeys.beneficiaryConversationsMessage)}
                                                placeholders={richTextPlaceHolders(configurationKeys.beneficiaryConversationsMessage)}
                                                bgColor='#FFFFFF'
                                            />
                                        </Cards>
                                        <Cards
                                            className='card-xl-stretch mb-xl-8 ic-widget-small card'
                                        >
                                            <h1>Conversations Per MHFA</h1>
                                            <h3>Conversations Per MHFA Limit</h3>
                                            <ul className="ic-table">
                                                <li className="ic-table__row">
                                                    <div className='ic-table__row__field ic-table__row__field--medium'>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            min={0}
                                                            id={configurationKeys.mhfaConversationsLimit}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.mhfaConversationsLimit)}
                                                        />
                                                    </div>
                                                    <div className='ic-table__row__field ic-table__row__field--larger flex-all-center'>
                                                        Conversations Per Each MHFA Every
                                                    </div>
                                                    <div className='ic-table__row__field ic-table__row__field--medium'>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            min={0}
                                                            id={configurationKeys.mhfaConversationsDuration}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.mhfaConversationsDuration)}
                                                        />
                                                    </div>
                                                    <div className='ic-table__row__field ic-table__row__field--small flex-all-center'> Days</div>

                                                </li>
                                            </ul>
                                            <h3>Warning Message for Conversations Per MHFA</h3>
                                            <ConfigurationAreaRichText
                                                id={configurationKeys.mhfaConversationsMessage}
                                                text={getValue(configurationKeys.mhfaConversationsMessage)!}
                                                onChange={(text) => handleRichTextChange(text, configurationKeys.mhfaConversationsMessage)}
                                                placeholders={richTextPlaceHolders(configurationKeys.mhfaConversationsMessage)}
                                                bgColor='#FFFFFF'
                                            />
                                        </Cards>

                                    </div>
                                    <div className='card-group'>
                                        <Cards className='card-xl-stretch mb-xl-8 ic-widget-small card'>
                                            <h1>Recommended Duration for Conversation</h1>
                                            <h3>Recommended Duration for Conversation</h3>
                                            <ul className="ic-table">
                                                <li className="ic-table__row">
                                                    <ButtonGroup>
                                                        {conversationDuration.map(duration => (
                                                            <Button
                                                                key={duration.key}
                                                                size='sm'
                                                                variant={conversationDurationSelect(duration.min, duration.max)}
                                                                onClick={() => handleClickConversationDuration(duration.min, duration.max)}
                                                            >
                                                                {duration.display}
                                                            </Button>
                                                        ))}
                                                    </ButtonGroup>
                                                </li>
                                            </ul>
                                            <ul className="ic-table" style={{ display: 'none' }}>
                                                <li className="ic-table__row">
                                                    <div>
                                                        <div className='ic-table__row__field ic-table__row__field--medium flex-all-center'>
                                                            Minimum
                                                        </div>
                                                        <div className='ic-table__row__field ic-table__row__field--medium'>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                min={0}
                                                                id={configurationKeys.conversationRecommendedLengthMin}
                                                                onChange={(e) => handleChange(e)}
                                                                value={getValue(configurationKeys.conversationRecommendedLengthMin)}
                                                            />
                                                        </div>
                                                        <div className='ic-table__row__field ic-table__row__field--medium flex-all-center'>
                                                            Maximum
                                                        </div>
                                                        <div className='ic-table__row__field ic-table__row__field--medium'>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                min={0}
                                                                id={configurationKeys.conversationRecommendedLengthMax}
                                                                onChange={(e) => handleChange(e)}
                                                                value={getValue(configurationKeys.conversationRecommendedLengthMax)}
                                                            />
                                                        </div>
                                                        <div className='ic-table__row__field ic-table__row__field--small flex-all-center'> Minutes</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <h3>Warning Message for Conversation Duration</h3>
                                            <ConfigurationAreaRichText
                                                id={configurationKeys.conversationRecommendedLengthMessage}
                                                text={getValue(configurationKeys.conversationRecommendedLengthMessage)!}
                                                onChange={(text) => handleRichTextChange(text, configurationKeys.conversationRecommendedLengthMessage)}
                                                placeholders={richTextPlaceHolders(configurationKeys.conversationRecommendedLengthMessage)}
                                                bgColor='#FFFFFF'
                                            />
                                        </Cards>
                                    </div>
                                </AuthorizeAccessByRole>
                                

                                <AuthorizeAccess permissionKey={KnowledgeHubPermissions.UpdateTenantSpecificDescription}
                                    allowAccess={doesUserHaveRole(auth.user, BaseRippleRoles.RippleAndCoSuperAdmin) || doesUserHaveRole(auth.user, BaseRippleRoles.TenantAdmin)}>
                                    <div className='card-group'>
                                        <Cards
                                            className='card-xl-stretch mb-xl-8 ic-widget-small card'
                                        >
                                            <h1>Details of EAP</h1>
                                            <h3>Employee Assistance Programme Details</h3>
                                            <ul className="ic-table">
                                                <li className="ic-table__row">
                                                    <div className='ic-table__row__field ic-table__row__field--small flex-all-center'> Name</div>
                                                    <div className='ic-table__row__field ic-table__row__field--largest'>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            min={0}
                                                            id={configurationKeys.eapName}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.eapName)}
                                                        />
                                                    </div>
                                                </li>
                                                <li className="ic-table__row">
                                                    <div className='ic-table__row__field ic-table__row__field--small flex-all-center'> Phone</div>
                                                    <div className='ic-table__row__field ic-table__row__field--largest'>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            min={0}
                                                            id={configurationKeys.eapPhone}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.eapPhone)}
                                                        />
                                                    </div>
                                                </li>
                                                <li className="ic-table__row">
                                                    <div className='ic-table__row__field ic-table__row__field--small flex-all-center'> Email</div>
                                                    <div className='ic-table__row__field ic-table__row__field--largest'>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            min={0}
                                                            id={configurationKeys.eapEmail}
                                                            onChange={(e) => handleChange(e)}
                                                            value={getValue(configurationKeys.eapEmail)}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        </Cards>
                                    </div>
                                </AuthorizeAccess>

                                <div className='card-xl-stretch'>
                                    <ButtonSpinner
                                        variant="primary"
                                        text="Save Settings"
                                        className="w-100"
                                        onClick={() => saveConfigurationAreaData()}
                                        saving={isSaving}
                                    />
                                </div>

                            </div>
                        </div>
                    }
                </Loader>
            </ErrorBoundary>
        </>
    );
};

export default connector(ConfigurationArea);