/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { UserModel } from '../../../../@Common/Models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import * as auth from '../../../../app/core/auth/redux/AuthRedux'
import * as menu from '../../../../_metronic/layout/components/aside/redux/MenuRedux'
import { useDispatch } from 'react-redux'
import { toAbsoluteUrl } from '../../../helpers'
import { LoggedUser } from '../../../layout/components/LoggedUser'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
    dispatch(menu.actions.clearMenuItems())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <LoggedUser />
          </div>
          <br />
          <div className='flex-column'>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={generatePath("/profile/:userId", { userId: user.id })} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
