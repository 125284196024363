import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { ConfigurationAreaModel } from '../models/ConfigurationAreaModel';


const API_URL = process.env.REACT_APP_API_URL + "/api/v1/ConfigurationArea";

export const SAVE_CONFIGURATION_AREA_LIST_URL = `${API_URL}/SaveConfigurationAreaList`;
export const GET_CONFIGURATION_AREA_LIST_URL = `${API_URL}/GetConfigurationAreaList`;
export const GET_CONFIGURATION_AREA_URL = `${API_URL}/GetConfigurationArea`;


export const saveConfigurationArea = async (configuration: ConfigurationAreaModel[]) => {
    let response = await postToServerAPI<ServiceResponse>(SAVE_CONFIGURATION_AREA_LIST_URL, configuration);
    return response;
}

export const getConfigurationAreaList = async () => {
    let response = await getFromServerAPI<ServiceResponse<ConfigurationAreaModel[]>>(GET_CONFIGURATION_AREA_LIST_URL);
    return response;
}

export const getConfigurationArea = async () => {
    let response = await getFromServerAPI<ServiceResponse<ConfigurationAreaModel>>(GET_CONFIGURATION_AREA_URL);
    return response;
}