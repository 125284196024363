import React, { FC, useEffect, useState } from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { GridColSort, GridFilterDto } from '../../../@Common/Models/GridModel'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { CustomColumnDef, Grid } from '../../../@Common/UIComponents/Grid'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { RootState } from '../../../setup'
import { PageButtons, PageTitle } from '../../../_metronic/layout/core'
import { FirstAiderModel } from '../models/FirstAiderModel'
import { getFirstAiderList } from '../redux/FirstAidersActions'
import * as firstAiderRedux from '../redux/FirstAidersRedux'

const mapState = (state: RootState) => ({
    firstAiderList: state.firstAiderList,
    permissions: state.auth.userPermissions
})
const connector = connect(mapState, firstAiderRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const ViewFirstAiders: FC<PropsFromRedux> = ({ firstAiderList, permissions }) => {

    let { loading } = firstAiderList;

    // Grid Columns
    const columns = React.useMemo<CustomColumnDef<FirstAiderModel>[]>(
        () => [
            {
                id: 'fullName',
                header: () => 'MHFA',
                accessorKey: 'fullName',
                cell: info => info.getValue()
            },
            {
                id: 'conversations',
                header: () => 'Total Conversations',
                accessorKey: 'conversations',
                cell: info => info.getValue(),
            },
            {
                id: 'averageDurationText',
                header: () => 'Average Duration',
                accessorKey: 'averageDurationText',
                cell: info => info.getValue(),
            },
        ],
        []
    )

    // Get Data
    const getData = async (options: GridFilterDto) => {

        // Column Sort
        let colSort: GridColSort = { name: "conversations", isDesc: true };
        // Grid Filter Column Sort
        options.sorting = colSort;

        var dataResponse = await getFirstAiderList(options);
        if (dataResponse.isSuccess)
            return dataResponse.data!;
        else
            return null;
    }

    return (

                <ErrorBoundary>
                    <PageTitle>MHFAs</PageTitle>
                    <PageButtons>
                    </PageButtons>
                    <Loader loading={loading}>
                    <div className='card-xl-stretch mb-xl-8 card'>
                        <div className='card-body'>
                            <div className='card-group'>
                                <Cards
                                    className='card-xl-stretch mb-xl-8 ic-widget-small card'
                                >
                                    <h1>MHFA List</h1>
                                    <Grid<FirstAiderModel>
                                        columns={columns}
                                        getData={(options) => getData(options)}
                                        maxPageSize={25} />
                                </Cards>
                            </div>
                        </div>
                    </div>
                    </Loader>

                </ErrorBoundary>
        
    );
};

export default connector(ViewFirstAiders);