import { postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { OrganisationAiderStatusModel } from '../models/OrganisationAiderStatusModel';
import { AssesmentModel } from '../models/AssesmentModel';
import { CauseAndImpactPercentageModel } from '../models/CauseAndImpactPercentageModel';

const DASHBOARD_API_URL = process.env.REACT_APP_API_URL + "/api/v1/Dashboard";

const GET_ORGANISATION_AIDER_STATUS = `${DASHBOARD_API_URL}/GetOrganisationAiderStatus`;
const GET_ORGANISATION_ASSESMENT_FOR_MONTH = `${DASHBOARD_API_URL}/GetOrganisationAssesmentForMonth`;
const GET_ORGANISATION_TOP_CAUSES_FOR_MONTHL = `${DASHBOARD_API_URL}/GetOrganisationTopCausesForMonth`;
const GET_ORGANISATION_TOP_IMPACTS_FOR_MONTH = `${DASHBOARD_API_URL}/GetOrganisationTopImpactsForMonth`;
const GET_SYSTEM_ASSESMENT_FOR_MONTH = `${DASHBOARD_API_URL}/GetSystemAssesmentForMonth`;
const GET_SYSTEM_TOP_CAUSES_FOR_MONTHL = `${DASHBOARD_API_URL}/GetSystemTopCausesForMonth`;
const GET_SYSTEM_TOP_IMPACTS_FOR_MONTH = `${DASHBOARD_API_URL}/GetSystemTopImpactsForMonth`;

export const GetOrganisationAiderStatus = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<OrganisationAiderStatusModel[]>>(GET_ORGANISATION_AIDER_STATUS, { month, year });
    return response;
}

export const GetOrganisationAssesmentForMonth = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<AssesmentModel>>(GET_ORGANISATION_ASSESMENT_FOR_MONTH, { month, year });
    return response;
}

export const GetOrganisationTopCausesForMonth = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<CauseAndImpactPercentageModel[]>>(GET_ORGANISATION_TOP_CAUSES_FOR_MONTHL, { month, year });
    return response;
}

export const GetOrganisationTopImpactsForMonth = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<CauseAndImpactPercentageModel[]>>(GET_ORGANISATION_TOP_IMPACTS_FOR_MONTH, { month, year });
    return response;
}

export const GetSystemAssesmentForMonth = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<AssesmentModel>>(GET_SYSTEM_ASSESMENT_FOR_MONTH, { month, year });
    return response;
}

export const GetSystemTopCausesForMonth = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<CauseAndImpactPercentageModel[]>>(GET_SYSTEM_TOP_CAUSES_FOR_MONTHL, { month, year });
    return response;
}

export const GetSystemTopImpactsForMonth = async (month: number, year: number) => {
    let response = await postToServerAPI<ServiceResponse<CauseAndImpactPercentageModel[]>>(GET_SYSTEM_TOP_IMPACTS_FOR_MONTH, { month, year });
    return response;
}