import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

const CookiePolicy: React.FC = () => {
    return(
        <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="CookiePolicy"
        style={{ background: "#2B373B" }}
        buttonClasses="btn-primary"
        buttonStyle={{ fontSize: "13px" }}
        expires={150}
      >        
        This website uses cookies to enhance the user experience. The full cookie policy can be seen <a href="https://www.rippleandco.com/privacy-policy" target="_blank">here</a>.
      </CookieConsent>
    );
}

export { CookiePolicy }
