import axios from "axios";
import { getFromServerAPI } from "../../../../../@Common/Helpers/ApiHelper";
import { ServiceResponse } from "../../../../../@Common/Interfaces/ServiceResponse";
import { MenuItemModel } from "../models/MenuItemModel";

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/tenant";

export const GET_MENU_ITEMS_URL = `${API_URL}/GetMenuItems`;

export const getMenuItems = async () => {
    let menuItemsResponse = await getFromServerAPI<ServiceResponse<MenuItemModel[]>>(GET_MENU_ITEMS_URL);

    if (menuItemsResponse.isSuccess)
        return menuItemsResponse.data!;
    else
        return [];
}