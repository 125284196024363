import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../@Common/Models/UserModel'
import { RootState } from '../../../setup'

const LoggedUser: FC = () => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

    return (
        <div className=''>
            {user &&
                <>
                    Hello <b>{user.name}</b>
                </>
            }
        </div>
    );
}

export { LoggedUser }