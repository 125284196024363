import { toAbsoluteUrl } from '../../_metronic/helpers';
import { WellbeingStatuses } from "../../@Common/Enums/WellbeingStatuses"

//References Enum values in WellbeingStatuses
const moodMap =
{
    0: '/media/moods/mood-angry-regular.svg',   //Very Bad
    1: '/media/moods/mood-frown-regular.svg',   //Bad
    2: '/media/moods/mood-meh-regular.svg',     //Normal
    3: '/media/moods/mood-smile-regular.svg',   //Good
    4: '/media/moods/mood-laugh-regular.svg'    //Very Good
}

export const getAbsoluteMoodUrl = (wellBeingScore: number): string => {
    let wellBeingScoreEnum: WellbeingStatuses = wellBeingScore;

    if (wellBeingScoreEnum == undefined || wellBeingScoreEnum == WellbeingStatuses.Unset) {
        console.error("Unable to get mood url as the well being score value provided is invalid.");
        return ""
    }

    return toAbsoluteUrl(moodMap[wellBeingScoreEnum]);
}