import axios from "axios";
import { Request, Response, NextFunction } from 'express';

export const postToServerAPI = async <T>(url: string, params: any = {}): Promise<T> => {
    try {
        let response = await axios.post(url, params);
        return response.data;
    } catch (e) {
        throw e;
    }
}

export const getFromServerAPI = async <T>(url: string, params: any = {}): Promise<T> => {
    try {
        let customConfig = {
            params: {},
            headers: {}
        }

        if (params != null)
            customConfig.params = params;

        let response = await axios.get(url, customConfig);
        return response.data;
    } catch (e) {
        throw e;
    }
}