import { lazy, FC, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import HomeWrapper from '../core/home/HomeWrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../setup';
import { MenuItemModel } from '../../_metronic/layout/components/aside/models/MenuItemModel';
import { SuspensedView } from '../../_metronic/layout/SuspendedView';
import RoutePath from '../../@Common/Helpers/RoutePathConstants'
import OrganisationViewPage from '../dashboard/organisationViewPage'
import SystemViewPage from '../dashboard/systemViewPage'
import ViewFirstAidersPage from '../firstaiders/viewFirstAidersPage';
import KnowledgehubPage from '../knowledgehub/knowledgehubPage'
import KhciPage from '../knowledgehub/knowledgeHubCauseAndImpactPage'
import ImportBeneficiaryPage from '../beneficiary/ImportBeneficiaryPage'
import BeneficiaryListPage from '../beneficiary/BeneficiaryListPage'
import ConfigurationAreaPage from '../configurationArea/ConfigurationAreaPage'

const PrivateRoutes = () => {
  const PeoplePage = lazy(() => import('../core/people/PeoplePage'))
  const ProfilePage = lazy(() => import('../core/profile/ProfilePage'))
  const ConversationPage = lazy(() => import('../conversation/ConversationPage'))
  const ViewConversationPage = lazy(() => import('../conversation/ViewConversationPage'))
  const MHFADashboardPage = lazy(() => import('../mhfaDashboard/MHFADashboard'))
  const ComplianceDashboardPage = lazy(() => import('../complianceDashboard/ComplianceDashboardPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Home after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/home' />} />
                {/* Pages */}
                <Route path='home' element={<HomeWrapper />} />
                {/* Lazy Modules */}
                <Route
                    path={RoutePath.PEOPLE + '/*'}
                    element={
                        <AuthorizedPage>
                            <SuspensedView>
                                <PeoplePage />
                            </SuspensedView>
                        </AuthorizedPage>} />

                <Route
                    path='profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                } />

                
                <Route
                    path={RoutePath.CREATE_CONVERSATION}
                    element={
                        <AuthorizedPage>
                        <SuspensedView>
                            <ConversationPage />
                        </SuspensedView>
                        </AuthorizedPage>
                } />

                <Route
                    path={RoutePath.VIEW_CONVERSATION}
                    element={
                      <SuspensedView>
                        <ViewConversationPage />
                      </SuspensedView>
                } />

                <Route
                    path={RoutePath.SELF_VIEW}
                    element={
                        <AuthorizedPage>
                        <SuspensedView>
                            <MHFADashboardPage />
                        </SuspensedView>
                        </AuthorizedPage>
                } />

                <Route
                    path={RoutePath.COMPLIANCE_VIEW}
                    element={
                        <AuthorizedPage>
                            <SuspensedView>
                                <ComplianceDashboardPage />
                            </SuspensedView>
                        </AuthorizedPage>
                }/>

                <Route path={RoutePath.ORGANISATION_VIEW} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <OrganisationViewPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.SYSTEM_VIEW} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <SystemViewPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.CREATE_CONVERSATION} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <ConversationPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.FIRST_AIDERS_VIEW} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <ViewFirstAidersPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.KNOWLEDGE_HUB_VIEW} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <KnowledgehubPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.KNOWLEDGE_HUB_CAUSE_AND_IMPACT_VIEW} element={
                    <SuspensedView>
                        <KhciPage />
                    </SuspensedView>} />

                <Route path={RoutePath.BENEFICIARY_LIST} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <BeneficiaryListPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.IMPORT_BENEFICIARY_LIST} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <ImportBeneficiaryPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                <Route path={RoutePath.CONFIGURATION_AREA} element={
                    <AuthorizedPage>
                        <SuspensedView>
                            <ConfigurationAreaPage />
                        </SuspensedView>
                    </AuthorizedPage>} />

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const AuthorizedPage: FC = ({ children }) => {
    let menuItems = useSelector<RootState, MenuItemModel[]>(({ menu }) => menu.menuItems!)

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (menuItems != undefined || menuItems != null) {            
            const hasAccess = menuItems!.findIndex(mi => mi.location == location.pathname.substr(1, location.pathname.length)) > -1;

            if (!hasAccess){
                console.error("user unauthorised for private route");
                console.error(menuItems);
                navigate('/error/401');
            }
        }      

    }, [location.pathname, menuItems])

    return <>{children}</>
}

export { PrivateRoutes }