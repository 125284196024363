import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { CauseAndImpactFullModel } from "../models/CausesAndImpactModel";
import { getCauseAndImpactDetails } from "./KnowledgeHubActions";

export const actionTypes = {
    CauseAndImpactRequested: '[Request Cause and Impact] Action',
    CauseAndImpactLoaded: '[Loaded Cause and Impact] API',
    CauseAndImpactFailed: '[Failed Cause and Impact] API',
    ShowGeneralDescriptionModal: '[General Description Modal] Action',
    ShowTenantSpecificDescriptionModal: '[Tenant SpecificDescription Modal] Action',
    CauseAndImpactSaved: '[Cause And Impact Saved] Action',
    RequestEditGeneralDescription: '[General Description Edit] Action',
    RequestEditTenantSpecificDescription: '[Tenant Specific Description Edit] Action'
}

export interface IKnowledgeHubCausesAndImpactState {
    causeAndImpact?: CauseAndImpactFullModel,
    loading: boolean,
    causeAndImpactNotFound: boolean,
    showEditGeneralDescriptionModal: boolean,
    showEditTenantSpecificDescriptionModal: boolean,
}

const initialKhciState: IKnowledgeHubCausesAndImpactState =
 {
    causeAndImpact: undefined,
    loading: false,
    causeAndImpactNotFound: false,
    showEditGeneralDescriptionModal: false,
    showEditTenantSpecificDescriptionModal: false,
}

export const reducer = (state: IKnowledgeHubCausesAndImpactState = initialKhciState, action: ActionWithPayload<IKnowledgeHubCausesAndImpactState>): IKnowledgeHubCausesAndImpactState => {
    switch (action.type) {
        case actionTypes.CauseAndImpactRequested: {
            return { ...state, loading: true };
        }

        case actionTypes.CauseAndImpactLoaded: {
            return {
                ...state,
                causeAndImpact: action.payload?.causeAndImpact,
                loading: false,
                causeAndImpactNotFound: false
            };
        }

        case actionTypes.CauseAndImpactFailed: {
            return {
                ...state,
                loading: false,
                causeAndImpactNotFound: true
            };
        }

        case actionTypes.ShowGeneralDescriptionModal: {
            let showEditGeneralDescriptionModal = action.payload?.showEditGeneralDescriptionModal!;
            return { ...state, showEditGeneralDescriptionModal};
        }

        case actionTypes.ShowTenantSpecificDescriptionModal: {
            let showEditTenantSpecificDescriptionModal = action.payload?.showEditTenantSpecificDescriptionModal!;
            return { ...state, showEditTenantSpecificDescriptionModal };
        }

        case actionTypes.RequestEditGeneralDescription: {
            return {
                ...state,
                causeAndImpact: action.payload?.causeAndImpact,
                showEditGeneralDescriptionModal: true
            };
        }

        case actionTypes.RequestEditTenantSpecificDescription: {
            return {
                ...state,
                causeAndImpact: action.payload?.causeAndImpact,
                showEditTenantSpecificDescriptionModal: true
            };
        }

        case actionTypes.CauseAndImpactSaved: {
            return { 
                ...state, 
                showEditGeneralDescriptionModal: false, 
                showEditTenantSpecificDescriptionModal: false,
                causeAndImpact: action.payload?.causeAndImpact 
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestCauseAndImpactCategory: (causeAndImpactTitle: string) => ({ type: actionTypes.CauseAndImpactRequested, payload: { causeAndImpactTitle } }),
    fulfillCauseAndImpactCategory: (causeAndImpact: CauseAndImpactFullModel) => ({ type: actionTypes.CauseAndImpactLoaded, payload: { causeAndImpact } }),
    fulfillFailedCauseAndImpactCategory: () => ({ type: actionTypes.CauseAndImpactFailed }),
    fulfillCauseAndImpactSaved: (causeAndImpact: CauseAndImpactFullModel) => ({ type: actionTypes.CauseAndImpactSaved, payload: { causeAndImpact } }),
    showGeneralDescriptionModal: (showDescriptionModal: boolean) => ({ type: actionTypes.ShowGeneralDescriptionModal, payload: { showDescriptionModal } }),
    showTenantSpecificDescriptionModal: (showDescriptionModal: boolean) => ({ type: actionTypes.ShowTenantSpecificDescriptionModal, payload: { showDescriptionModal } }),
    editGeneralDescriptionModal: (causeAndImpact: CauseAndImpactFullModel) => ({ type: actionTypes.RequestEditGeneralDescription, payload: { causeAndImpact } }),
    editTenantSpecificDescriptionModal: (causeAndImpact: CauseAndImpactFullModel) => ({ type: actionTypes.RequestEditTenantSpecificDescription, payload: { causeAndImpact } })
}

export function* saga() {
    function* causeAndImpactRequested(action: any) {
        let causeAndImpactTitle: string = action.payload.causeAndImpactTitle;

        const causeAndImpactCategoryResponse: ServiceResponse<CauseAndImpactFullModel> = yield getCauseAndImpactDetails(causeAndImpactTitle);

        if (causeAndImpactCategoryResponse.isSuccess)
            yield put(actions.fulfillCauseAndImpactCategory(causeAndImpactCategoryResponse.data!));
        else
            yield put(actions.fulfillFailedCauseAndImpactCategory());
    }

    yield takeLatest(actionTypes.CauseAndImpactRequested, causeAndImpactRequested);
}