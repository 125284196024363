import {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Navigate, Routes} from 'react-router-dom'
import { UserModel } from '../../../@Common/Models/UserModel'
import { RootState } from '../../../setup'
import * as auth from './redux/AuthRedux'
import * as menu from '../../../_metronic/layout/components/aside/redux/MenuRedux'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(auth.actions.logout());
    dispatch(menu.actions.clearMenuItems);
    document.location.reload();
  }, [dispatch])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
