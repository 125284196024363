import { getFromServerAPI, postToServerAPI } from '../../../../@Common/Helpers/ApiHelper';
import { ServiceResponse } from '../../../../@Common/Interfaces/ServiceResponse';
import { OptionModel } from '../../../../@Common/Models/OptionModel';
import { AssignUnAssignUserToRoleModel } from '../models/AssignUnAssignUserToRoleModel';
import { RoleSpecificPermissionModel } from '../models/RippleAndCo/RoleSpecificPermissionModel';
import { RoleWriteModel } from '../models/RoleWriteModel';
import { NewUserModel } from '../models/NewUserModel';
import { RolePermissionsReadModel, RolePermissionsWriteModel } from '../models/RolePermissionsModel';
import { UsersRoles } from '../models/UsersRoles';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/people";

export const GET_USERS_ROLES_URL = `${API_URL}/GetUsersRoles`;
export const CREATE_ROLE_URL = `${API_URL}/CreateRole`;
export const EDIT_ROLE_URL = `${API_URL}/EditRole`;
export const DELETE_ROLE_URL = `${API_URL}/DeleteRole`;
export const CREATE_USER_URL = `${API_URL}/CreateUser`;
export const ASSIGN_USER_TO_ROLE_URL = `${API_URL}/AssignUserToRole`;
export const UNASSIGN_USER_FROM_ROLE_URL = `${API_URL}/UnAssignUserFromRole`;
export const GET_FILTERED_USERS_URL = `${API_URL}/GetFilteredUsersForRole`;
export const GET_ROLE_PERMISSIONS = `${API_URL}/GetRolePermissions`;
export const SAVE_ROLE_PERMISSIONS = `${API_URL}/SaveRolePermissions`;
export const GET_ROLE_SPECIFIC_PERMISSIONS = `${API_URL}/GetRoleSpecificPermissions`;
export const SAVE_ROLE_SPECIFIC_PERMISSIONS = `${API_URL}/SaveRoleSpecificPermissions`;

export const getUsersRoles = async () => {
  let response = await getFromServerAPI<UsersRoles>(GET_USERS_ROLES_URL, {});
  return response;
}

export const saveCreateRole = async (newRole: RoleWriteModel) => {
  let response = await postToServerAPI<ServiceResponse>(CREATE_ROLE_URL, newRole);
  return response;
}

export const saveEditRole = async (newRole: RoleWriteModel) => {
  let response = await postToServerAPI<ServiceResponse>(EDIT_ROLE_URL, newRole);
  return response;
}

export const deleteRole = async (roleId: string) => {
  let response = await postToServerAPI<ServiceResponse>(DELETE_ROLE_URL, { roleId });
  return response;
}

export const saveCreateUser = async (newUser: NewUserModel) => {
  let response = await postToServerAPI<ServiceResponse>(CREATE_USER_URL, newUser);
  return response;
}

export const saveAssignUserToRole = async (model: AssignUnAssignUserToRoleModel) => {
  let response = await postToServerAPI<ServiceResponse>(ASSIGN_USER_TO_ROLE_URL, model);
  return response;
}

export const getFilteredUsers = async (inputValue: string, roleId: string) => {
  return await getFromServerAPI<OptionModel[]>(GET_FILTERED_USERS_URL, { inputValue, roleId });
}

export const saveRemoveUserFromRole = async (model: AssignUnAssignUserToRoleModel) => {
  let response = await postToServerAPI<ServiceResponse>(UNASSIGN_USER_FROM_ROLE_URL, model);
  return response;
}

export const getRolePermissions = async (roleId: string) => {
  let response = await getFromServerAPI<ServiceResponse<RolePermissionsReadModel>>(GET_ROLE_PERMISSIONS, { roleId });
  return response;
}

export const saveRolePermissions = async (rolePermissions: RolePermissionsWriteModel) => {
  let response = await postToServerAPI<ServiceResponse>(SAVE_ROLE_PERMISSIONS, rolePermissions);
  return response;
}

export const getRoleSpecificPermissions = async (roleId: string) => {
  let response = await getFromServerAPI<ServiceResponse<RoleSpecificPermissionModel>>(GET_ROLE_SPECIFIC_PERMISSIONS, { roleId });
  return response;
}

export const saveRoleSpecificPermissions = async (roleSpecificPermissions: RoleSpecificPermissionModel) => {
  let response = await postToServerAPI<ServiceResponse>(SAVE_ROLE_SPECIFIC_PERMISSIONS, roleSpecificPermissions);
  return response;
}