import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { ConversationInformationWriteModel } from '../../conversation/models/ConversationWriteModel';
import { MHFAConversation } from '../models/ConversationReadModel';
import { SelfAccomplishmentsModel } from '../models/SelfAccomplishmentsModel';

const CONVERSATION_API_URL = process.env.REACT_APP_API_URL + "/api/v1/Conversation";
const DASHBOARD_API_URL = process.env.REACT_APP_API_URL + "/api/v1/Dashboard";

const GET_CONVERSATIONS_FOR_MHFA = CONVERSATION_API_URL + "/GetMHFAConversationMinifiedList";
const GET_SELF_ACCOMPLISHMENTS_URL = `${DASHBOARD_API_URL}/GetSelfAccomplishments`;


export const GetMHFAConversationMinifiedList = async (filter: GridFilterDto) => {
    let response = await postToServerAPI<PaginatedServiceResponse<MHFAConversation>>(GET_CONVERSATIONS_FOR_MHFA, filter);
    return response;
}

export const GetSelfAccomplishments = async () => {
    let response = await getFromServerAPI<ServiceResponse<SelfAccomplishmentsModel>>(GET_SELF_ACCOMPLISHMENTS_URL, {});
    return response;
}