import * as toastr from "toastr";

export default function setupToastr() {
    // toastr.options.closeButton = true;
    toastr.options.timeOut = 5000;
    toastr.options.extendedTimeOut = 1000;
    toastr.options.showDuration = 300;
    toastr.options.preventDuplicates = false;
    toastr.options.newestOnTop = true;
    toastr.options.onclick = () => { };
    toastr.options.showEasing = "swing";
    toastr.options.hideEasing = "linear";
    toastr.options.showMethod = "fadeIn";
    toastr.options.hideMethod = "fadeOut";
}

export const showToastrErrors = (errors: string[]) => {
    errors.forEach(error => {
        toastr.error(error);
    });
}

export const showToastrError = (message: string, title?: string) => {
    toastr.error(message, title);
}

export const showToastrInfo = (message: string, title?: string) => {
    toastr.info(message, title);
}

export const showToastrSuccess = (message: string, title?: string) => {
    toastr.success(message, title);
}