import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload"
import { RecordsConversationServiceResponse } from "../../../@Common/Interfaces/RecordsConversationServiceResponse";
import { ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from "../../../@Common/Models/GridModel";
import { SelfAccomplishmentsModel } from '../models/SelfAccomplishmentsModel';
import { GetMHFAConversationMinifiedList, GetSelfAccomplishments } from "./MHFADashboardActions";

export const actionTypes = {
    MHFAListConversationRequested: '[Request MHFA List Conversations] Action',
    MHFAListConversationLoaded: '[Loaded MHFA List Conversations] API',
    MHFASelfAccomplishmentsRequested: '[Request MHFA Self Accomplishments] Action',
    MHFASelfAccomplishmentsLoaded: '[Loaded MHFA Self Accomplishments] API',
}

export interface IConversation {
    id: number,
    beneficiary: string,
    date: Date
}

export interface IConversationRecords {
    records: IConversation[];
}

export interface IMHFADashboardState {
    mhfaConversationListLoading: boolean,
    mhfaConversationList: IConversationRecords | undefined,
    selfAccomplishmentsLoading: boolean,
    selfAccomplishments: SelfAccomplishmentsModel | undefined
}

const initialMHFADashboardState: IMHFADashboardState = {
    mhfaConversationListLoading: false,
    mhfaConversationList: { records: [] },
    selfAccomplishmentsLoading: false,
    selfAccomplishments: undefined
}

export const reducer = (state: IMHFADashboardState = initialMHFADashboardState, action: ActionWithPayload<IMHFADashboardState>): IMHFADashboardState => {
    switch (action.type) {
        case actionTypes.MHFAListConversationRequested: {
            return {
                ...state,
                mhfaConversationListLoading: true
            };
        }

        case actionTypes.MHFAListConversationLoaded: {
            return {
                ...state,
                mhfaConversationList: action.payload?.mhfaConversationList,
                mhfaConversationListLoading: false
            };
        }

        case actionTypes.MHFASelfAccomplishmentsRequested: {
            return {
                ...state,
                selfAccomplishmentsLoading: true
            };
        }

        case actionTypes.MHFASelfAccomplishmentsLoaded: {
             return {
                ...state,
                selfAccomplishments: action.payload?.selfAccomplishments,
                selfAccomplishmentsLoading: false
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestMHFAListConversations: () => ({ type: actionTypes.MHFAListConversationRequested }),
    fulfillMHFAListConversations: (mhfaConversationList: IConversationRecords) => ({ type: actionTypes.MHFAListConversationLoaded, payload: { mhfaConversationList } }),
    requestMHFASelfAccomplishments: () => ({ type: actionTypes.MHFASelfAccomplishmentsRequested }),
    fulfillMHFASelfAccomplishments: (selfAccomplishments: SelfAccomplishmentsModel) => ({ type: actionTypes.MHFASelfAccomplishmentsLoaded, payload: { selfAccomplishments } })
}

export function* saga() {
    yield takeLatest(actionTypes.MHFAListConversationRequested, function* mhfaListConversationRequested() {

        let fetchDto: GridFilterDto = {
            pageIndex: 0,
            pageSize: 10
        };

        const mhfaConversationRequestedResponse: RecordsConversationServiceResponse<IConversationRecords> = yield GetMHFAConversationMinifiedList(fetchDto);

        if (mhfaConversationRequestedResponse.isSuccess) {
            let conversationState: IConversationRecords = {
                records: mhfaConversationRequestedResponse.data?.records
            }
            yield put(actions.fulfillMHFAListConversations(conversationState));
        } else {
            showToastrErrors(mhfaConversationRequestedResponse.errors);
        }
    })

    yield takeLatest(actionTypes.MHFASelfAccomplishmentsRequested, function* mhfaSelfAccomplishmentsRequested() {
        const mhfaSelfAccomplishmentsRequestedResponse: ServiceResponse<SelfAccomplishmentsModel> = yield GetSelfAccomplishments();

        if (mhfaSelfAccomplishmentsRequestedResponse.isSuccess && mhfaSelfAccomplishmentsRequestedResponse.data) {
            let selfAccomplishmentsState: SelfAccomplishmentsModel = mhfaSelfAccomplishmentsRequestedResponse.data;
            yield put(actions.fulfillMHFASelfAccomplishments(selfAccomplishmentsState));
        } else {
            showToastrErrors(mhfaSelfAccomplishmentsRequestedResponse.errors);
        }
    })
}