/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, CardGroup, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { BeneficiaryModel } from '../models/BeneficiaryModel'
import { getBeneficiary, updateBeneficiary } from '../redux/BeneficiaryActions'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'
import { BeneficiaryWriteModel } from '../models/BeneficiaryWriteModel'
import { FormGroupComponent } from '../../../@Common/FormComponents/FormGroupComponent'
import { nameOf } from '../../../@Common/Helpers/Utilities'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import { ButtonSpinner } from '../../../@Common/UIComponents/ButtonSpinner'
import { Modals } from '../../../@Common/UIComponents/Modals'
import { Loader } from '../../../@Common/UIComponents/Loader'
import * as beneficiaryListRedux from '../redux/BeneficiaryListRedux'


interface BeneficiaryProps {
    id: number;
    isShowModal: boolean;
    setIsShowModal: (isShowModal: boolean) => {};
}

const BeneficiaryFormModal: FC<BeneficiaryProps> = ({ id, isShowModal, setIsShowModal }) => {

    const [beneficiary, setBeneficiary] = useState<BeneficiaryModel>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const formikRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        getBeneficiaryData();
    }, [id]);


    // Get Beneficiary Data
    const getBeneficiaryData = async () => {

        // Check Beneficiary Id
        if (id > 0) {

            // Loading
            await setIsLoading(true);

            // Data - GET
            let beneficiaryData = await getBeneficiary(id);
            await setBeneficiary(beneficiaryData.data);

            // Stop Loading
            await setIsLoading(false);
        }
    }

    // Beneficiary Schema
    const beneficiarySchema: Yup.SchemaOf<BeneficiaryWriteModel> = Yup.object({
        id: Yup.number()
            .required(),
        idNumber: Yup.string()
            .label("Id Number")
            .max(25)
            .required(),
        name: Yup.string()
            .label("Name")
            .max(50)
            .required(),
        surname: Yup.string()
            .label("Surname")
            .max(50)
            .required(),
        email: Yup.string()
            .label("Email")
            .max(50)
            .required()

    });

    const displayBeneficiaryForm = (props: FormikProps<BeneficiaryWriteModel>) => {
        return <>

            <div className=''>
                <FormGroupComponent
                    name={`${nameOf<BeneficiaryWriteModel>("idNumber")}`}
                    className="ic-widget-small__form-list__row"
                    controlId='formBeneficiaryIdNumber'
                    label='Id Number'
                    value={props.values.idNumber}
                    isInvalidError={props.errors.idNumber}
                    touched={props.touched.idNumber}
                    type="text"
                    handleChange={props.handleChange}
                    required
                >
                </FormGroupComponent>
                <FormGroupComponent
                    name={`${nameOf<BeneficiaryWriteModel>("name")}`}
                    className="ic-widget-small__form-list__row"
                    controlId='formBeneficiaryName'
                    label='Name'
                    value={props.values.name}
                    isInvalidError={props.errors.name}
                    touched={props.touched.name}
                    type="text"
                    handleChange={props.handleChange}
                    required
                >
                </FormGroupComponent>
                <FormGroupComponent
                    name={`${nameOf<BeneficiaryWriteModel>("surname")}`}
                    className="ic-widget-small__form-list__row"
                    controlId='formBeneficiarySurname'
                    label='Surname'
                    value={props.values.surname}
                    isInvalidError={props.errors.surname}
                    touched={props.touched.surname}
                    type="text"
                    handleChange={props.handleChange}
                    required
                >
                </FormGroupComponent>
                <FormGroupComponent
                    name={`${nameOf<BeneficiaryWriteModel>("email")}`}
                    className="ic-widget-small__form-list__row"
                    controlId='formBeneficiaryEmail'
                    label='Email'
                    value={props.values.email}
                    isInvalidError={props.errors.email}
                    touched={props.touched.email}
                    type="email"
                    handleChange={props.handleChange}
                    required
                >
                </FormGroupComponent>
            </div>
        </>
    }

    // Handle Save from Modal Footer
    const onSaveBeneficiary = async () => {
        await (formikRef.current as any).submitForm();
    }

    // Handle Modal Close -> Request Beneficiary List
    const onCloseBeneficiaryFormModal = () => {

        // Reset Form
        (formikRef.current as any).resetForm();

        if (isReload) {
            // Request Beneficiary List
            dispatch(beneficiaryListRedux.actions.requestBeneficiaryList());
        }

        // Trigger Hide Modal in Beneficiary List
        setIsShowModal(false);
    }

    return (
        <>
            <Modals
                show={isShowModal}
                title={"Employee"}
                onSave={onSaveBeneficiary}
                saving={isSaving}
                onClose={onCloseBeneficiaryFormModal}
            >
                <Loader loading={isLoading}>
                    {beneficiary &&
                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                id: beneficiary!.id,
                                idNumber: beneficiary!.idNumber,
                                name: beneficiary!.name,
                                surname: beneficiary!.surname,
                                email: beneficiary!.email

                            } as BeneficiaryWriteModel}
                            validateOnChange={true}
                            validateOnBlur={false}
                            validationSchema={beneficiarySchema}
                            onSubmit={(values: BeneficiaryWriteModel, { setSubmitting, setStatus, resetForm }) => {
                                setIsSaving(true);
                                setTimeout(async () => {
                                    try {
                                        let response = await updateBeneficiary(values);

                                        if (response.isSuccess) {
                                            showToastrSuccess("Beneficiary Updated Successfully");
                                        }
                                        else {
                                            showToastrErrors(response.errors);
                                        }
                                    }
                                    catch (e) {
                                        showToastrError('An unexpected error has occured');
                                    }

                                    setSubmitting(false);
                                    setIsSaving(false);
                                    // Indicates that beneficiary list will load after close
                                    setIsReload(true);


                                }, 1000)
                            }}
                        >
                            {(props) => (
                                <>
                                    <Form noValidate onSubmit={props.handleSubmit}>
                                        {displayBeneficiaryForm(props)}
                                    </Form>
                                </>
                            )}
                        </Formik>
                    }
                </Loader>
            </Modals>
        </>
    );
}

export default BeneficiaryFormModal;
