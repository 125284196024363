/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import * as menuRedux from '../redux/MenuRedux'
import { RootState } from '../../../../../setup'
import { getMenuItems } from '../redux/MenuActions'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { MenuItemModel } from '../models/MenuItemModel'

const mapState = (state: RootState) => ({ menu: state.menu })
const connector = connect(mapState, menuRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AsideMenuMain: FC<PropsFromRedux> = ({ menu }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    async function loadMenuItems() {
      dispatch(menuRedux.actions.requestMenuItems())
    }

    loadMenuItems();
  }, [])

  const displayMenuItems = (menuItems: MenuItemModel[]) => {
    let parentItems = menu.menuItems?.filter(item => item.parentMenuItem == null) ?? [];
    let childrenItems = menu.menuItems?.filter(item => item.parentMenuItem != null) ?? [];

    if (parentItems.length > 0) {
      return parentItems.map((mi, mii) => {
        let children = childrenItems.filter(cItems => cItems.parentMenuItem.id == mi.id);
        return (<Fragment key={mii}>
          {children.length == 0 &&
            <>
              <AsideMenuItem
                key={"menu-" + mii}
                to={`/${mi.location}`}
                icon={mi.iconClass}
                title={mi.name}
              />
            </>
          }
          {children.length != 0 &&
            <>
              <AsideMenuItemWithSub key={"menu-" + mii} to={`/${mi.location}`} title={mi.name} icon={mi.iconClass}>
                {children.map((ci, cii) =>
                  <AsideMenuItem key={"subMenu-" + cii}
                    to={`/${ci.location}`}
                    icon={ci.iconClass}
                    title={ci.name} />
                )}
              </AsideMenuItemWithSub>
            </>
          }
        </Fragment>);
      }
      );
    }

    return <></>;
  }

  return (
    <>
      {menu.menuItems && menu.menuItems.length > 0 &&
        displayMenuItems(menu.menuItems)
      }
    </>
  );
}

export default connector(AsideMenuMain)