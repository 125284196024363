import { FC } from 'react'

const Error401: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>No Access</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        You do not have access to this page!
      </div>
    </>
  )
}

export { Error401 }
