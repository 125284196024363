import { IOrganisationDashboardState } from "../redux/OrganisationDashboardRedux";
import { connect } from "react-redux";
import { MHFAHighNumberOfCasesThreshold, MHFAMediumNumberOfCasesThreshold} from '../../../@Common/Configurations/BLConfigurations';

interface IAiderStatusEntry {
    organisationDashboardState: IOrganisationDashboardState | undefined ;
}

const OrganisationAiderStatusEntry: React.FC<IAiderStatusEntry> = ({ organisationDashboardState } ) => {
    return(
        ((organisationDashboardState === undefined || organisationDashboardState?.mhfaStatusList === undefined) ||
            (organisationDashboardState?.mhfaStatusList !== undefined && organisationDashboardState?.mhfaStatusList?.length < 1))
        ?
            <>No first aider conversations logged for the selected month</>
        :
        <ul className="ic-table">
            {
                organisationDashboardState?.mhfaStatusList?.map(function(element, index){
                return (
                    <li className="ic-table__row" key={index}>
                        <div className="ic-table__row__field ic-table__row__field--large">
                            <strong>{element.mhfaFullName}</strong>
                        </div>
                        <div className="ic-table__row__field ic-table__row__field--small">
                            <span className={element.totalNumberOfActiveCases > MHFAHighNumberOfCasesThreshold ? "ic-tag ic-tag--red" :
                                element.totalNumberOfActiveCases > MHFAMediumNumberOfCasesThreshold ? "ic-tag ic-tag--orange" : 
                                    "ic-tag ic-tag--green"}>
                                <strong>{element.totalNumberOfActiveCases}</strong> Active Cases
                            </span>
                        </div>
                    </li>)
                })
            }
        </ul>
    );
}

// states to be retrieved from the redux store
const mapStateToProps = (state) => {
    return {
        mhfaStatusList: state.organisationDashboard
    };
  };

export default connect(mapStateToProps)(OrganisationAiderStatusEntry);
