import { Routes, Route, Outlet } from 'react-router-dom'
import SystemView from './components/systemView';
import { PageTitle } from "../../_metronic/layout/core";

const SystemViewPage = () => (
    <Routes>
        <Route
            element={
                <>
                    <Outlet />
                </>
            }
        >
            <Route
                path="/"
                element={
                    <>
                        <PageTitle>Dashboard (System)</PageTitle>
                        <SystemView />
                    </>
                }
            />
        </Route>
    </Routes>
)

export default SystemViewPage;