/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { connect, ConnectedProps } from 'react-redux'
import { showToastrError, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import { ButtonSpinner } from '../../../@Common/UIComponents/ButtonSpinner'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../_metronic/layout/core'
import { BeneficiaryModel } from '../models/BeneficiaryModel'
import { saveBeneficiaryList } from '../redux/BeneficiaryActions'
import * as beneficiaryRedux from '../redux/BeneficiaryListRedux'

const mapState = (state: RootState) => ({
    beneficiaryList: state.beneficiaryList,
    permissions: state.auth.userPermissions
})
const connector = connect(mapState, beneficiaryRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

var beneficiaryData: BeneficiaryModel[];

const ImportBeneficiary: FC<PropsFromRedux> = () => {

    const [isValid, setIsValid] = useState<Boolean>(false);
    const [isSaving, setIsSaving] = useState(false);
    const fileReader = new FileReader();

    // On File Upload
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files?.length) {
            let file: File = e.target.files[0];

            if (file) {
                fileReader.onload = async function (event) {
                    const text: any = event.target!.result;
                    await csvFileToArray(text);
                };
                fileReader.readAsText(file);
            }
        }
        else {
            showToastrError('Please Upload CSV File');
        }
    };

    // Convert CSV File To Array
    const csvFileToArray = async (text: string) => {
        const csvHeader = text.slice(0, text.indexOf("\r")).split(",");
        const csvRows = text.slice(text.indexOf("\n")).split("\n");

        const csvData = csvRows.map(i => {
            const values = i.split(",");
            const obj = csvHeader.reduce((object: any, header: any, index: number) => {
                object[header] = values[index];
                return object;
            }, {});

            return obj;
        })

        // Convert Array [csvData] To Beneficiary Array
        let beneficiaryArr: BeneficiaryModel[] = Object.assign(csvData);
        beneficiaryData = beneficiaryArr.slice(1, beneficiaryArr.length - 1);

        if (beneficiaryData.length) {
            setIsValid(true);
        }
        else {
            setIsValid(false);
        }
    }

    // On Submit
    const handleSubmit = () => {
        if (isValid) {
            saveList();
        }
        else {
            showToastrError('Employee List is Empty');
        }
    };

    // Save Beneficiary List
    const saveList = async () => {
        setIsSaving(true);
        // Save Beneficiary List
        let result = await saveBeneficiaryList(beneficiaryData);
        if (result.isSuccess)
            showToastrSuccess('Employee List Imported')
        else {
            showToastrError(result.errorsDisplay)
        }
        setIsSaving(false);
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle
                    description="Import Employee List as [idNumber, Name, Surname, Email]"
                >
                    Import Employee List
                </PageTitle>

                <div className='card-xl-stretch mb-xl-8 card'>
                    <div className='card-body'>
                        <div className='card-group'>

                            <div className='ic-small-widget-wrapper ic-small-widget-wrapper--75-col-desk'>
                                <Cards
                                    className="card-xl-stretch mb-xl-8 ic-widget-small card"
                                    title={<><h1>Employee List Upload</h1></>}>
                                    <form className="mt-4">
                                        <label className="form-label">Upload Employee List - CSV File</label>
                                        <input
                                            className="form-control"
                                            type={'file'}
                                            id={"csvFileInput"}
                                            accept={'.csv'}
                                            onChange={handleChange}
                                        />
                                        <ButtonSpinner
                                            variant="primary"
                                            text="Import Employee List"
                                            className="w-100 mt-4"
                                            onClick={() => { handleSubmit() }}
                                            saving={isSaving}
                                        >
                                        </ButtonSpinner>
                                    </form>
                                </Cards>
                            </div>

                            <div className='ic-small-widget-wrapper ic-small-widget-wrapper--4-col-desk'>
                                <Cards
                                    className="card-xl-stretch mb-xl-8 ic-widget-small ic-widget-small--alert card"
                                    title={<><h2>Download Template</h2></>}>
                                    <p>Use this template to fill employee data</p>
                                    <p>
                                        <a
                                            href="media\files\Employee_List_Template.csv"
                                            className='btn btn-primary mt-4'
                                        >
                                            <i className="fas fa-download" style={{ marginRight: '10px' }}></i>
                                            Download
                                        </a>
                                    </p>
                                </Cards>
                            </div>

                        </div>
                    </div>
                </div>

            </ErrorBoundary>
        </>
    );
}

export default connector(ImportBeneficiary)
